.achievement {
  padding-inline: 50px;

  @include max-screen($breakpoint-bestview) {
    padding-inline: px-to-vw(50);
  }

  @include max-screen($breakpoint-mobile) {
    padding-inline: 0;
  }

  &-title-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(20);
    }

    @include max-screen($breakpoint-mobile) {
      column-gap: 10px;
    }
  }

  &-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: $base-font-color;
    font-size: 30px;
    line-height: 1;
    color: $white;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(100);
      height: px-to-vw(100);
      font-size: px-to-vw(30);
    }

    @include max-screen($breakpoint-mobile) {
      width: 46px;
      height: 46px;
      font-size: 14px;
    }
  }

  &-title {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    font-family: $secondary-font-family;
    font-size: 120px;
    font-weight: bold;
    line-height: 1;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(120);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 56px;
      line-height: 1.28;
      align-items: center;
    }

    span,
    small {
      font-family: $base-font-family;
      line-height: 1;

      @include max-screen($breakpoint-mobile) {
        line-height: 1.45;
      }
    }

    span {
      font-size: 70px;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(70);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 33px;
        margin-block-start: 5px;
      }
    }

    small {
      font-size: 48px;
      margin-inline-start: 10px;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(48);
        margin-inline-start: px-to-vw(10);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 22px;
        margin-inline-start: 5px;
        margin-block-start: 10px;
      }
    }
  }

  &-content {
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 53px;
    margin-block-start: 5px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(53);
      margin-block-start: px-to-vw(5);
    }

    @include max-screen($breakpoint-mobile) {
      column-gap: 20px;
      margin-block-start: -10px;
    }
  }

  &-desc {
    font-size: 48px;
    font-weight: bold;
    line-height: 1.45;
    text-align: right;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(48);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: $base-font-size;
    }
  }

  &-info {
    position: relative;
    font-family: $secondary-font-family;
    font-weight: bold;
    color: $top-theme-color;
    line-height: 1.28;

    span {
      &:first-of-type {
        position: absolute;
        top: 48px;
        left: 0;
        font-size: 35px;
        line-height: 1.45;

        @include max-screen($breakpoint-bestview) {
          top: px-to-vw(48);
          font-size: px-to-vw(35);
        }

        @include max-screen($breakpoint-mobile) {
          top: 12px;
          font-size: 12px;
        }
      }

      &:nth-of-type(2) {
        font-size: 100px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(100);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 33px;
        }
      }

      &:last-of-type {
        font-size: 165px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(165);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 55px;
        }
      }
    }
  }

  &-image {
    padding-block: 57px 54px;
    background-color: $gray-two;

    @include max-screen($breakpoint-bestview) {
      padding-block: px-to-vw(57) px-to-vw(54);
    }

    @include max-screen($breakpoint-mobile) {
      padding: 25px 40px;
      margin-block-start: 5px;
    }

    img {
      display: block;
      width: 999px;
      max-width: 100%;
      margin: 0 auto;

      @include max-screen($breakpoint-bestview) {
        width: px-to-vw(999);
      }

      @include max-screen($breakpoint-mobile) {
        width: 100%;
      }
    }
  }
}
