.l-com {
  @include max-screen($breakpoint-mobile) {
    padding-block-start: 53px;
  }

  &-function {
    &-automation {
      &-inner {
        @include max-screen($breakpoint-mobile) {
          padding-inline: 25px; // l-container override
        }
      }

      &-title {
        text-align: center;
        margin-block-start: -40px;

        @include max-screen($breakpoint-bestview) {
          margin-block-start: px-to-vw(-40);
        }

        @include max-screen($breakpoint-mobile) {
          margin-block-start: -17px;
        }
      }

      &-cards {
        margin-block: 30px;

        @include max-screen($breakpoint-bestview) {
          margin-block: px-to-vw(30);
        }

        @include max-screen($breakpoint-mobile) {
          margin-block: 0 25px;
        }
      }
    }

    &-support {
      &-title {
        text-align: center;
      }

      &-cards {
        padding-block: 70px 80px;
        margin-block-start: -40px;
        background-color: $gray-two;

        @include max-screen($breakpoint-bestview) {
          padding-block: px-to-vw(70) px-to-vw(80);
          margin-block-start: px-to-vw(-40);
        }

        @include max-screen($breakpoint-mobile) {
          padding-block: 15px 25px;
          margin-block-start: -17px;
        }
      }
    }
  }

  &-banner {
    margin-block-start: 130px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(130);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 65px;
    }
  }

  &-ltv {
    margin-block-start: 135px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(135);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 62px;
    }
  }
}
