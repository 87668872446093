.flow {
  @include max-screen($breakpoint-mobile) {
    margin-left: -20px;
  }
  &-title {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(40);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 25px;
    }
  }

  &-list {
    counter-reset: count;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 31px;
    margin-block-start: 47px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(31);
      margin-block-start: px-to-vw(47);
    }

    @include max-screen($breakpoint-mobile) {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0;
      margin-block-start: 33px;
      padding-left: 20px;
    }
  }

  &-item {
    counter-increment: count;
    position: relative;
    height: 100%;

    @include max-screen($breakpoint-mobile) {
      &:not(:last-child) {
        padding-block-end: 45px;
      }
    }

    &::before {
      content: counter(count);
      position: relative;
      z-index: $z-min;

      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      margin: 0 auto;
      background-color: $top-theme-color;
      border-radius: 50%;

      font-family: $secondary-font-family;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.28;
      color: $white;

      @include max-screen($breakpoint-bestview) {
        width: px-to-vw(50);
        height: px-to-vw(50);
        font-size: px-to-vw(30);
      }

      @include max-screen($breakpoint-mobile) {
        position: absolute;
        top: -4px;
        left: -14px;
        width: 30px;
        height: 30px;
        font-size: 20px;
      }
    }

    &:not(:last-child) {
      &::after {
        content: "";
        position: absolute;
        top: 25px;
        right: -55%;
        width: 100%;
        height: 2px;
        background-color: $top-theme-color;

        @include max-screen($breakpoint-bestview) {
          top: px-to-vw(25);
          height: px-to-vw(2);
        }

        @include max-screen($breakpoint-mobile) {
          width: 1px;
          height: 100%;
          top: 0;
          left: 0;
        }
      }
    }
  }

  &-card {
    position: relative;
    display: flex;
    flex-direction: column;
    height: calc(100% - 75px);
    margin-block-start: 25px;

    @include max-screen($breakpoint-bestview) {
      height: calc(100% - px-to-vw(75));
      margin-block-start: px-to-vw(25);
    }

    @include max-screen($breakpoint-mobile) {
      height: 100%;
      margin-block-start: 0;
    }
  }

  &-icon-wrapper {
    width: 90px;
    height: 80px;
    margin: 0 auto;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(90);
      height: px-to-vw(80);
    }

    @include max-screen($breakpoint-mobile) {
      width: 30px;
      height: 30px;
      margin: 0;
    }
  }

  &-icon {
    width: 90px;
    height: 80px;
    background-size: 90px 80px;
    background-position: center;
    background-repeat: no-repeat;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(90);
      height: px-to-vw(80);
      background-size: px-to-vw(90) px-to-vw(80);
    }

    @include max-screen($breakpoint-mobile) {
      width: 30px;
      height: 30px;
      background-size: 30px 30px;
    }
  }

  &-subtitle-wrapper {
    position: relative;

    @include max-screen($breakpoint-mobile) {
      display: inline-flex;
      justify-content: flex-start;
      align-items: center;
      column-gap: 14px;
    }
  }

  &-subtitle {
    font-size: 25px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;
    margin-block-start: 17px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(25);
      margin-block-start: px-to-vw(17);
    }

    @include max-screen($breakpoint-mobile) {
      display: inline-block;
      font-size: 18px;
      font-weight: normal;
      text-align: left;
      margin-block-start: 0;
      padding-inline-start: 26px;
    }
  }

  &-desc {
    height: 100%;
    margin-block-start: 22px;
    padding: 28px 29px 25px 31px;
    background-color: $gray-two;
    font-size: 16px;
    line-height: 1.79;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(22);
      padding: px-to-vw(28) px-to-vw(29) px-to-vw(25) px-to-vw(31);
      font-size: px-to-vw(16);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 10px;
      padding: 24px 25px 19px;
      font-size: 13px;
      line-height: 1.62;
    }

    span {
      display: block;
      margin-block-start: 6px;
      font-size: 12px;

      @include max-screen($breakpoint-bestview) {
        margin-block-start: px-to-vw(6);
        font-size: px-to-vw(12);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 9px;
      }
    }
  }
}
