// SP only
.nav-menu {
  @include max-screen($breakpoint-mobile) {
    width: 100%;
    border-top: 1px solid $gray-five;
  }

  &-list {
    @include max-screen($breakpoint-mobile) {
      display: flex;
      flex-wrap: wrap;
      margin-block-start: 5px;
    }
  }

  &-item {
    @include max-screen($breakpoint-mobile) {
      flex-basis: 50%;
      width: 50%;
      margin-block-start: 20px;
    }
  }

  &-link {
    @include max-screen($breakpoint-mobile) {
      display: block;
      font-size: 13px;
      color: $black;
      text-align: center;
    }
  }
}
