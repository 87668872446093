$font-smoothing: antialiased;
$text-rendering: auto;

$base-font-family: ryo-gothic-plusn, sans-serif;
$secondary-font-family: din-2014, sans-serif;

$base-font-size: 16px;
$base-font-color: #222;

$white: #fff;
$black: #000;
$black-two: #222;
$gray: #f5f5f5;
$gray-two: #f6f6f6;
$gray-three: #dbdbdb;
$gray-four: #e8e8e8;
$gray-five: #ccc;
$gray-dark: #434343;
$gray-dark-two: #5e5e5e;
$green: #21aa92;
$green-two: #6cb53c;
$green-three: #4ea199;
$blue: #29a6d5;
$blue-two: #15adea;
$blue-three: #3b9cef;
$blue-four: #4d98aa;
$pink: #bb5281;
$orange: #ce7168;

$top-theme-color: $green;
$com-theme-color: $green-two;
$efo-theme-color: $blue;

$site-size: 1000px + 60;
$site-size-lg: 1600px + 60;

$breakpoint-bestview: 1659px;
$breakpoint-tablet: 1023px;
$breakpoint-mobile: 767px;
$breakpoint-mobile-small: 374px;

$font-path: "../fonts/";
$image-path: "../images/";

$ease: ease;
$ease-in-out: ease-in-out;
$duration: 0.25s;

$z-max: 10;
$z-min: 1;
