.function-card {
  display: grid;
  grid-template-columns: 300px 650px;
  justify-content: space-between;
  align-items: center;

  @include max-screen($breakpoint-bestview) {
    grid-template-columns: px-to-vw(300) px-to-vw(650);
  }

  @include max-screen($breakpoint-mobile) {
    grid-template-columns: repeat(1, 1fr);
  }

  &-image {
    width: 300px;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(300);
    }

    @include max-screen($breakpoint-mobile) {
      width: 145px;
      margin: 0 auto;
    }
  }

  &-content {
    margin-block-start: 20px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(20);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 29px;
    }
  }

  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(15);
    }

    @include max-screen($breakpoint-mobile) {
      justify-content: center;
      column-gap: 10px;
    }
  }

  &-icon {
    width: 55px;
    height: 50px;
    background-size: 55px 50px;
    background-position: center;
    background-repeat: no-repeat;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(55);
      height: px-to-vw(50);
      background-size: px-to-vw(55) px-to-vw(50);
    }

    @include max-screen($breakpoint-mobile) {
      width: 33px;
      height: 30px;
      background-size: 33px 30px;
    }
  }

  &-title {
    font-size: 35px;
    font-weight: bold;
    line-height: 1.45;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(35);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 18px;
    }
  }

  &-desc {
    font-size: 18px;
    line-height: 2;
    margin-block-start: 13px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
      margin-block-start: px-to-vw(13);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      margin-block-start: 21px;
    }
  }
}
