.l-index {
  @include max-screen($breakpoint-mobile) {
    padding-block-start: 53px;
  }

  &-service {
    text-align: center;
    margin-block-start: 195px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(195);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 58px;
    }

    &-efo {
      margin-block-start: 90px;

      @include max-screen($breakpoint-bestview) {
        margin-block-start: px-to-vw(90);
      }

      @include max-screen($breakpoint-mobile) {
        margin-block-start: 25px;
      }
    }

    &-com {
      margin-block-start: 80px;

      @include max-screen($breakpoint-bestview) {
        margin-block-start: px-to-vw(80);
      }

      @include max-screen($breakpoint-mobile) {
        margin-block-start: 28px;
      }
    }
  }

  &-optimization {
    margin-block-start: 80px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(80);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 40px;
    }
  }

  &-support {
    width: 985px + 60;
    max-width: 100%;
    margin-block-start: 45px;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(1045);
      margin-block-start: px-to-vw(45);
    }

    @include max-screen($breakpoint-mobile) {
      width: 100%;
      margin-block-start: 62px;
    }
  }

  &-scene {
    margin-block-start: 192px;
    padding-block: 76px 70px;
    background-color: $gray-two;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(192);
      padding-block: px-to-vw(76) px-to-vw(70);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 56px;
      padding-block: 62px 215px;
    }

    &-inner {
      width: 823px + 60;
      max-width: 100%;
      margin-block-start: -158px;

      @include max-screen($breakpoint-bestview) {
        width: px-to-vw(883);
        margin-block-start: px-to-vw(-158);
      }

      @include max-screen($breakpoint-mobile) {
        width: 100%;
        margin-block-start: 0;
        padding: 0; // l-container override
      }
    }
  }

  &-flow {
    margin-block-start: 195px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(195);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 457px;
    }
  }

  &-achievement {
    margin-block-start: 188px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(188);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 62px;
    }
  }

  &-qualvatics {
    margin-block-start: 200px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(200);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 65px;
    }
  }

  &-topics {
    margin-block-start: 165px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(165);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 63px;
    }
  }
}
