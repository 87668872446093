.mv {
  $this: &;
  position: relative;
  display: grid;
  grid-template-columns: 620px 291px;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 89px;
  padding-block: 117px 101px;

  @include max-screen($breakpoint-bestview) {
    grid-template-columns: px-to-vw(620) px-to-vw(291);
    column-gap: px-to-vw(89);
    padding-block: px-to-vw(117) px-to-vw(101);
  }

  @include max-screen($breakpoint-mobile) {
    display: block;
    padding: 40px 0 289px;
  }

  &-content {
    @include max-screen($breakpoint-mobile) {
      width: 295px;
      max-width: 100%;
      margin: 0 auto;
    }
  }

  &-title {
    opacity: 0;
    @include margin-reset;
    font-family: $secondary-font-family;
    font-weight: bold;
    line-height: 1.28;
    color: $com-theme-color;

    &-main {
      display: block;

      &-en {
        font-size: 88px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(88);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 43px;
        }
      }

      &-jp {
        font-family: $base-font-family;
        font-size: 72px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(72);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 35px;
        }
      }

      small {
        font-family: $base-font-family;
        font-size: 52px;
        color: $base-font-color;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(52);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 25px;
        }
      }
    }

    &-sub {
      display: block;
      font-size: 100px;
      margin-block-start: -35px;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(100);
        margin-block-start: px-to-vw(-35);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 50px;
        margin-block-start: -15px;
      }
    }
  }

  &-desc {
    opacity: 0;
    font-size: 22px;
    line-height: 2;
    margin-block-start: 57px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(22);
      margin-block-start: px-to-vw(57);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      margin-block-start: 19px;
    }
  }

  &-image {
    position: absolute;
    top: 130px;
    right: 0;
    width: 291px;

    @include max-screen($breakpoint-bestview) {
      top: px-to-vw(130);
      width: px-to-vw(291);
    }

    @include max-screen($breakpoint-mobile) {
      top: auto;
      right: auto;
      left: 50%;
      transform: translateX(-50%);
      width: 230px;
      margin-block-start: 29px;
    }
  }

  // modifier
  &-efo {
    #{$this} {
      &-title {
        color: $efo-theme-color;
      }
    }
  }
}
