.service-heading {
  position: relative;
  display: inline-block;
  font-size: 90px;
  font-weight: bold;
  line-height: 1.21;

  @include max-screen($breakpoint-bestview) {
    font-size: px-to-vw(90);
  }

  @include max-screen($breakpoint-mobile) {
    font-size: 45px;
    line-height: 1.45;
  }

  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 160px;
    height: 160px;
    background-size: 160px 160px;
    background-position: center;
    background-repeat: no-repeat;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(160);
      height: px-to-vw(160);
      background-size: px-to-vw(160) px-to-vw(160);
    }

    @include max-screen($breakpoint-mobile) {
      display: none;
    }
  }

  &::before {
    left: -252px;
    background-image: url(#{$image-path}svg/icon-efo.svg);

    @include max-screen($breakpoint-bestview) {
      left: px-to-vw(-252);
    }
  }

  &::after {
    right: -252px;
    background-image: url(#{$image-path}svg/icon-com.svg);

    @include max-screen($breakpoint-bestview) {
      right: px-to-vw(-252);
    }
  }

  span {
    display: block;
    font-size: 45px;
    font-weight: normal;
    line-height: 1.45;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(45);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 20px;
    }
  }

  small {
    line-height: 1.45;

    &:first-of-type {
      font-size: 80px;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(80);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 35px;
      }
    }

    &:last-of-type {
      font-size: 45px;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(45);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 20px;
      }
    }
  }
}
