.l-efo {
  @include max-screen($breakpoint-mobile) {
    padding-block-start: 53px;
  }

  &-ab-test {
    &-title {
      text-align: center;
      margin-block-start: -40px;

      @include max-screen($breakpoint-bestview) {
        margin-block-start: px-to-vw(-40);
      }

      @include max-screen($breakpoint-mobile) {
        margin-block-start: -17px;
      }
    }

    &-card {
      padding-block: 80px 190px;

      @include max-screen($breakpoint-bestview) {
        padding-block: px-to-vw(80) px-to-vw(190);
      }

      @include max-screen($breakpoint-mobile) {
        padding-block: 40px 53px;
      }
    }
  }

  &-sms {
    &-title {
      text-align: center;
    }

    &-card {
      padding-block: 120px 170px;
      margin-block-start: -40px;
      background-color: $gray-two;

      @include max-screen($breakpoint-bestview) {
        padding-block: px-to-vw(120) px-to-vw(170);
        margin-block-start: px-to-vw(-40);
      }

      @include max-screen($breakpoint-mobile) {
        padding-block: 85px 71px;
        margin-block-start: -48px;
      }
    }
  }

  &-conversion-api {
    &-title {
      text-align: center;
      margin-block-start: -40px;

      @include max-screen($breakpoint-bestview) {
        margin-block-start: px-to-vw(-40);
      }

      @include max-screen($breakpoint-mobile) {
        margin-block-start: -17px;
      }
    }

    &-card {
      padding-block: 72px 150px;

      @include max-screen($breakpoint-bestview) {
        padding-block: px-to-vw(72) px-to-vw(150);
      }

      @include max-screen($breakpoint-mobile) {
        padding-block: 40px 54px;
      }
    }
  }

  &-keypad {
    &-title {
      text-align: center;
    }

    &-card {
      padding-block: 120px 110px;
      margin-block-start: -40px;
      background-color: $gray-two;

      @include max-screen($breakpoint-bestview) {
        padding-block: px-to-vw(120) px-to-vw(110);
        margin-block-start: px-to-vw(-40);
      }

      @include max-screen($breakpoint-mobile) {
        padding-block: 85px 68px;
        margin-block-start: -48px;
      }
    }
  }

  &-restore {
    &-inner {
      @include max-screen($breakpoint-mobile) {
        padding-inline: 25px; // l-container override
      }
    }

    &-title {
      text-align: center;
      margin-block-start: -40px;

      @include max-screen($breakpoint-bestview) {
        margin-block-start: px-to-vw(-40);
      }

      @include max-screen($breakpoint-mobile) {
        margin-block-start: -17px;
      }
    }

    &-card {
      padding-block: 80px 130px;

      @include max-screen($breakpoint-bestview) {
        padding-block: px-to-vw(80) px-to-vw(130);
      }

      @include max-screen($breakpoint-mobile) {
        padding-block: 40px 57px;
        padding-inline: 15px;
      }
    }
  }

  &-dashboard {
    &-title {
      text-align: center;
    }

    &-card {
      padding-block: 120px 170px;
      margin-block-start: -40px;
      background-color: $gray-two;

      @include max-screen($breakpoint-bestview) {
        padding-block: px-to-vw(120) px-to-vw(170);
        margin-block-start: px-to-vw(-40);
      }

      @include max-screen($breakpoint-mobile) {
        padding-block: 85px 68px;
        margin-block-start: -48px;
      }
    }
  }

  &-data {
    &-title {
      text-align: center;
      margin-block-start: -40px;

      @include max-screen($breakpoint-bestview) {
        margin-block-start: px-to-vw(-40);
      }

      @include max-screen($breakpoint-mobile) {
        margin-block-start: -17px;
      }
    }

    &-card {
      padding-block-start: 80px;

      @include max-screen($breakpoint-bestview) {
        padding-block-start: px-to-vw(80);
      }

      @include max-screen($breakpoint-mobile) {
        padding-block-start: 40px;
      }
    }

    &-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 50px;

      @include max-screen($breakpoint-bestview) {
        column-gap: px-to-vw(50);
      }

      @include max-screen($breakpoint-mobile) {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 25px;
        column-gap: 0;
      }
    }
  }

  &-banner {
    margin-block-start: 130px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(130);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 65px;
    }
  }

  &-cvr {
    margin-block-start: 135px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(135);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 62px;
    }
  }
}
