.merit-card {
  $this: &;
  display: grid;
  grid-template-columns: 620px 330px;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;

  @include max-screen($breakpoint-bestview) {
    grid-template-columns: px-to-vw(620) px-to-vw(330);
    column-gap: px-to-vw(50);
  }

  @include max-screen($breakpoint-mobile) {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    row-gap: 24px;
  }

  &-content {
    @include max-screen($breakpoint-mobile) {
      order: 2;
    }
  }

  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    column-gap: 31px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(31);

      @include max-screen($breakpoint-mobile) {
        align-items: center;
        column-gap: 13px;
      }
    }
  }

  &-count {
    display: block;
    font-family: $secondary-font-family;
    font-size: 150px;
    line-height: 0.8;
    color: $com-theme-color;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(150);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 85px;
    }
  }

  &-title {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.5;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(30);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 16px;
    }
  }

  &-desc {
    font-size: 18px;
    line-height: 2;
    margin-block-start: 47px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
      margin-block-start: px-to-vw(47);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      margin-block-start: 19px;
    }
  }

  &-icon {
    width: 330px;
    height: 300px;
    background-size: 330px 300px;
    background-position: center;
    background-repeat: no-repeat;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(330);
      height: px-to-vw(300);
      background-size: px-to-vw(330) px-to-vw(300);
    }

    @include max-screen($breakpoint-mobile) {
      order: 1;
      width: 230px;
      height: 210px;
      background-size: 230px 210px;
      margin: 0 auto;
    }
  }

  // modifier
  &-efo {
    #{$this} {
      &-info {
        align-items: center;
      }

      &-count {
        color: $efo-theme-color;
      }

      &-desc {
        margin-block-start: 30px;

        @include max-screen($breakpoint-bestview) {
          margin-block-start: px-to-vw(30);
        }

        @include max-screen($breakpoint-mobile) {
          margin-block-start: 25px;
        }
      }

      &-icon {
        height: 270px;
        background-size: 330px 270px;

        @include max-screen($breakpoint-bestview) {
          height: px-to-vw(270);
          background-size: px-to-vw(330) px-to-vw(270);
        }

        @include max-screen($breakpoint-mobile) {
          height: 210px;
          background-size: 230px 210px;
        }
      }
    }
  }
}
