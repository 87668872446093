.banner {
  cursor: pointer;
  display: block;
  width: 100%;

  @include max-screen($breakpoint-mobile) {
    width: calc(100% + 40px);
    margin-inline: -20px;
  }
}
