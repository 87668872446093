* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

body {
  -webkit-text-size-adjust: auto;
  text-size-adjust: auto;
  -webkit-font-smoothing: $font-smoothing; // stylelint-disable property-no-unknown
  -moz-osx-font-smoothing: grayscale;
  text-rendering: $text-rendering;
  font-size: $base-font-size;
  font-family: $base-font-family;
  color: $base-font-color;
  overflow-x: hidden;

  @media screen and (max-width: $breakpoint-mobile) {
    -webkit-text-size-adjust: 100%;
  }
}

svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: $black;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
  text-decoration: none;

  &[href="javascript:void;"] {
    pointer-events: none;
  }

  &[href^="tel"] {
    color: $base-font-color;
  }

  &:focus,
  &:active,
  &:visited {
    outline: none !important;
  }
}

input,
textarea,
button,
select,
div,
span {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

div {
  &::before,
  &::after {
    &:focus {
      outline: none !important;
    }
  }
}

img {
  display: block;
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
