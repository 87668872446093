.service {
  padding-inline: 50px;

  @include max-screen($breakpoint-bestview) {
    padding-inline: px-to-vw(50);
  }

  @include max-screen($breakpoint-mobile) {
    padding-inline: 5px;
  }

  &-wrapper {
    padding-block: 101px;
    margin-block-start: -40px;
    background-color: $gray-two;

    @include max-screen($breakpoint-bestview) {
      padding-block: px-to-vw(101);
      margin-block-start: px-to-vw(-40);
    }

    @include max-screen($breakpoint-mobile) {
      padding-block: 48px 50px;
      margin-block-start: -17px;
    }
  }

  &-container {
    @include max-screen($breakpoint-mobile) {
      padding-inline: 35px;
    }
  }

  &-title {
    font-family: $secondary-font-family;
    font-size: 75px;
    font-weight: bold;
    line-height: 1.28;
    color: currentColor;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(75);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 38px;
      line-height: 1.09;
    }
  }

  &-desc {
    font-size: 18px;
    line-height: 2;
    color: $base-font-color;
    margin-block-start: 41px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
      margin-block-start: px-to-vw(41);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      text-align: left;
      margin-block-start: 23px;
    }
  }

  &-list-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
    align-items: flex-start;
    column-gap: 80px;
    margin-block-start: 66px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(80);
      margin-block-start: px-to-vw(66);
    }

    @include max-screen($breakpoint-mobile) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 75px;
      column-gap: 0;
      margin-block-start: 29px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 0;
      height: 0;
      border-top: 80px solid transparent;
      border-bottom: 80px solid transparent;
      border-left: 30px solid currentColor;

      @include max-screen($breakpoint-bestview) {
        border-top-width: px-to-vw(80);
        border-bottom-width: px-to-vw(80);
        border-left-width: px-to-vw(30);
      }

      @include max-screen($breakpoint-mobile) {
        display: none;
      }
    }
  }

  &-list {
    position: relative;

    @include max-screen($breakpoint-mobile) {
      &:first-of-type {
        &::after {
          content: "";
          position: absolute;
          bottom: -65px;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0;
          height: 0;
          border-top: 25px solid currentColor;
          border-bottom: none;
          border-right: 90px solid transparent;
          border-left: 90px solid transparent;
        }
      }
    }

    &-left {
      .service-list-item {
        color: $base-font-color;
        border-left-color: $gray-dark;
      }
    }

    &-item {
      padding: 29px 20px 25px;
      background-color: $white;
      border-left: 3px solid currentColor;
      font-size: 18px;
      line-height: 1.45;
      text-align: left;

      @include max-screen($breakpoint-bestview) {
        padding: px-to-vw(29) px-to-vw(20) px-to-vw(25);
        border-left-width: px-to-vw(3);
        font-size: px-to-vw(18);
      }

      @include max-screen($breakpoint-mobile) {
        padding: 16px 15px 10px;
        border-left-width: 2px;
        font-size: 13px;
      }

      &:not(:first-child) {
        margin-block-start: 10px;

        @include max-screen($breakpoint-bestview) {
          margin-block-start: px-to-vw(10);
        }

        @include max-screen($breakpoint-mobile) {
          margin-block-start: 5px;
        }
      }
    }

    &-tag {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      position: absolute;
      top: -80px;
      right: -50px;
      z-index: $z-min;
      width: 180px;
      height: 180px;
      border-radius: 50%;
      background-color: currentColor;

      @include max-screen($breakpoint-bestview) {
        top: px-to-vw(-80);
        right: px-to-vw(-50);
        width: px-to-vw(180);
        height: px-to-vw(180);
      }

      @include max-screen($breakpoint-mobile) {
        top: auto;
        bottom: 92px;
        right: -20px;
        width: 90px;
        height: 90px;
      }

      &::before {
        content: "";
        pointer-events: none;
        position: absolute;
        top: 47px;
        left: 50%;
        transform: translateX(-50%);
        width: 156px;
        height: 111px;
        background-image: url(#{$image-path}svg/icon-check.svg);
        background-size: 156px 111px;
        background-position: center;
        background-repeat: no-repeat;

        @include max-screen($breakpoint-bestview) {
          top: px-to-vw(47);
          width: px-to-vw(156);
          height: px-to-vw(111);
          background-size: px-to-vw(156) px-to-vw(111);
        }

        @include max-screen($breakpoint-mobile) {
          top: 22px;
          width: 78px;
          height: 56px;
          background-size: 78px 56px;
        }
      }

      &-text {
        font-size: 30px;
        font-weight: bold;
        line-height: 1.45;
        color: $white;
        padding-block-start: 15px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(30);
          padding-block-start: px-to-vw(15);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 15px;
          padding-block-start: 5px;
        }

        span {
          display: block;
          font-size: 49px;
          line-height: 1.21;

          @include max-screen($breakpoint-bestview) {
            font-size: px-to-vw(49);
          }

          @include max-screen($breakpoint-mobile) {
            font-size: 25px;
          }
        }

        small {
          font-size: 22px;

          @include max-screen($breakpoint-bestview) {
            font-size: px-to-vw(22);
          }

          @include max-screen($breakpoint-mobile) {
            font-size: 11px;
          }
        }
      }
    }
  }

  &-button {
    margin-block-start: 81px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(81);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 40px;
    }
  }
}
