.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);

  @include max-screen($breakpoint-mobile) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: $white;
    z-index: $z-max;
  }

  &.is-active {
    @include max-screen($breakpoint-mobile) {
      height: 100vh;
      overflow: auto;
      background-color: rgba($white, 0.9);
      backdrop-filter: blur(3px);
    }
  }

  &-inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-inline-start: 50px;

    @include max-screen($breakpoint-bestview) {
      padding-inline-start: px-to-vw(50);
    }

    @include max-screen($breakpoint-mobile) {
      padding: 12px 13px;
      padding-inline-start: 13px;
    }
  }

  &-logo {
    display: block;
    width: 200px;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(200);
    }

    @include max-screen($breakpoint-mobile) {
      width: 120px;
    }
  }

  // SP only
  &-menu {
    &-toggle {
      position: relative;
      width: 30px;
      height: 21px;
      z-index: $z-max;

      span {
        position: absolute;
        top: 6px;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $base-font-color;
        transition: background-color $duration $ease-in-out;

        &::before,
        &::after {
          content: "";
          position: absolute;
          top: -10px;
          left: 0;
          width: 100%;
          height: 2px;
          background-color: $base-font-color;
          transition: transform $duration $ease-in-out;
        }

        &::after {
          top: 10px;
        }
      }

      .header.is-active & {
        span {
          background-color: transparent;

          &::before,
          &::after {
            top: 0;
            transform-origin: center;
          }

          &::before {
            transform: rotate(-45deg);
          }

          &::after {
            transform: rotate(45deg);
          }
        }

        + .header-menu-content {
          position: fixed;
          top: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          row-gap: 30px;
          width: 100%;
          height: 100vh;
          overflow-x: hidden;
          overflow-y: auto;
          padding-block: 55px;
          padding-inline: 66px 48px;
        }
      }
    }

    &-content {
      display: none;
    }
  }

  &-contact {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    column-gap: 15px;

    width: 250px;
    height: 100px;
    background-color: $top-theme-color;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(15);
      width: px-to-vw(250);
      height: px-to-vw(100);
    }

    @include max-screen($breakpoint-mobile) {
      flex-shrink: 0;
      column-gap: 15px;
      width: 100%;
      height: 65px;
    }

    @include true-hover {
      .header-contact-icon,
      .header-contact-text {
        opacity: 0.7;
      }
    }

    &-icon {
      display: block;
      transition: opacity $duration $ease;

      &::before {
        content: "";
        display: block;
        width: 40px;
        height: 40px;
        background-image: url(#{$image-path}svg/icon-message.svg);
        background-size: 40px 40px;

        @include max-screen($breakpoint-bestview) {
          width: px-to-vw(40);
          height: px-to-vw(40);
          background-size: px-to-vw(40) px-to-vw(40);
        }

        @include max-screen($breakpoint-mobile) {
          width: 25px;
          height: 25px;
          background-size: 25px 25px;
        }
      }
    }

    &-text {
      font-family: $base-font-family;
      font-size: 22px;
      font-weight: bold;
      color: $white;
      transition: opacity $duration $ease;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(22);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 15px;
      }
    }
  }
}
