.support {
  &-title {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(40);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 25px;
      line-height: 1.6;
    }
  }

  &-content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 65px;
    margin-block-start: 67px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(65);
      margin-block-start: px-to-vw(67);
    }

    @include max-screen($breakpoint-mobile) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 33px;
      column-gap: 0;
      margin-block-start: 28px;
    }
  }

  &-card {
    &:first-of-type {
      .support-icon {
        width: 100px;
        height: 76px;
        background-size: 100px 76px;

        @include max-screen($breakpoint-bestview) {
          width: px-to-vw(100);
          height: px-to-vw(76);
          background-size: px-to-vw(100) px-to-vw(76);
        }

        @include max-screen($breakpoint-mobile) {
          width: 55px;
          height: 42px;
          background-size: 55px 42px;
        }
      }
    }

    &:last-of-type {
      .support-icon {
        width: 86px;
        height: 100px;
        background-size: 86px 100px;

        @include max-screen($breakpoint-bestview) {
          width: px-to-vw(86);
          height: px-to-vw(100);
          background-size: px-to-vw(86) px-to-vw(100);
        }

        @include max-screen($breakpoint-mobile) {
          width: 45px;
          height: 52px;
          background-size: 45px 52px;
        }
      }
    }
  }

  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 30px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(30);
    }

    @include max-screen($breakpoint-mobile) {
      column-gap: 15px;
    }
  }

  &-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 140px;
    height: 140px;
    background-color: $gray-two;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(140);
      height: px-to-vw(140);
    }

    @include max-screen($breakpoint-mobile) {
      width: 80px;
      height: 80px;
    }
  }

  &-icon {
    background-position: center;
    background-repeat: no-repeat;
  }

  &-subtitle {
    font-size: 30px;
    font-weight: bold;
    line-height: 1.3;
    text-align: left;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(30);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 20px;
      line-height: 1.6;
    }
  }

  &-desc {
    font-size: 18px;
    line-height: 2;
    margin-block-start: 48px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
      margin-block-start: px-to-vw(48);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      margin-block-start: 24px;
    }
  }
}
