.why-card {
  $this: &;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;

  @include max-screen($breakpoint-bestview) {
    column-gap: px-to-vw(50);
  }

  @include max-screen($breakpoint-mobile) {
    flex-direction: column-reverse;
  }

  &-content {
    flex-basis: 620px;
    width: 620px;

    @include max-screen($breakpoint-bestview) {
      flex-basis: px-to-vw(620);
      width: px-to-vw(620);
    }

    @include max-screen($breakpoint-mobile) {
      flex-basis: 100%;
      width: 100%;
    }
  }

  &-title {
    position: relative;
    padding-inline-start: 27px;
    font-size: 30px;
    font-weight: bold;
    line-height: 1.67;

    @include max-screen($breakpoint-bestview) {
      padding-inline-start: px-to-vw(27);
      font-size: px-to-vw(30);
    }

    @include max-screen($breakpoint-mobile) {
      flex-basis: 149px;
      width: 149px;
      padding-inline-start: 0;
      font-size: 18px;
      line-height: 1.61;
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 3px;
      height: 80%;
      background-color: $com-theme-color;

      @include max-screen($breakpoint-bestview) {
        width: px-to-vw(3);
      }

      @include max-screen($breakpoint-mobile) {
        display: none;
      }
    }
  }

  &-desc {
    font-size: 18px;
    line-height: 2;
    margin-block-start: 27px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
      margin-block-start: px-to-vw(27);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 12px;
      margin-block-start: 35px;
    }
  }

  &-image-wrapper {
    flex-basis: 330px;
    width: 330px;

    @include max-screen($breakpoint-bestview) {
      flex-basis: px-to-vw(330);
      width: px-to-vw(330);
    }

    @include max-screen($breakpoint-mobile) {
      flex-basis: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      column-gap: 25px;
    }
  }

  &-thumb {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 100%;
    height: 350px;
    background-color: $white;

    @include max-screen($breakpoint-bestview) {
      height: px-to-vw(350);
    }

    @include max-screen($breakpoint-mobile) {
      flex-basis: 110px;
      width: 110px;
      height: auto;
      background-color: transparent;
    }
  }

  &-image {
    width: 180px;
    margin-block-end: 20px;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(180);
      margin-block-end: px-to-vw(20);
    }

    @include max-screen($breakpoint-mobile) {
      width: 110px;
      margin-block-end: 0;
    }

    // modifier
    &-lg {
      width: 230px;
      margin-block-end: 32px;

      @include max-screen($breakpoint-bestview) {
        width: px-to-vw(230);
        margin-block-end: px-to-vw(32);
      }

      @include max-screen($breakpoint-mobile) {
        width: 110px;
        margin-block-end: 0;
      }
    }
  }

  // modifier
  &-reverse {
    flex-direction: row-reverse;

    @include max-screen($breakpoint-mobile) {
      flex-direction: column-reverse;
    }

    #{$this} {
      &-image-wrapper {
        flex-direction: row-reverse;
      }
    }
  }

  &-efo {
    #{$this} {
      &-content,
      &-image-wrapper {
        flex-basis: 475px;
        width: 475px;

        @include max-screen($breakpoint-bestview) {
          flex-basis: px-to-vw(475);
          width: px-to-vw(475);
        }

        @include max-screen($breakpoint-mobile) {
          flex-basis: 100%;
          width: 100%;
        }
      }

      &-title {
        @include max-screen($breakpoint-mobile) {
          flex-basis: 100%;
          width: 100%;
          padding-inline-start: 14px;
          margin-block-start: 24px;
        }

        &::before {
          background-color: $efo-theme-color;

          @include max-screen($breakpoint-mobile) {
            display: block;
            width: 2px;
          }
        }
      }

      &-desc {
        @include max-screen($breakpoint-mobile) {
          font-size: 13px;
          margin-block-start: 17px;
        }
      }

      &-image-wrapper {
        @include max-screen($breakpoint-mobile) {
          flex-direction: column;
        }
      }

      &-thumb {
        height: auto;
        background-color: transparent;

        @include max-screen($breakpoint-mobile) {
          flex-basis: 100%;
          width: 100%;
        }
      }

      &-image {
        width: 100%;
        margin-block-end: 0;
      }
    }
  }
}
