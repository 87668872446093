.efo-function-card {
  $this: &;
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;

  @include max-screen($breakpoint-bestview) {
    column-gap: px-to-vw(50);
  }

  @include max-screen($breakpoint-mobile) {
    flex-direction: column;
    row-gap: 25px;
    column-gap: 0;
  }

  &-content {
    flex-basis: 580px;
    width: 580px;

    @include max-screen($breakpoint-bestview) {
      flex-basis: px-to-vw(580);
      width: px-to-vw(580);
    }

    @include max-screen($breakpoint-mobile) {
      order: 2;
      flex-basis: 100%;
      width: 100%;
    }
  }

  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(15);
    }

    @include max-screen($breakpoint-mobile) {
      justify-content: center;
      column-gap: 10px;
    }
  }

  &-icon {
    width: 55px;
    height: 50px;
    background-size: 55px 50px;
    background-position: center;
    background-repeat: no-repeat;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(55);
      height: px-to-vw(50);
      background-size: px-to-vw(55) px-to-vw(50);
    }

    @include max-screen($breakpoint-mobile) {
      width: 33px;
      height: 30px;
      background-size: 33px 30px;
    }
  }

  &-title {
    font-size: 33px;
    font-weight: bold;
    line-height: 1.45;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(33);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 18px;
    }
  }

  &-desc {
    font-size: 18px;
    line-height: 2;
    margin-block-start: 38px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
      margin-block-start: px-to-vw(38);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      margin-block-start: 21px;
    }
  }

  &-image {
    flex-basis: 570px;
    width: 570px;

    @include max-screen($breakpoint-bestview) {
      flex-basis: px-to-vw(570);
      width: px-to-vw(570);
    }

    @include max-screen($breakpoint-mobile) {
      order: 1;
      flex-basis: 100%;
      width: 100%;
    }
  }

  // modifier
  &-reverse {
    flex-direction: row-reverse;

    @include max-screen($breakpoint-mobile) {
      flex-direction: column;
    }

    #{$this} {
      &-info {
        width: calc(100% + 20px);

        @include max-screen($breakpoint-bestview) {
          width: calc(100% + px-to-vw(20));
        }

        @include max-screen($breakpoint-mobile) {
          width: 100%;
        }
      }
    }
  }

  &-sm {
    flex-direction: row-reverse;
    justify-content: flex-end;
    column-gap: 90px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(90);
    }

    @include max-screen($breakpoint-mobile) {
      flex-direction: column;
    }

    #{$this} {
      &-image {
        flex-basis: 340px;
        width: 340px;

        @include max-screen($breakpoint-bestview) {
          flex-basis: px-to-vw(340);
          width: px-to-vw(340);
        }

        @include max-screen($breakpoint-mobile) {
          flex-basis: 100%;
          width: 100%;
          padding-inline: 60px;
        }
      }
    }
  }

  &-ab {
    #{$this} {
      &-content {
        flex-basis: 380px;
        width: 380px;

        @include max-screen($breakpoint-bestview) {
          flex-basis: px-to-vw(380);
          width: px-to-vw(380);
        }

        @include max-screen($breakpoint-mobile) {
          flex-basis: 100%;
          width: 100%;
        }
      }

      &-image {
        flex-basis: 570px;
        width: 570px;
        padding-inline: 40px;

        @include max-screen($breakpoint-bestview) {
          flex-basis: px-to-vw(570);
          width: px-to-vw(570);
          padding-inline: px-to-vw(40);
        }

        @include max-screen($breakpoint-mobile) {
          flex-basis: 100%;
          width: 100%;
          padding-inline: 75px;
        }
      }
    }
  }

  &-lg {
    #{$this} {
      &-content {
        flex-basis: 570px;
        width: 570px;

        @include max-screen($breakpoint-bestview) {
          flex-basis: px-to-vw(570);
          width: px-to-vw(570);
        }

        @include max-screen($breakpoint-mobile) {
          flex-basis: 100%;
          width: 100%;
        }
      }

      &-image {
        flex-basis: 380px;
        width: 380px;
        padding-inline: 40px;

        @include max-screen($breakpoint-bestview) {
          flex-basis: px-to-vw(380);
          width: px-to-vw(380);
          padding-inline: px-to-vw(40);
        }

        @include max-screen($breakpoint-mobile) {
          flex-basis: 100%;
          width: 100%;
          padding-inline: 75px;
        }
      }
    }
  }

  &-restore {
    #{$this} {
      &-content {
        flex-basis: 380px;
        width: 380px;

        @include max-screen($breakpoint-bestview) {
          flex-basis: px-to-vw(380);
          width: px-to-vw(380);
        }

        @include max-screen($breakpoint-mobile) {
          flex-basis: 100%;
          width: 100%;
        }
      }

      &-image {
        flex-basis: 570px;
        width: 570px;
        padding-inline: 40px;

        @include max-screen($breakpoint-bestview) {
          flex-basis: px-to-vw(570);
          width: px-to-vw(570);
          padding-inline: px-to-vw(40);
        }

        @include max-screen($breakpoint-mobile) {
          flex-basis: 100%;
          width: 100%;
          padding-inline: 75px;
        }
      }
    }
  }

  &-block {
    flex-direction: column;

    #{$this} {
      &-content {
        flex-basis: 100%;
        width: 100%;
        text-align: center;
      }

      &-info {
        justify-content: center;
      }

      &-desc {
        @include max-screen($breakpoint-mobile) {
          text-align: left;
        }
      }

      &-image {
        flex-basis: 100%;
        width: 100%;
        margin-block-start: 28px;

        @include max-screen($breakpoint-bestview) {
          margin-block-start: px-to-vw(28);
        }
      }
    }
  }
}
