/* GENERIC */
@mixin true-hover {
  @media (any-hover: hover) {
    &:hover {
      @content;
    }
  }

  &:active {
    @content;
  }
}

/* RESET */
@mixin border-box-reset {
  box-sizing: border-box;
}

@mixin margin-reset {
  margin: 0;
}

@mixin padding-reset {
  padding: 0;
}

@mixin border-reset {
  border: none;
}

@mixin list-reset {
  list-style: none;
  padding: 0;
  margin: 0;
}
