.why-banner {
  $this: &;
  position: relative;
  background-color: $com-theme-color;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-top: 51px solid $gray;
    border-left: 350px solid transparent;
    border-right: 350px solid transparent;

    @include max-screen($breakpoint-bestview) {
      border-top-width: px-to-vw(51);
      border-left-width: px-to-vw(350);
      border-right-width: px-to-vw(350);
    }

    @include max-screen($breakpoint-mobile) {
      border-top-width: 26px;
      border-left-width: 133px;
      border-right-width: 133px;
    }
  }

  &-inner {
    padding: 68px 50px 50px;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      padding: px-to-vw(68) px-to-vw(50) px-to-vw(50);
    }

    @include max-screen($breakpoint-mobile) {
      padding: 38px 5px 5px;
    }
  }

  &-title {
    font-size: 55px;
    font-weight: normal;
    line-height: 1.45;
    color: $white;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(55);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 30px;
    }

    span {
      font-size: 75px;
      font-weight: bold;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(75);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 41px;
      }
    }

    small {
      font-size: 35px;
      font-weight: bold;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(35);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 18px;
      }
    }
  }

  &-graph {
    display: grid;
    grid-template-columns: 365px 620px;
    justify-content: center;
    align-items: center;
    column-gap: 15px;
    margin-block-start: 5px;
    padding: 50px;
    background-color: $white;

    @include max-screen($breakpoint-bestview) {
      grid-template-columns: px-to-vw(365) px-to-vw(620);
      column-gap: px-to-vw(15);
      margin-block-start: px-to-vw(5);
      padding: px-to-vw(50);
    }

    @include max-screen($breakpoint-mobile) {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0;
      row-gap: 13px;
      margin-block-start: 8px;
      padding: 33px 35px 40px;
    }

    &-desc {
      font-size: 33px;
      line-height: 1.2;
      text-align: left;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(33);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 17px;
        text-align: center;
      }

      // modifier
      &-main,
      &-subheading,
      &-text {
        display: block;
      }

      &-lg {
        font-size: 76px;
        font-weight: bold;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(76);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 38px;
        }
      }

      &-color {
        color: $com-theme-color;
      }

      &-text {
        margin-block-start: 18px;

        @include max-screen($breakpoint-bestview) {
          margin-block-start: px-to-vw(18);
        }

        @include max-screen($breakpoint-mobile) {
          margin-block-start: 5px;
        }
      }
    }
  }

  // modifier
  &-efo {
    background-color: $efo-theme-color;

    #{$this} {
      &-inner {
        padding-block-end: 80px;

        @include max-screen($breakpoint-bestview) {
          padding-block-end: px-to-vw(80);
        }

        @include max-screen($breakpoint-mobile) {
          padding-block-end: 5px;
        }
      }

      &-graph {
        grid-template-columns: 334px 650px;

        @include max-screen($breakpoint-bestview) {
          grid-template-columns: px-to-vw(334) px-to-vw(650);
        }

        @include max-screen($breakpoint-mobile) {
          grid-template-columns: repeat(1, 1fr);
          row-gap: 7px;
          padding: 40px 5px 43px;
        }

        &-desc {
          font-weight: bold;
          line-height: 1.45;

          &-main {
            display: block;
            font-size: 20px;
            font-weight: normal;

            @include max-screen($breakpoint-bestview) {
              font-size: px-to-vw(20);
            }

            @include max-screen($breakpoint-mobile) {
              font-size: 15px;
            }
          }

          &-sub {
            display: block;
            font-size: 35px;
            margin-block-start: 5px;

            @include max-screen($breakpoint-bestview) {
              font-size: px-to-vw(35);
              margin-block-start: px-to-vw(5);
            }

            @include max-screen($breakpoint-mobile) {
              font-size: 26px;
              margin-block-start: 3px;
            }

            small {
              font-size: 30px;

              @include max-screen($breakpoint-bestview) {
                font-size: px-to-vw(30);
              }

              @include max-screen($breakpoint-mobile) {
                font-size: 26px;
              }
            }
          }

          &-subheading {
            display: block;
            font-size: 50px;
            margin-block-start: -12px;

            @include max-screen($breakpoint-bestview) {
              font-size: px-to-vw(50);
              margin-block-start: px-to-vw(-12);
            }

            @include max-screen($breakpoint-mobile) {
              display: inline-block;
              font-size: 28px;
              margin-block-start: -17px;
            }

            // modifier
            &-lg {
              font-size: 87px;
              color: $efo-theme-color;

              @include max-screen($breakpoint-bestview) {
                font-size: px-to-vw(87);
              }

              @include max-screen($breakpoint-mobile) {
                font-size: 50px;
              }

              small {
                font-size: 50px;

                @include max-screen($breakpoint-bestview) {
                  font-size: px-to-vw(50);
                }

                @include max-screen($breakpoint-mobile) {
                  font-size: 28px;
                }
              }
            }
          }

          &-text {
            display: block;
            font-size: 50px;
            text-align: center;
            margin-block-start: -25px;

            @include max-screen($breakpoint-bestview) {
              font-size: px-to-vw(50);
              margin-block-start: px-to-vw(-25);
            }

            @include max-screen($breakpoint-mobile) {
              display: inline-block;
              font-size: 28px;
              margin-block-start: 0;
            }
          }
        }
      }
    }
  }
}
