.l-container {
  width: $site-size;
  max-width: 100%;
  padding: 0 30px;
  margin: 0 auto;

  @include max-screen($breakpoint-bestview) {
    width: px-to-vw(1060);
    padding: 0 px-to-vw(30);
  }

  @include max-screen($breakpoint-mobile) {
    width: 100%;
    padding: 0 40px;
  }

  &-large {
    width: $site-size-lg;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(1660);
    }

    @include max-screen($breakpoint-mobile) {
      padding: 0;
    }
  }
}
