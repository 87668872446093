.function-card-list {
  $this: &;
  padding-inline: 25px;

  @include max-screen($breakpoint-bestview) {
    padding-inline: px-to-vw(25);
  }

  @include max-screen($breakpoint-mobile) {
    padding-inline: 0;
  }

  &-item {
    padding-block: 50px 48px;
    border-bottom: 2px solid $gray-five;

    @include max-screen($breakpoint-bestview) {
      padding-block: px-to-vw(50) px-to-vw(48);
      border-bottom-width: px-to-vw(2);
    }

    @include max-screen($breakpoint-mobile) {
      padding-block: 39px 27px;
    }

    &:last-child {
      @include max-screen($breakpoint-mobile) {
        border-bottom: none;
      }
    }
  }

  // modifier
  &-automation {
    @include max-screen($breakpoint-mobile) {
      padding-inline: 15px;
    }

    #{$this} {
      &-item {
        &:last-child {
          border-bottom: none;
        }
      }
    }
  }
}
