.icon-card {
  $this: &;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 27px;
  height: 310px;
  background-color: $white;

  @include max-screen($breakpoint-bestview) {
    row-gap: px-to-vw(27);
    height: px-to-vw(310);
  }

  @include max-screen($breakpoint-mobile) {
    row-gap: 14px;
    height: 120px;
    padding-inline: 12px;
  }

  &-image {
    width: 170px;
    height: 130px;
    background-size: 170px 130px;
    background-position: center;
    background-repeat: no-repeat;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(170);
      height: px-to-vw(130);
      background-size: px-to-vw(170) px-to-vw(130);
    }

    @include max-screen($breakpoint-mobile) {
      width: 60px;
      height: 45px;
      background-size: 60px 45px;
    }
  }

  &-title {
    font-size: 21px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(21);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 12px;
      text-align: center;
    }
  }

  // modifier
  &-efo {
    height: 232px;
    row-gap: 17px;

    @include max-screen($breakpoint-bestview) {
      height: px-to-vw(232);
      row-gap: px-to-vw(17);
    }

    @include max-screen($breakpoint-mobile) {
      height: 120px;
      row-gap: 14px;
      padding-inline: 8px;
    }

    #{$this} {
      &-image {
        width: 130px;
        height: 90px;
        background-size: 130px 90px;

        @include max-screen($breakpoint-bestview) {
          width: px-to-vw(130);
          height: px-to-vw(90);
          background-size: px-to-vw(130) px-to-vw(90);
        }

        @include max-screen($breakpoint-mobile) {
          width: 60px;
          height: 45px;
          background-size: 60px 45px;
        }
      }
    }
  }
}
