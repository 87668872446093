.button {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;

  min-width: 299px;
  max-width: 100%;
  padding: 24px 30px 22px;
  margin: 0 auto;
  border: 1px solid $base-font-color;

  font-weight: bold;
  line-height: 1.45;
  color: $base-font-color;
  text-align: left;

  @include max-screen($breakpoint-bestview) {
    column-gap: px-to-vw(8);
    min-width: px-to-vw(299);
    padding: px-to-vw(24) px-to-vw(30) px-to-vw(22);
    font-size: px-to-vw(16);
  }

  @include max-screen($breakpoint-mobile) {
    column-gap: 8px;
    min-width: 220px;
    padding: 15px 20px;
    font-size: 13px;
  }

  @include true-hover {
    .button-icon {
      transform: translateX(10px);
    }
  }

  &-icon {
    pointer-events: none;
    display: inline-block;
    width: 30px;
    height: 5px;
    background: url(#{$image-path}svg/icon-button.svg) no-repeat center;
    background-size: 30px 5px;
    transition: transform $duration $ease;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(30);
      height: px-to-vw(5);
      background-size: px-to-vw(30) px-to-vw(5);
    }

    @include max-screen($breakpoint-mobile) {
      width: 20px;
      height: 5px;
      background-size: 20px 5px;
    }
  }
}
