.footer {
  margin-block-start: 130px;
  text-align: center;

  @include max-screen($breakpoint-bestview) {
    margin-block-start: px-to-vw(130);
  }

  @include max-screen($breakpoint-mobile) {
    margin-block-start: 30px;
  }

  &-back-to-top {
    position: relative;
    display: inline-flex;
    flex-direction: column;
    row-gap: 8px;
    transition: opacity $duration $ease;

    @include max-screen($breakpoint-bestview) {
      row-gap: px-to-vw(8);
    }

    @include max-screen($breakpoint-mobile) {
      row-gap: 6px;
    }

    @include true-hover {
      opacity: 0.7;
    }

    &-icon {
      display: block;

      &::before {
        content: "";
        display: block;
        width: 30px;
        height: 10px;
        background-image: url(#{$image-path}svg/icon-arrow-up.svg);
        background-size: 30px 10px;

        @include max-screen($breakpoint-bestview) {
          width: px-to-vw(30);
          height: px-to-vw(10);
          background-size: px-to-vw(30) px-to-vw(10);
        }

        @include max-screen($breakpoint-mobile) {
          width: 30px;
          height: 10px;
          background-size: 30px 10px;
        }
      }
    }

    &-text {
      font-family: $secondary-font-family;
      color: $base-font-color;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(16);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 12px;
      }
    }
  }

  &-content {
    background-color: $base-font-color;
    padding: 50px 30px 24px;
    margin-block-start: 21px;

    @include max-screen($breakpoint-bestview) {
      padding: px-to-vw(50) px-to-vw(30) px-to-vw(24);
      margin-block-start: px-to-vw(21);
    }

    @include max-screen($breakpoint-mobile) {
      padding: 40px 0 25px;
      margin-block-start: 18px;
    }
  }

  &-logo {
    display: block;
    width: 200px;
    margin: 0 auto;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(200);
    }

    @include max-screen($breakpoint-mobile) {
      width: 110px;
    }
  }

  &-nav {
    width: 480px;
    margin: 47px auto 0;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(480);
      margin-block-start: px-to-vw(47);
    }

    @include max-screen($breakpoint-mobile) {
      width: 100%;
      margin-block-start: 27px;
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      row-gap: 5px;
      column-gap: 60px;

      @include max-screen($breakpoint-bestview) {
        row-gap: px-to-vw(5);
        column-gap: px-to-vw(60);
      }

      @include max-screen($breakpoint-mobile) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 0;
      }
    }

    &-item {
      position: relative;

      @include max-screen($breakpoint-mobile) {
        border-top: 1px solid $gray-dark-two;

        &:nth-child(odd) {
          border-right: 1px solid $gray-dark-two;
        }

        &:nth-child(7),
        &:last-child {
          border-bottom: 1px solid $gray-dark-two;
        }
      }

      &:nth-child(n):not(:nth-child(4n)) {
        .footer-nav-link {
          &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: -30px;
            transform: translateY(-50%);
            width: 1px;
            height: 12px;
            background-color: $white;

            @include max-screen($breakpoint-bestview) {
              right: px-to-vw(-30);
              height: px-to-vw(12);
            }

            @include max-screen($breakpoint-mobile) {
              display: none;
            }
          }
        }
      }
    }

    &-link {
      position: relative;
      font-size: 12px;
      line-height: 1;
      color: $white;
      transition: opacity $duration $ease;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(12);
      }

      @include max-screen($breakpoint-mobile) {
        display: block;
        padding: 20px 15px 18px;
        font-size: 12px;
      }

      @include true-hover {
        opacity: 0.7;
      }
    }
  }

  &-copyright {
    margin-block-start: 67px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(67);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 24px;
    }

    &-text {
      font-size: 12px;
      color: $white;
      text-transform: uppercase;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(12);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 8px;
        color: $gray-dark-two;
      }

      small {
        font-size: 12px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(12);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 8px;
        }
      }
    }
  }
}
