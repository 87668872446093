.top-why {
  background-color: $gray;

  &-inner {
    padding: 188px 50px 22px;

    @include max-screen($breakpoint-bestview) {
      padding: px-to-vw(188) px-to-vw(50) px-to-vw(22);
    }

    @include max-screen($breakpoint-mobile) {
      padding: 94px 0 0;
    }
  }

  &-content {
    // width: 770px;
    max-width: 100%;
    margin-inline-start: 251px;

    @include max-screen($breakpoint-bestview) {
      // width: px-to-vw(770);
      margin-inline-start: px-to-vw(251);
    }

    @include max-screen($breakpoint-mobile) {
      width: 100%;
      margin-inline-start: 0;
      padding-inline: 40px;
    }
  }

  &-title {
    font-size: 100px;
    font-weight: bold;
    line-height: 1.45;
    color: $top-theme-color;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(100);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 50px;
    }

    span {
      display: block;
      font-size: 55px;
      font-weight: normal;
      line-height: 1.49;
      color: $base-font-color;
      font-weight: 500;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(55);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 20px;
        line-height: 1.6;
      }
    }
  }

  &-desc {
    font-size: 18px;
    line-height: 2;
    margin-block-start: 31px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      margin-block-start: 32px;
    }
  }

  &-image {
    width: 100%;
    margin-block-start: 73px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(73);
    }

    @include max-screen($breakpoint-mobile) {
      width: 100%;
      margin: 19px auto 0;
      padding-inline-start: 5px;
      overflow-x: auto;
    }

    &-inner {
      width: 100%;

      @include max-screen($breakpoint-mobile) {
        width: 575px;
        margin: 0 auto;
      }
    }

    &-note {
      @include max-screen($breakpoint-mobile) {
        width: 548px;
        font-size: 9px;
        line-height: 1.45;
        text-align: left;
        background-color: $white;
        padding: 12px 15px;
        margin-block-start: -1px;

        &::after {
          content: "";
          display: inline-block;
          vertical-align: top;
          width: 190px;
          height: 8px;
          background: url(#{$image-path}svg/icon-button3.svg) no-repeat center;
          background-size: 190px 8px;
          margin-inline-start: 10px;
        }
      }
    }
  }

  &-slogan {
    margin-block-start: 125px;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(125);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 38px;
    }

    &-desc {
      font-size: 45px;
      line-height: 1.33;
      font-weight: 500;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(45);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 20px;
        line-height: 1.6;
      }
    }

    &-icon {
      width: 150px;
      height: 138px;
      background-size: 150px 138px;
      background-position: center;
      background-repeat: no-repeat;
      margin: 25px auto 0;

      @include max-screen($breakpoint-bestview) {
        width: px-to-vw(150);
        height: px-to-vw(138);
        background-size: px-to-vw(150) px-to-vw(138);
        margin-block-start: px-to-vw(25);
      }

      @include max-screen($breakpoint-mobile) {
        width: 60px;
        height: 55px;
        background-size: 60px 55px;
        margin-block-start: 8px;
      }
    }
  }

  &-banner {
    position: relative;
    background-color: $top-theme-color;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 0;
      height: 0;
      border-top: 40px solid $gray;
      border-left: 280px solid transparent;
      border-right: 280px solid transparent;

      @include max-screen($breakpoint-bestview) {
        border-top-width: px-to-vw(40);
        border-left-width: px-to-vw(280);
        border-right-width: px-to-vw(280);
      }

      @include max-screen($breakpoint-mobile) {
        border-top-width: 25px;
        border-left-width: 133px;
        border-right-width: 133px;
      }
    }

    &-inner {
      padding-block: 81px 62px;
      text-align: center;

      @include max-screen($breakpoint-bestview) {
        padding-block: px-to-vw(81) px-to-vw(62);
      }

      @include max-screen($breakpoint-mobile) {
        padding-block: 48px 29px;
      }
    }

    &-title {
      font-size: 80px;
      font-weight: bold;
      line-height: 1.45;
      color: $white;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(80);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 31px;
      }

      span {
        font-size: 70px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(70);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 27px;
        }
      }

      small {
        display: block;
        font-size: 31px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(31);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: $base-font-size;
        }
      }
    }
  }
}
