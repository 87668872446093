.nav {
  padding-block: 38px 35px;

  @include max-screen($breakpoint-bestview) {
    padding-block: px-to-vw(38) px-to-vw(35);
  }

  @include max-screen($breakpoint-mobile) {
    width: 100%;
    padding-block: 0;
  }

  &-list {
    display: flex;
    column-gap: 47px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(47);
    }

    @include max-screen($breakpoint-mobile) {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      row-gap: 27px;
      column-gap: 0;
    }
  }

  &-item {
    @include max-screen($breakpoint-mobile) {
      flex-basis: 100%;
      width: 100%;
    }
  }

  &-link {
    position: relative;
    font-size: 18px;
    color: $black;
    border-bottom: 4px solid transparent;
    padding-block: 16px;
    transition: all $duration $ease;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
      border-bottom-width: px-to-vw(4);
      padding-block: px-to-vw(16);
    }

    @include max-screen($breakpoint-mobile) {
      display: block;
      font-size: 15px;
      text-align: left;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        width: 51px;
        height: 4px;
        background: url(#{$image-path}svg/icon-button2.svg) no-repeat center;
        background-size: 51px 4px;
      }
    }

    @include true-hover {
      opacity: 0.7;
      border-bottom: 4px solid $top-theme-color;
    }
  }
}
