/*! sanitize.css v4.1.0 | CC0 License | github.com/jonathantneal/sanitize.css */
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

template,
[hidden] {
  display: none;
}

*,
::before,
::after {
  background-repeat: no-repeat;
  box-sizing: inherit;
}

::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}

html {
  box-sizing: border-box;
  cursor: default;
  font-family: sans-serif;
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

hr {
  height: 0;
  overflow: visible;
}

nav ol,
nav ul {
  list-style: none;
}

abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
}

b,
strong {
  font-weight: inherit;
}

b,
strong {
  font-weight: bolder;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #ff0;
  color: #000;
}

progress {
  vertical-align: baseline;
}

small {
  font-size: 83.3333%;
}

sub,
sup {
  font-size: 83.3333%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

::-moz-selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}

::selection {
  background-color: #b3d4fc;
  color: #000;
  text-shadow: none;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

img {
  border-style: none;
}

svg {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}

a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  outline-width: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  color: inherit;
  font-size: 1em;
  margin: 0;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  display: table;
  max-width: 100%;
  padding: 0;
  white-space: normal;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[type=checkbox],
[type=radio] {
  padding: 0;
}

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-cancel-button,
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

[aria-busy=true] {
  cursor: progress;
}

[aria-controls] {
  cursor: pointer;
}

[aria-disabled] {
  cursor: default;
}

a,
area,
button,
input,
label,
select,
textarea,
[tabindex] {
  -ms-touch-action: manipulation;
  touch-action: manipulation;
}

[hidden][aria-hidden=false] {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute;
}
[hidden][aria-hidden=false]:focus {
  clip: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  overflow-x: hidden;
}

body {
  -webkit-text-size-adjust: auto;
  text-size-adjust: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: auto;
  font-size: 16px;
  font-family: ryo-gothic-plusn, sans-serif;
  color: #222;
  overflow-x: hidden;
}
@media screen and (max-width: 767px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
}

svg {
  display: block;
  width: 100%;
  height: 100%;
  fill: #000;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  outline: none;
}

a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
  outline: none !important;
  text-decoration: none;
}
a[href="javascript:void;"] {
  pointer-events: none;
}
a[href^=tel] {
  color: #222;
}
a:focus, a:active, a:visited {
  outline: none !important;
}

input,
textarea,
button,
select,
div,
span {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

div::before:focus, div::after:focus {
  outline: none !important;
}

img {
  display: block;
  max-width: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

/* GENERIC */
/* RESET */
@media only screen and (max-width: 767px) {
  .u-show-pc {
    display: none !important;
  }
}

.u-show-sp {
  display: none !important;
}
@media only screen and (max-width: 767px) {
  .u-show-sp {
    display: block !important;
  }
}
@media only screen and (max-width: 767px) {
  .u-show-sp.u-show-sp-flex {
    display: flex !important;
  }
}

.u-hide {
  display: none;
}

.l-container {
  width: 1060px;
  max-width: 100%;
  padding: 0 30px;
  margin: 0 auto;
}
@media only screen and (max-width: 1659px) {
  .l-container {
    width: 63.8554216867vw;
    padding: 0 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-container {
    width: 100%;
    padding: 0 40px;
  }
}
.l-container-large {
  width: 1660px;
}
@media only screen and (max-width: 1659px) {
  .l-container-large {
    width: 100vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-container-large {
    padding: 0;
  }
}

@media only screen and (max-width: 767px) {
  .l-com {
    padding-block-start: 53px;
  }
}
@media only screen and (max-width: 767px) {
  .l-com-function-automation-inner {
    padding-inline: 25px;
  }
}
.l-com-function-automation-title {
  text-align: center;
  margin-block-start: -40px;
}
@media only screen and (max-width: 1659px) {
  .l-com-function-automation-title {
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-com-function-automation-title {
    margin-block-start: -17px;
  }
}
.l-com-function-automation-cards {
  margin-block: 30px;
}
@media only screen and (max-width: 1659px) {
  .l-com-function-automation-cards {
    margin-block: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-com-function-automation-cards {
    margin-block: 0 25px;
  }
}
.l-com-function-support-title {
  text-align: center;
}
.l-com-function-support-cards {
  padding-block: 70px 80px;
  margin-block-start: -40px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .l-com-function-support-cards {
    padding-block: 4.2168674699vw 4.8192771084vw;
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-com-function-support-cards {
    padding-block: 15px 25px;
    margin-block-start: -17px;
  }
}
.l-com-banner {
  margin-block-start: 130px;
}
@media only screen and (max-width: 1659px) {
  .l-com-banner {
    margin-block-start: 7.8313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-com-banner {
    margin-block-start: 65px;
  }
}
.l-com-ltv {
  margin-block-start: 135px;
}
@media only screen and (max-width: 1659px) {
  .l-com-ltv {
    margin-block-start: 8.1325301205vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-com-ltv {
    margin-block-start: 62px;
  }
}

@media only screen and (max-width: 767px) {
  .l-efo {
    padding-block-start: 53px;
  }
}
.l-efo-ab-test-title {
  text-align: center;
  margin-block-start: -40px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-ab-test-title {
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-ab-test-title {
    margin-block-start: -17px;
  }
}
.l-efo-ab-test-card {
  padding-block: 80px 190px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-ab-test-card {
    padding-block: 4.8192771084vw 11.4457831325vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-ab-test-card {
    padding-block: 40px 53px;
  }
}
.l-efo-sms-title {
  text-align: center;
}
.l-efo-sms-card {
  padding-block: 120px 170px;
  margin-block-start: -40px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .l-efo-sms-card {
    padding-block: 7.2289156627vw 10.2409638554vw;
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-sms-card {
    padding-block: 85px 71px;
    margin-block-start: -48px;
  }
}
.l-efo-conversion-api-title {
  text-align: center;
  margin-block-start: -40px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-conversion-api-title {
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-conversion-api-title {
    margin-block-start: -17px;
  }
}
.l-efo-conversion-api-card {
  padding-block: 72px 150px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-conversion-api-card {
    padding-block: 4.3373493976vw 9.0361445783vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-conversion-api-card {
    padding-block: 40px 54px;
  }
}
.l-efo-keypad-title {
  text-align: center;
}
.l-efo-keypad-card {
  padding-block: 120px 110px;
  margin-block-start: -40px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .l-efo-keypad-card {
    padding-block: 7.2289156627vw 6.6265060241vw;
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-keypad-card {
    padding-block: 85px 68px;
    margin-block-start: -48px;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-restore-inner {
    padding-inline: 25px;
  }
}
.l-efo-restore-title {
  text-align: center;
  margin-block-start: -40px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-restore-title {
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-restore-title {
    margin-block-start: -17px;
  }
}
.l-efo-restore-card {
  padding-block: 80px 130px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-restore-card {
    padding-block: 4.8192771084vw 7.8313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-restore-card {
    padding-block: 40px 57px;
    padding-inline: 15px;
  }
}
.l-efo-dashboard-title {
  text-align: center;
}
.l-efo-dashboard-card {
  padding-block: 120px 170px;
  margin-block-start: -40px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .l-efo-dashboard-card {
    padding-block: 7.2289156627vw 10.2409638554vw;
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-dashboard-card {
    padding-block: 85px 68px;
    margin-block-start: -48px;
  }
}
.l-efo-data-title {
  text-align: center;
  margin-block-start: -40px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-data-title {
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-data-title {
    margin-block-start: -17px;
  }
}
.l-efo-data-card {
  padding-block-start: 80px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-data-card {
    padding-block-start: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-data-card {
    padding-block-start: 40px;
  }
}
.l-efo-data-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 50px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-data-list {
    column-gap: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-data-list {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 25px;
    column-gap: 0;
  }
}
.l-efo-banner {
  margin-block-start: 130px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-banner {
    margin-block-start: 7.8313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-banner {
    margin-block-start: 65px;
  }
}
.l-efo-cvr {
  margin-block-start: 135px;
}
@media only screen and (max-width: 1659px) {
  .l-efo-cvr {
    margin-block-start: 8.1325301205vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-efo-cvr {
    margin-block-start: 62px;
  }
}

@media only screen and (max-width: 767px) {
  .l-index {
    padding-block-start: 53px;
  }
}
.l-index-service {
  text-align: center;
  margin-block-start: 195px;
}
@media only screen and (max-width: 1659px) {
  .l-index-service {
    margin-block-start: 11.7469879518vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-service {
    margin-block-start: 58px;
  }
}
.l-index-service-efo {
  margin-block-start: 90px;
}
@media only screen and (max-width: 1659px) {
  .l-index-service-efo {
    margin-block-start: 5.421686747vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-service-efo {
    margin-block-start: 25px;
  }
}
.l-index-service-com {
  margin-block-start: 80px;
}
@media only screen and (max-width: 1659px) {
  .l-index-service-com {
    margin-block-start: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-service-com {
    margin-block-start: 28px;
  }
}
.l-index-optimization {
  margin-block-start: 80px;
}
@media only screen and (max-width: 1659px) {
  .l-index-optimization {
    margin-block-start: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-optimization {
    margin-block-start: 40px;
  }
}
.l-index-support {
  width: 1045px;
  max-width: 100%;
  margin-block-start: 45px;
}
@media only screen and (max-width: 1659px) {
  .l-index-support {
    width: 62.9518072289vw;
    margin-block-start: 2.7108433735vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-support {
    width: 100%;
    margin-block-start: 62px;
  }
}
.l-index-scene {
  margin-block-start: 192px;
  padding-block: 76px 70px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .l-index-scene {
    margin-block-start: 11.5662650602vw;
    padding-block: 4.578313253vw 4.2168674699vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-scene {
    margin-block-start: 56px;
    padding-block: 62px 215px;
  }
}
.l-index-scene-inner {
  width: 883px;
  max-width: 100%;
  margin-block-start: -158px;
}
@media only screen and (max-width: 1659px) {
  .l-index-scene-inner {
    width: 53.1927710843vw;
    margin-block-start: -9.5180722892vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-scene-inner {
    width: 100%;
    margin-block-start: 0;
    padding: 0;
  }
}
.l-index-flow {
  margin-block-start: 195px;
}
@media only screen and (max-width: 1659px) {
  .l-index-flow {
    margin-block-start: 11.7469879518vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-flow {
    margin-block-start: 457px;
  }
}
.l-index-achievement {
  margin-block-start: 188px;
}
@media only screen and (max-width: 1659px) {
  .l-index-achievement {
    margin-block-start: 11.3253012048vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-achievement {
    margin-block-start: 62px;
  }
}
.l-index-qualvatics {
  margin-block-start: 200px;
}
@media only screen and (max-width: 1659px) {
  .l-index-qualvatics {
    margin-block-start: 12.0481927711vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-qualvatics {
    margin-block-start: 65px;
  }
}
.l-index-topics {
  margin-block-start: 165px;
}
@media only screen and (max-width: 1659px) {
  .l-index-topics {
    margin-block-start: 9.9397590361vw;
  }
}
@media only screen and (max-width: 767px) {
  .l-index-topics {
    margin-block-start: 63px;
  }
}

.achievement {
  padding-inline: 50px;
}
@media only screen and (max-width: 1659px) {
  .achievement {
    padding-inline: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement {
    padding-inline: 0;
  }
}
.achievement-title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
}
@media only screen and (max-width: 1659px) {
  .achievement-title-wrapper {
    column-gap: 1.2048192771vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-title-wrapper {
    column-gap: 10px;
  }
}
.achievement-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #222;
  font-size: 30px;
  line-height: 1;
  color: #fff;
}
@media only screen and (max-width: 1659px) {
  .achievement-tag {
    width: 6.0240963855vw;
    height: 6.0240963855vw;
    font-size: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-tag {
    width: 46px;
    height: 46px;
    font-size: 14px;
  }
}
.achievement-title {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  font-family: din-2014, sans-serif;
  font-size: 120px;
  font-weight: bold;
  line-height: 1;
}
@media only screen and (max-width: 1659px) {
  .achievement-title {
    font-size: 7.2289156627vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-title {
    font-size: 56px;
    line-height: 1.28;
    align-items: center;
  }
}
.achievement-title span,
.achievement-title small {
  font-family: ryo-gothic-plusn, sans-serif;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .achievement-title span,
  .achievement-title small {
    line-height: 1.45;
  }
}
.achievement-title span {
  font-size: 70px;
}
@media only screen and (max-width: 1659px) {
  .achievement-title span {
    font-size: 4.2168674699vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-title span {
    font-size: 33px;
    margin-block-start: 5px;
  }
}
.achievement-title small {
  font-size: 48px;
  margin-inline-start: 10px;
}
@media only screen and (max-width: 1659px) {
  .achievement-title small {
    font-size: 2.8915662651vw;
    margin-inline-start: 0.6024096386vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-title small {
    font-size: 22px;
    margin-inline-start: 5px;
    margin-block-start: 10px;
  }
}
.achievement-content {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 53px;
  margin-block-start: 5px;
}
@media only screen and (max-width: 1659px) {
  .achievement-content {
    column-gap: 3.1927710843vw;
    margin-block-start: 0.3012048193vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-content {
    column-gap: 20px;
    margin-block-start: -10px;
  }
}
.achievement-desc {
  font-size: 48px;
  font-weight: bold;
  line-height: 1.45;
  text-align: right;
}
@media only screen and (max-width: 1659px) {
  .achievement-desc {
    font-size: 2.8915662651vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-desc {
    font-size: 16px;
  }
}
.achievement-info {
  position: relative;
  font-family: din-2014, sans-serif;
  font-weight: bold;
  color: #21aa92;
  line-height: 1.28;
}
.achievement-info span:first-of-type {
  position: absolute;
  top: 48px;
  left: 0;
  font-size: 35px;
  line-height: 1.45;
}
@media only screen and (max-width: 1659px) {
  .achievement-info span:first-of-type {
    top: 2.8915662651vw;
    font-size: 2.1084337349vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-info span:first-of-type {
    top: 12px;
    font-size: 12px;
  }
}
.achievement-info span:nth-of-type(2) {
  font-size: 100px;
}
@media only screen and (max-width: 1659px) {
  .achievement-info span:nth-of-type(2) {
    font-size: 6.0240963855vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-info span:nth-of-type(2) {
    font-size: 33px;
  }
}
.achievement-info span:last-of-type {
  font-size: 165px;
}
@media only screen and (max-width: 1659px) {
  .achievement-info span:last-of-type {
    font-size: 9.9397590361vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-info span:last-of-type {
    font-size: 55px;
  }
}
.achievement-image {
  padding-block: 57px 54px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .achievement-image {
    padding-block: 3.4337349398vw 3.2530120482vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-image {
    padding: 25px 40px;
    margin-block-start: 5px;
  }
}
.achievement-image img {
  display: block;
  width: 999px;
  max-width: 100%;
  margin: 0 auto;
}
@media only screen and (max-width: 1659px) {
  .achievement-image img {
    width: 60.1807228916vw;
  }
}
@media only screen and (max-width: 767px) {
  .achievement-image img {
    width: 100%;
  }
}

.banner {
  cursor: pointer;
  display: block;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .banner {
    width: calc(100% + 40px);
    margin-inline: -20px;
  }
}

.button {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 8px;
  min-width: 299px;
  max-width: 100%;
  padding: 24px 30px 22px;
  margin: 0 auto;
  border: 1px solid #222;
  font-weight: bold;
  line-height: 1.45;
  color: #222;
  text-align: left;
}
@media only screen and (max-width: 1659px) {
  .button {
    column-gap: 0.4819277108vw;
    min-width: 18.0120481928vw;
    padding: 1.4457831325vw 1.8072289157vw 1.3253012048vw;
    font-size: 0.9638554217vw;
  }
}
@media only screen and (max-width: 767px) {
  .button {
    column-gap: 8px;
    min-width: 220px;
    padding: 15px 20px;
    font-size: 13px;
  }
}
@media (any-hover: hover) {
  .button:hover .button-icon {
    transform: translateX(10px);
  }
}
.button:active .button-icon {
  transform: translateX(10px);
}
.button-icon {
  pointer-events: none;
  display: inline-block;
  width: 30px;
  height: 5px;
  background: url(../images/svg/icon-button.svg) no-repeat center;
  background-size: 30px 5px;
  transition: transform 0.25s ease;
}
@media only screen and (max-width: 1659px) {
  .button-icon {
    width: 1.8072289157vw;
    height: 0.3012048193vw;
    background-size: 1.8072289157vw 0.3012048193vw;
  }
}
@media only screen and (max-width: 767px) {
  .button-icon {
    width: 20px;
    height: 5px;
    background-size: 20px 5px;
  }
}

.card-list {
  position: relative;
}
.card-list-title {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .card-list-title {
    font-size: 2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .card-list-title {
    font-size: 25px;
  }
}
.card-list-title img {
  width: 345px;
  margin: 0 auto;
}
@media only screen and (max-width: 1659px) {
  .card-list-title img {
    width: 20.7831325301vw;
  }
}
@media only screen and (max-width: 767px) {
  .card-list-title img {
    width: 222px;
  }
}
.card-list-inner {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 31px;
  margin-block-start: 73px;
}
@media only screen and (max-width: 1659px) {
  .card-list-inner {
    column-gap: 1.8674698795vw;
    margin-block-start: 4.3975903614vw;
  }
}
@media only screen and (max-width: 767px) {
  .card-list-inner {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 25px;
    column-gap: 0;
    margin-block-start: 38px;
  }
}
.card-list-button {
  margin-block-start: 80px;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .card-list-button {
    margin-block-start: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .card-list-button {
    margin-block-start: 40px;
  }
}
.card-list-com .card-list-inner, .card-list-efo .card-list-inner {
  margin-block-start: 67px;
}
@media only screen and (max-width: 1659px) {
  .card-list-com .card-list-inner, .card-list-efo .card-list-inner {
    margin-block-start: 4.0361445783vw;
  }
}
@media only screen and (max-width: 767px) {
  .card-list-com .card-list-inner, .card-list-efo .card-list-inner {
    margin-block-start: 32px;
  }
}

@media (any-hover: hover) {
  .card-block:hover .card-image {
    transform: scale(1.2) translate3d(0, 0, 0);
  }
}
.card-block:active .card-image {
  transform: scale(1.2) translate3d(0, 0, 0);
}
.card-image-wrapper {
  border: 1px solid #000;
  overflow: hidden;
}
@media only screen and (max-width: 767px) {
  .card-image-wrapper {
    border: none;
  }
}
.card-image {
  display: block;
  width: 100%;
  padding-top: 62.6198083067%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  transition: transform 0.5s ease;
}
@media only screen and (max-width: 767px) {
  .card-image {
    padding-top: 62.5%;
  }
}
.card-content {
  padding: 30px 43px 30px 30px;
  border: 1px solid #dbdbdb;
  color: #222;
}
@media only screen and (max-width: 1659px) {
  .card-content {
    padding: 1.8072289157vw 2.5903614458vw 1.8072289157vw 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .card-content {
    padding: 20px 24px;
  }
}
.card-tag {
  display: inline-block;
  font-size: 11px;
  line-height: 1.45;
  color: #15adea;
  padding: 3px 13px 3px 9px;
  border: 2px solid #3b9cef;
  border-radius: 15px;
}
@media only screen and (max-width: 1659px) {
  .card-tag {
    font-size: 0.6626506024vw;
    padding: 0.1807228916vw 0.7831325301vw 0.1807228916vw 0.5421686747vw;
    border-width: 0.1204819277vw;
    border-radius: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .card-tag {
    font-size: 9px;
    padding: 3px 12px 3px 8px;
    border-width: 1px;
  }
}
.card-title {
  font-size: 18px;
  font-weight: bold;
  line-height: 1.56;
  margin-block-start: 11px;
}
@media only screen and (max-width: 1659px) {
  .card-title {
    font-size: 1.0843373494vw;
    margin-block-start: 0.6626506024vw;
  }
}
@media only screen and (max-width: 767px) {
  .card-title {
    font-size: 13px;
    line-height: 1.61;
    margin-block-start: 14px;
  }
}
.card-date {
  display: block;
  margin-block-start: 8px;
  font-family: din-2014, sans-serif;
  font-size: 13px;
  line-height: 1.28;
}
@media only screen and (max-width: 1659px) {
  .card-date {
    margin-block-start: 0.4819277108vw;
    font-size: 0.7831325301vw;
  }
}
@media only screen and (max-width: 767px) {
  .card-date {
    font-size: 11px;
    margin-block-start: 9px;
  }
}

.efo-function-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card {
    column-gap: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card {
    flex-direction: column;
    row-gap: 25px;
    column-gap: 0;
  }
}
.efo-function-card-content {
  flex-basis: 580px;
  width: 580px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-content {
    flex-basis: 34.9397590361vw;
    width: 34.9397590361vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-content {
    order: 2;
    flex-basis: 100%;
    width: 100%;
  }
}
.efo-function-card-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-info {
    column-gap: 0.9036144578vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-info {
    justify-content: center;
    column-gap: 10px;
  }
}
.efo-function-card-icon {
  width: 55px;
  height: 50px;
  background-size: 55px 50px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-icon {
    width: 3.313253012vw;
    height: 3.0120481928vw;
    background-size: 3.313253012vw 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-icon {
    width: 33px;
    height: 30px;
    background-size: 33px 30px;
  }
}
.efo-function-card-title {
  font-size: 33px;
  font-weight: bold;
  line-height: 1.45;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-title {
    font-size: 1.9879518072vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-title {
    font-size: 18px;
  }
}
.efo-function-card-desc {
  font-size: 18px;
  line-height: 2;
  margin-block-start: 38px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-desc {
    font-size: 1.0843373494vw;
    margin-block-start: 2.2891566265vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-desc {
    font-size: 13px;
    margin-block-start: 21px;
  }
}
.efo-function-card-image {
  flex-basis: 570px;
  width: 570px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-image {
    flex-basis: 34.3373493976vw;
    width: 34.3373493976vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-image {
    order: 1;
    flex-basis: 100%;
    width: 100%;
  }
}
.efo-function-card-reverse {
  flex-direction: row-reverse;
}
@media only screen and (max-width: 767px) {
  .efo-function-card-reverse {
    flex-direction: column;
  }
}
.efo-function-card-reverse .efo-function-card-info {
  width: calc(100% + 20px);
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-reverse .efo-function-card-info {
    width: calc(100% + 1.2048192771vw);
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-reverse .efo-function-card-info {
    width: 100%;
  }
}
.efo-function-card-sm {
  flex-direction: row-reverse;
  justify-content: flex-end;
  column-gap: 90px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-sm {
    column-gap: 5.421686747vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-sm {
    flex-direction: column;
  }
}
.efo-function-card-sm .efo-function-card-image {
  flex-basis: 340px;
  width: 340px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-sm .efo-function-card-image {
    flex-basis: 20.4819277108vw;
    width: 20.4819277108vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-sm .efo-function-card-image {
    flex-basis: 100%;
    width: 100%;
    padding-inline: 60px;
  }
}
.efo-function-card-ab .efo-function-card-content {
  flex-basis: 380px;
  width: 380px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-ab .efo-function-card-content {
    flex-basis: 22.8915662651vw;
    width: 22.8915662651vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-ab .efo-function-card-content {
    flex-basis: 100%;
    width: 100%;
  }
}
.efo-function-card-ab .efo-function-card-image {
  flex-basis: 570px;
  width: 570px;
  padding-inline: 40px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-ab .efo-function-card-image {
    flex-basis: 34.3373493976vw;
    width: 34.3373493976vw;
    padding-inline: 2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-ab .efo-function-card-image {
    flex-basis: 100%;
    width: 100%;
    padding-inline: 75px;
  }
}
.efo-function-card-lg .efo-function-card-content {
  flex-basis: 570px;
  width: 570px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-lg .efo-function-card-content {
    flex-basis: 34.3373493976vw;
    width: 34.3373493976vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-lg .efo-function-card-content {
    flex-basis: 100%;
    width: 100%;
  }
}
.efo-function-card-lg .efo-function-card-image {
  flex-basis: 380px;
  width: 380px;
  padding-inline: 40px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-lg .efo-function-card-image {
    flex-basis: 22.8915662651vw;
    width: 22.8915662651vw;
    padding-inline: 2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-lg .efo-function-card-image {
    flex-basis: 100%;
    width: 100%;
    padding-inline: 75px;
  }
}
.efo-function-card-restore .efo-function-card-content {
  flex-basis: 380px;
  width: 380px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-restore .efo-function-card-content {
    flex-basis: 22.8915662651vw;
    width: 22.8915662651vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-restore .efo-function-card-content {
    flex-basis: 100%;
    width: 100%;
  }
}
.efo-function-card-restore .efo-function-card-image {
  flex-basis: 570px;
  width: 570px;
  padding-inline: 40px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-restore .efo-function-card-image {
    flex-basis: 34.3373493976vw;
    width: 34.3373493976vw;
    padding-inline: 2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .efo-function-card-restore .efo-function-card-image {
    flex-basis: 100%;
    width: 100%;
    padding-inline: 75px;
  }
}
.efo-function-card-block {
  flex-direction: column;
}
.efo-function-card-block .efo-function-card-content {
  flex-basis: 100%;
  width: 100%;
  text-align: center;
}
.efo-function-card-block .efo-function-card-info {
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .efo-function-card-block .efo-function-card-desc {
    text-align: left;
  }
}
.efo-function-card-block .efo-function-card-image {
  flex-basis: 100%;
  width: 100%;
  margin-block-start: 28px;
}
@media only screen and (max-width: 1659px) {
  .efo-function-card-block .efo-function-card-image {
    margin-block-start: 1.686746988vw;
  }
}

@media only screen and (max-width: 767px) {
  .flow {
    margin-left: -20px;
  }
}
.flow-title {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .flow-title {
    font-size: 2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .flow-title {
    font-size: 25px;
  }
}
.flow-list {
  counter-reset: count;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 31px;
  margin-block-start: 47px;
}
@media only screen and (max-width: 1659px) {
  .flow-list {
    column-gap: 1.8674698795vw;
    margin-block-start: 2.8313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .flow-list {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    margin-block-start: 33px;
    padding-left: 20px;
  }
}
.flow-item {
  counter-increment: count;
  position: relative;
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .flow-item:not(:last-child) {
    padding-block-end: 45px;
  }
}
.flow-item::before {
  content: counter(count);
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  margin: 0 auto;
  background-color: #21aa92;
  border-radius: 50%;
  font-family: din-2014, sans-serif;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.28;
  color: #fff;
}
@media only screen and (max-width: 1659px) {
  .flow-item::before {
    width: 3.0120481928vw;
    height: 3.0120481928vw;
    font-size: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .flow-item::before {
    position: absolute;
    top: -4px;
    left: -14px;
    width: 30px;
    height: 30px;
    font-size: 20px;
  }
}
.flow-item:not(:last-child)::after {
  content: "";
  position: absolute;
  top: 25px;
  right: -55%;
  width: 100%;
  height: 2px;
  background-color: #21aa92;
}
@media only screen and (max-width: 1659px) {
  .flow-item:not(:last-child)::after {
    top: 1.5060240964vw;
    height: 0.1204819277vw;
  }
}
@media only screen and (max-width: 767px) {
  .flow-item:not(:last-child)::after {
    width: 1px;
    height: 100%;
    top: 0;
    left: 0;
  }
}
.flow-card {
  position: relative;
  display: flex;
  flex-direction: column;
  height: calc(100% - 75px);
  margin-block-start: 25px;
}
@media only screen and (max-width: 1659px) {
  .flow-card {
    height: calc(100% - 4.5180722892vw);
    margin-block-start: 1.5060240964vw;
  }
}
@media only screen and (max-width: 767px) {
  .flow-card {
    height: 100%;
    margin-block-start: 0;
  }
}
.flow-icon-wrapper {
  width: 90px;
  height: 80px;
  margin: 0 auto;
}
@media only screen and (max-width: 1659px) {
  .flow-icon-wrapper {
    width: 5.421686747vw;
    height: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .flow-icon-wrapper {
    width: 30px;
    height: 30px;
    margin: 0;
  }
}
.flow-icon {
  width: 90px;
  height: 80px;
  background-size: 90px 80px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1659px) {
  .flow-icon {
    width: 5.421686747vw;
    height: 4.8192771084vw;
    background-size: 5.421686747vw 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .flow-icon {
    width: 30px;
    height: 30px;
    background-size: 30px 30px;
  }
}
.flow-subtitle-wrapper {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .flow-subtitle-wrapper {
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 14px;
  }
}
.flow-subtitle {
  font-size: 25px;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
  margin-block-start: 17px;
}
@media only screen and (max-width: 1659px) {
  .flow-subtitle {
    font-size: 1.5060240964vw;
    margin-block-start: 1.0240963855vw;
  }
}
@media only screen and (max-width: 767px) {
  .flow-subtitle {
    display: inline-block;
    font-size: 18px;
    font-weight: normal;
    text-align: left;
    margin-block-start: 0;
    padding-inline-start: 26px;
  }
}
.flow-desc {
  height: 100%;
  margin-block-start: 22px;
  padding: 28px 29px 25px 31px;
  background-color: #f6f6f6;
  font-size: 16px;
  line-height: 1.79;
}
@media only screen and (max-width: 1659px) {
  .flow-desc {
    margin-block-start: 1.3253012048vw;
    padding: 1.686746988vw 1.7469879518vw 1.5060240964vw 1.8674698795vw;
    font-size: 0.9638554217vw;
  }
}
@media only screen and (max-width: 767px) {
  .flow-desc {
    margin-block-start: 10px;
    padding: 24px 25px 19px;
    font-size: 13px;
    line-height: 1.62;
  }
}
.flow-desc span {
  display: block;
  margin-block-start: 6px;
  font-size: 12px;
}
@media only screen and (max-width: 1659px) {
  .flow-desc span {
    margin-block-start: 0.3614457831vw;
    font-size: 0.7228915663vw;
  }
}
@media only screen and (max-width: 767px) {
  .flow-desc span {
    font-size: 9px;
  }
}

.footer {
  margin-block-start: 130px;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .footer {
    margin-block-start: 7.8313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer {
    margin-block-start: 30px;
  }
}
.footer-back-to-top {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  row-gap: 8px;
  transition: opacity 0.25s ease;
}
@media only screen and (max-width: 1659px) {
  .footer-back-to-top {
    row-gap: 0.4819277108vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-back-to-top {
    row-gap: 6px;
  }
}
@media (any-hover: hover) {
  .footer-back-to-top:hover {
    opacity: 0.7;
  }
}
.footer-back-to-top:active {
  opacity: 0.7;
}
.footer-back-to-top-icon {
  display: block;
}
.footer-back-to-top-icon::before {
  content: "";
  display: block;
  width: 30px;
  height: 10px;
  background-image: url(../images/svg/icon-arrow-up.svg);
  background-size: 30px 10px;
}
@media only screen and (max-width: 1659px) {
  .footer-back-to-top-icon::before {
    width: 1.8072289157vw;
    height: 0.6024096386vw;
    background-size: 1.8072289157vw 0.6024096386vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-back-to-top-icon::before {
    width: 30px;
    height: 10px;
    background-size: 30px 10px;
  }
}
.footer-back-to-top-text {
  font-family: din-2014, sans-serif;
  color: #222;
}
@media only screen and (max-width: 1659px) {
  .footer-back-to-top-text {
    font-size: 0.9638554217vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-back-to-top-text {
    font-size: 12px;
  }
}
.footer-content {
  background-color: #222;
  padding: 50px 30px 24px;
  margin-block-start: 21px;
}
@media only screen and (max-width: 1659px) {
  .footer-content {
    padding: 3.0120481928vw 1.8072289157vw 1.4457831325vw;
    margin-block-start: 1.265060241vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-content {
    padding: 40px 0 25px;
    margin-block-start: 18px;
  }
}
.footer-logo {
  display: block;
  width: 200px;
  margin: 0 auto;
}
@media only screen and (max-width: 1659px) {
  .footer-logo {
    width: 12.0481927711vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-logo {
    width: 110px;
  }
}
.footer-nav {
  width: 480px;
  margin: 47px auto 0;
}
@media only screen and (max-width: 1659px) {
  .footer-nav {
    width: 28.9156626506vw;
    margin-block-start: 2.8313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-nav {
    width: 100%;
    margin-block-start: 27px;
  }
}
.footer-nav-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  row-gap: 5px;
  column-gap: 60px;
}
@media only screen and (max-width: 1659px) {
  .footer-nav-list {
    row-gap: 0.3012048193vw;
    column-gap: 3.6144578313vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-nav-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0;
  }
}
.footer-nav-item {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .footer-nav-item {
    border-top: 1px solid #5e5e5e;
  }
  .footer-nav-item:nth-child(odd) {
    border-right: 1px solid #5e5e5e;
  }
  .footer-nav-item:nth-child(7), .footer-nav-item:last-child {
    border-bottom: 1px solid #5e5e5e;
  }
}
.footer-nav-item:nth-child(n):not(:nth-child(4n)) .footer-nav-link::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -30px;
  transform: translateY(-50%);
  width: 1px;
  height: 12px;
  background-color: #fff;
}
@media only screen and (max-width: 1659px) {
  .footer-nav-item:nth-child(n):not(:nth-child(4n)) .footer-nav-link::after {
    right: -1.8072289157vw;
    height: 0.7228915663vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-nav-item:nth-child(n):not(:nth-child(4n)) .footer-nav-link::after {
    display: none;
  }
}
.footer-nav-link {
  position: relative;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  transition: opacity 0.25s ease;
}
@media only screen and (max-width: 1659px) {
  .footer-nav-link {
    font-size: 0.7228915663vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-nav-link {
    display: block;
    padding: 20px 15px 18px;
    font-size: 12px;
  }
}
@media (any-hover: hover) {
  .footer-nav-link:hover {
    opacity: 0.7;
  }
}
.footer-nav-link:active {
  opacity: 0.7;
}
.footer-copyright {
  margin-block-start: 67px;
}
@media only screen and (max-width: 1659px) {
  .footer-copyright {
    margin-block-start: 4.0361445783vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-copyright {
    margin-block-start: 24px;
  }
}
.footer-copyright-text {
  font-size: 12px;
  color: #fff;
  text-transform: uppercase;
}
@media only screen and (max-width: 1659px) {
  .footer-copyright-text {
    font-size: 0.7228915663vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-copyright-text {
    font-size: 8px;
    color: #5e5e5e;
  }
}
.footer-copyright-text small {
  font-size: 12px;
}
@media only screen and (max-width: 1659px) {
  .footer-copyright-text small {
    font-size: 0.7228915663vw;
  }
}
@media only screen and (max-width: 767px) {
  .footer-copyright-text small {
    font-size: 8px;
  }
}

.function-card-list {
  padding-inline: 25px;
}
@media only screen and (max-width: 1659px) {
  .function-card-list {
    padding-inline: 1.5060240964vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-card-list {
    padding-inline: 0;
  }
}
.function-card-list-item {
  padding-block: 50px 48px;
  border-bottom: 2px solid #ccc;
}
@media only screen and (max-width: 1659px) {
  .function-card-list-item {
    padding-block: 3.0120481928vw 2.8915662651vw;
    border-bottom-width: 0.1204819277vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-card-list-item {
    padding-block: 39px 27px;
  }
}
@media only screen and (max-width: 767px) {
  .function-card-list-item:last-child {
    border-bottom: none;
  }
}
@media only screen and (max-width: 767px) {
  .function-card-list-automation {
    padding-inline: 15px;
  }
}
.function-card-list-automation .function-card-list-item:last-child {
  border-bottom: none;
}

.function-card {
  display: grid;
  grid-template-columns: 300px 650px;
  justify-content: space-between;
  align-items: center;
}
@media only screen and (max-width: 1659px) {
  .function-card {
    grid-template-columns: 18.0722891566vw 39.156626506vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-card {
    grid-template-columns: repeat(1, 1fr);
  }
}
.function-card-image {
  width: 300px;
}
@media only screen and (max-width: 1659px) {
  .function-card-image {
    width: 18.0722891566vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-card-image {
    width: 145px;
    margin: 0 auto;
  }
}
.function-card-content {
  margin-block-start: 20px;
}
@media only screen and (max-width: 1659px) {
  .function-card-content {
    margin-block-start: 1.2048192771vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-card-content {
    margin-block-start: 29px;
  }
}
.function-card-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
}
@media only screen and (max-width: 1659px) {
  .function-card-info {
    column-gap: 0.9036144578vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-card-info {
    justify-content: center;
    column-gap: 10px;
  }
}
.function-card-icon {
  width: 55px;
  height: 50px;
  background-size: 55px 50px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1659px) {
  .function-card-icon {
    width: 3.313253012vw;
    height: 3.0120481928vw;
    background-size: 3.313253012vw 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-card-icon {
    width: 33px;
    height: 30px;
    background-size: 33px 30px;
  }
}
.function-card-title {
  font-size: 35px;
  font-weight: bold;
  line-height: 1.45;
}
@media only screen and (max-width: 1659px) {
  .function-card-title {
    font-size: 2.1084337349vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-card-title {
    font-size: 18px;
  }
}
.function-card-desc {
  font-size: 18px;
  line-height: 2;
  margin-block-start: 13px;
}
@media only screen and (max-width: 1659px) {
  .function-card-desc {
    font-size: 1.0843373494vw;
    margin-block-start: 0.7831325301vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-card-desc {
    font-size: 13px;
    margin-block-start: 21px;
  }
}

.function {
  padding-block: 118px 170px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .function {
    padding-block: 7.1084337349vw 10.2409638554vw;
  }
}
@media only screen and (max-width: 767px) {
  .function {
    padding-block: 64px 80px;
  }
}
@media only screen and (max-width: 767px) {
  .function-inner {
    padding-inline: 0;
  }
}
.function-title {
  font-size: 50px;
  font-weight: 400;
  line-height: 1.45;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .function-title {
    font-size: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-title {
    font-size: 20px;
  }
}
.function-title span {
  display: block;
  font-size: 80px;
  font-weight: 700;
}
@media only screen and (max-width: 1659px) {
  .function-title span {
    font-size: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-title span {
    font-size: 35px;
    line-height: 1.67;
  }
}
.function-title small {
  font-size: 50px;
}
@media only screen and (max-width: 1659px) {
  .function-title small {
    font-size: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-title small {
    font-size: 16px;
  }
}
.function-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 0;
}
.function-list .function-item {
  flex: 1 1 33%;
  text-align: center;
  box-sizing: border-box;
  padding: 10px;
  max-width: 33%;
}
@media only screen and (max-width: 767px) {
  .function-list .function-item {
    padding: 2px;
  }
}
.function-efo {
  padding-block-end: 179px;
}
@media only screen and (max-width: 1659px) {
  .function-efo {
    padding-block-end: 10.7831325301vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-efo {
    padding-block-end: 80px;
  }
}
.function-efo .function-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  gap: 28px;
  margin-block-start: 54px;
}
@media only screen and (max-width: 1659px) {
  .function-efo .function-list {
    gap: 1.686746988vw;
  }
}
@media only screen and (max-width: 767px) {
  .function-efo .function-list {
    grid-template-columns: repeat(3, 1fr);
    gap: 5px;
  }
}
.function-efo .function-list .function-item {
  flex: inherit;
  text-align: inherit;
  box-sizing: inherit;
  padding: inherit;
  max-width: inherit;
}
@media only screen and (max-width: 767px) {
  .function-efo .function-list .function-item {
    padding: inherit;
  }
}

.graph {
  width: 100%;
  padding: 38px 50px 70px;
  border: 1px solid #ccc;
}
@media only screen and (max-width: 1659px) {
  .graph {
    padding: 2.2891566265vw 3.0120481928vw 4.2168674699vw;
  }
}
@media only screen and (max-width: 767px) {
  .graph {
    padding: 20px 30px 0;
    border: none;
    border-top: 1px solid #ccc;
  }
}
.graph-title {
  display: inline-block;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.45;
  padding-block-end: 10px;
  border-bottom: 1px solid currentColor;
}
@media only screen and (max-width: 1659px) {
  .graph-title {
    font-size: 0.9036144578vw;
    padding-block-end: 0.6024096386vw;
  }
}
@media only screen and (max-width: 767px) {
  .graph-title {
    font-size: 13px;
    font-weight: normal;
    padding-block-end: 0;
  }
}
.graph-inner {
  position: relative;
  margin-block-start: 30px;
}
@media only screen and (max-width: 1659px) {
  .graph-inner {
    margin-block-start: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .graph-inner {
    margin-block-start: 15px;
  }
}
.graph-inner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 150px;
  width: 2px;
  height: 100%;
  background-color: #ccc;
}
@media only screen and (max-width: 1659px) {
  .graph-inner::before {
    left: 9.0361445783vw;
    width: 0.1204819277vw;
  }
}
@media only screen and (max-width: 767px) {
  .graph-inner::before {
    left: 88px;
    width: 2px;
  }
}
.graph-inner::after {
  content: "0%";
  position: absolute;
  bottom: -35px;
  left: 150px;
  font-size: 14px;
}
@media only screen and (max-width: 1659px) {
  .graph-inner::after {
    bottom: -2.1084337349vw;
    left: 9.0361445783vw;
    font-size: 0.843373494vw;
  }
}
@media only screen and (max-width: 767px) {
  .graph-inner::after {
    bottom: -18px;
    left: 88px;
    font-size: 7px;
  }
}
.graph-wrapper {
  display: grid;
  grid-template-columns: 126px 400px;
  justify-content: flex-start;
  align-items: center;
  column-gap: 24px;
}
@media only screen and (max-width: 1659px) {
  .graph-wrapper {
    grid-template-columns: 7.5903614458vw 24.0963855422vw;
    column-gap: 1.4457831325vw;
  }
}
@media only screen and (max-width: 767px) {
  .graph-wrapper {
    grid-template-columns: 75px calc(100% - 88px);
    column-gap: 13px;
  }
}
.graph-wrapper:not(:first-of-type) {
  margin-block-start: 20px;
}
@media only screen and (max-width: 1659px) {
  .graph-wrapper:not(:first-of-type) {
    margin-block-start: 1.2048192771vw;
  }
}
@media only screen and (max-width: 767px) {
  .graph-wrapper:not(:first-of-type) {
    margin-block-start: 11px;
  }
}
.graph-label {
  display: block;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
}
@media only screen and (max-width: 1659px) {
  .graph-label {
    font-size: 0.843373494vw;
  }
}
@media only screen and (max-width: 767px) {
  .graph-label {
    font-size: 8px;
  }
}
.graph-label img {
  display: block;
  width: 100%;
}
.graph-bar {
  width: 100%;
  height: 50px;
  background-color: #f5f5f5;
}
@media only screen and (max-width: 1659px) {
  .graph-bar {
    height: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .graph-bar {
    height: 27px;
  }
}
.graph-percent {
  width: 0;
  height: 100%;
  transition: width 0.15s linear;
}

.header {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.9);
}
@media only screen and (max-width: 767px) {
  .header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
  }
}
@media only screen and (max-width: 767px) {
  .header.is-active {
    height: 100vh;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(3px);
  }
}
.header-inner {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-inline-start: 50px;
}
@media only screen and (max-width: 1659px) {
  .header-inner {
    padding-inline-start: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .header-inner {
    padding: 12px 13px;
    padding-inline-start: 13px;
  }
}
.header-logo {
  display: block;
  width: 200px;
}
@media only screen and (max-width: 1659px) {
  .header-logo {
    width: 12.0481927711vw;
  }
}
@media only screen and (max-width: 767px) {
  .header-logo {
    width: 120px;
  }
}
.header-menu-toggle {
  position: relative;
  width: 30px;
  height: 21px;
  z-index: 10;
}
.header-menu-toggle span {
  position: absolute;
  top: 6px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #222;
  transition: background-color 0.25s ease-in-out;
}
.header-menu-toggle span::before, .header-menu-toggle span::after {
  content: "";
  position: absolute;
  top: -10px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #222;
  transition: transform 0.25s ease-in-out;
}
.header-menu-toggle span::after {
  top: 10px;
}
.header.is-active .header-menu-toggle span {
  background-color: transparent;
}
.header.is-active .header-menu-toggle span::before, .header.is-active .header-menu-toggle span::after {
  top: 0;
  transform-origin: center;
}
.header.is-active .header-menu-toggle span::before {
  transform: rotate(-45deg);
}
.header.is-active .header-menu-toggle span::after {
  transform: rotate(45deg);
}
.header.is-active .header-menu-toggle + .header-menu-content {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 30px;
  width: 100%;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding-block: 55px;
  padding-inline: 66px 48px;
}
.header-menu-content {
  display: none;
}
.header-contact {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: flex-start;
  column-gap: 15px;
  width: 250px;
  height: 100px;
  background-color: #21aa92;
}
@media only screen and (max-width: 1659px) {
  .header-contact {
    column-gap: 0.9036144578vw;
    width: 15.0602409639vw;
    height: 6.0240963855vw;
  }
}
@media only screen and (max-width: 767px) {
  .header-contact {
    flex-shrink: 0;
    column-gap: 15px;
    width: 100%;
    height: 65px;
  }
}
@media (any-hover: hover) {
  .header-contact:hover .header-contact-icon,
  .header-contact:hover .header-contact-text {
    opacity: 0.7;
  }
}
.header-contact:active .header-contact-icon,
.header-contact:active .header-contact-text {
  opacity: 0.7;
}
.header-contact-icon {
  display: block;
  transition: opacity 0.25s ease;
}
.header-contact-icon::before {
  content: "";
  display: block;
  width: 40px;
  height: 40px;
  background-image: url(../images/svg/icon-message.svg);
  background-size: 40px 40px;
}
@media only screen and (max-width: 1659px) {
  .header-contact-icon::before {
    width: 2.4096385542vw;
    height: 2.4096385542vw;
    background-size: 2.4096385542vw 2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .header-contact-icon::before {
    width: 25px;
    height: 25px;
    background-size: 25px 25px;
  }
}
.header-contact-text {
  font-family: ryo-gothic-plusn, sans-serif;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
  transition: opacity 0.25s ease;
}
@media only screen and (max-width: 1659px) {
  .header-contact-text {
    font-size: 1.3253012048vw;
  }
}
@media only screen and (max-width: 767px) {
  .header-contact-text {
    font-size: 15px;
  }
}

.heading {
  position: relative;
  z-index: 1;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-block: 15px 17px;
  padding-inline: 50px 57px;
  transform: skew(10deg);
  font-size: 40px;
  font-weight: bold;
  line-height: 1.21;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .heading {
    padding-block: 0.9036144578vw 1.0240963855vw;
    padding-inline: 3.0120481928vw 3.4337349398vw;
    font-size: 2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .heading {
    padding-block: 4px 5px;
    padding-inline: 18px 25px;
    font-size: 18px;
    line-height: 1.45;
  }
}
.heading span {
  transform: skew(-10deg);
}

.icon-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 27px;
  height: 310px;
  background-color: #fff;
}
@media only screen and (max-width: 1659px) {
  .icon-card {
    row-gap: 1.6265060241vw;
    height: 18.6746987952vw;
  }
}
@media only screen and (max-width: 767px) {
  .icon-card {
    row-gap: 14px;
    height: 120px;
    padding-inline: 12px;
  }
}
.icon-card-image {
  width: 170px;
  height: 130px;
  background-size: 170px 130px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1659px) {
  .icon-card-image {
    width: 10.2409638554vw;
    height: 7.8313253012vw;
    background-size: 10.2409638554vw 7.8313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .icon-card-image {
    width: 60px;
    height: 45px;
    background-size: 60px 45px;
  }
}
.icon-card-title {
  font-size: 21px;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .icon-card-title {
    font-size: 1.265060241vw;
  }
}
@media only screen and (max-width: 767px) {
  .icon-card-title {
    font-size: 12px;
    text-align: center;
  }
}
.icon-card-efo {
  height: 232px;
  row-gap: 17px;
}
@media only screen and (max-width: 1659px) {
  .icon-card-efo {
    height: 13.9759036145vw;
    row-gap: 1.0240963855vw;
  }
}
@media only screen and (max-width: 767px) {
  .icon-card-efo {
    height: 120px;
    row-gap: 14px;
    padding-inline: 8px;
  }
}
.icon-card-efo .icon-card-image {
  width: 130px;
  height: 90px;
  background-size: 130px 90px;
}
@media only screen and (max-width: 1659px) {
  .icon-card-efo .icon-card-image {
    width: 7.8313253012vw;
    height: 5.421686747vw;
    background-size: 7.8313253012vw 5.421686747vw;
  }
}
@media only screen and (max-width: 767px) {
  .icon-card-efo .icon-card-image {
    width: 60px;
    height: 45px;
    background-size: 60px 45px;
  }
}

.merit-card {
  display: grid;
  grid-template-columns: 620px 330px;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
}
@media only screen and (max-width: 1659px) {
  .merit-card {
    grid-template-columns: 37.3493975904vw 19.8795180723vw;
    column-gap: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-card {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    row-gap: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .merit-card-content {
    order: 2;
  }
}
.merit-card-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  column-gap: 31px;
}
@media only screen and (max-width: 1659px) {
  .merit-card-info {
    column-gap: 1.8674698795vw;
  }
}
@media only screen and (max-width: 1659px) and (max-width: 767px) {
  .merit-card-info {
    align-items: center;
    column-gap: 13px;
  }
}
.merit-card-count {
  display: block;
  font-family: din-2014, sans-serif;
  font-size: 150px;
  line-height: 0.8;
  color: #6cb53c;
}
@media only screen and (max-width: 1659px) {
  .merit-card-count {
    font-size: 9.0361445783vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-card-count {
    font-size: 85px;
  }
}
.merit-card-title {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.5;
}
@media only screen and (max-width: 1659px) {
  .merit-card-title {
    font-size: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-card-title {
    font-size: 16px;
  }
}
.merit-card-desc {
  font-size: 18px;
  line-height: 2;
  margin-block-start: 47px;
}
@media only screen and (max-width: 1659px) {
  .merit-card-desc {
    font-size: 1.0843373494vw;
    margin-block-start: 2.8313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-card-desc {
    font-size: 13px;
    margin-block-start: 19px;
  }
}
.merit-card-icon {
  width: 330px;
  height: 300px;
  background-size: 330px 300px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1659px) {
  .merit-card-icon {
    width: 19.8795180723vw;
    height: 18.0722891566vw;
    background-size: 19.8795180723vw 18.0722891566vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-card-icon {
    order: 1;
    width: 230px;
    height: 210px;
    background-size: 230px 210px;
    margin: 0 auto;
  }
}
.merit-card-efo .merit-card-info {
  align-items: center;
}
.merit-card-efo .merit-card-count {
  color: #29a6d5;
}
.merit-card-efo .merit-card-desc {
  margin-block-start: 30px;
}
@media only screen and (max-width: 1659px) {
  .merit-card-efo .merit-card-desc {
    margin-block-start: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-card-efo .merit-card-desc {
    margin-block-start: 25px;
  }
}
.merit-card-efo .merit-card-icon {
  height: 270px;
  background-size: 330px 270px;
}
@media only screen and (max-width: 1659px) {
  .merit-card-efo .merit-card-icon {
    height: 16.265060241vw;
    background-size: 19.8795180723vw 16.265060241vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-card-efo .merit-card-icon {
    height: 210px;
    background-size: 230px 210px;
  }
}

.merit {
  padding-block: 118px 114px;
}
@media only screen and (max-width: 1659px) {
  .merit {
    padding-block: 7.1084337349vw 6.8674698795vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit {
    padding-block: 55px 22px;
  }
}
.merit-title {
  line-height: 1.45;
  text-align: center;
}
.merit-title-en {
  font-size: 45px;
  font-weight: normal;
}
@media only screen and (max-width: 1659px) {
  .merit-title-en {
    font-size: 2.7108433735vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-title-en {
    font-size: 20px;
  }
}
.merit-title-en small {
  font-size: 35px;
}
@media only screen and (max-width: 1659px) {
  .merit-title-en small {
    font-size: 2.1084337349vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-title-en small {
    font-size: 16px;
  }
}
.merit-title-jp {
  display: block;
  font-size: 80px;
  font-weight: bold;
}
@media only screen and (max-width: 1659px) {
  .merit-title-jp {
    font-size: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-title-jp {
    font-size: 35px;
    line-height: 1.8;
  }
}
.merit-title-jp-number {
  font-size: 90px;
  line-height: 1.21;
}
@media only screen and (max-width: 1659px) {
  .merit-title-jp-number {
    font-size: 5.421686747vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-title-jp-number {
    font-size: 45px;
  }
}
.merit-title-jp small {
  font-size: 45px;
}
@media only screen and (max-width: 1659px) {
  .merit-title-jp small {
    font-size: 2.7108433735vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-title-jp small {
    font-size: 20px;
  }
}
.merit-list {
  display: block;
  margin-block-start: -15px;
}
@media only screen and (max-width: 1659px) {
  .merit-list {
    margin-block-start: -0.9036144578vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-list {
    margin-block-start: -18px;
  }
}
.merit-item {
  padding-block: 70px 63px;
}
@media only screen and (max-width: 1659px) {
  .merit-item {
    padding-block: 4.2168674699vw 3.7951807229vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-item {
    padding-block: 40px 29px;
  }
}
.merit-item:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.merit-efo {
  padding-block: 124px 50px;
}
@media only screen and (max-width: 1659px) {
  .merit-efo {
    padding-block: 7.4698795181vw 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-efo {
    padding-block: 59px 22px;
  }
}
.merit-efo .merit-title-en small {
  font-size: 45px;
}
@media only screen and (max-width: 1659px) {
  .merit-efo .merit-title-en small {
    font-size: 2.7108433735vw;
  }
}
@media only screen and (max-width: 767px) {
  .merit-efo .merit-title-en small {
    font-size: 16px;
  }
}

.mini-card {
  display: block;
  height: 100%;
  padding: 50px 50px 34px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .mini-card {
    padding: 3.0120481928vw 3.0120481928vw 2.0481927711vw;
  }
}
@media only screen and (max-width: 767px) {
  .mini-card {
    padding: 25px 25px 17px;
  }
}
.mini-card-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 15px;
}
@media only screen and (max-width: 1659px) {
  .mini-card-info {
    column-gap: 0.9036144578vw;
  }
}
@media only screen and (max-width: 767px) {
  .mini-card-info {
    column-gap: 8px;
  }
}
.mini-card-icon {
  width: 55px;
  height: 50px;
  background-size: 55px 50px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1659px) {
  .mini-card-icon {
    width: 3.313253012vw;
    height: 3.0120481928vw;
    background-size: 3.313253012vw 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .mini-card-icon {
    width: 28px;
    height: 25px;
    background-size: 28px 25px;
  }
}
.mini-card-title {
  font-size: 33px;
  font-weight: bold;
  line-height: 1.45;
}
@media only screen and (max-width: 1659px) {
  .mini-card-title {
    font-size: 1.9879518072vw;
  }
}
@media only screen and (max-width: 767px) {
  .mini-card-title {
    font-size: 20px;
  }
}
.mini-card-desc {
  font-size: 18px;
  line-height: 2;
  margin-block-start: 38px;
}
@media only screen and (max-width: 1659px) {
  .mini-card-desc {
    font-size: 1.0843373494vw;
    margin-block-start: 2.2891566265vw;
  }
}
@media only screen and (max-width: 767px) {
  .mini-card-desc {
    font-size: 13px;
    margin-block-start: 14px;
  }
}

.mv {
  position: relative;
  display: grid;
  grid-template-columns: 620px 291px;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 89px;
  padding-block: 117px 101px;
}
@media only screen and (max-width: 1659px) {
  .mv {
    grid-template-columns: 37.3493975904vw 17.5301204819vw;
    column-gap: 5.3614457831vw;
    padding-block: 7.0481927711vw 6.0843373494vw;
  }
}
@media only screen and (max-width: 767px) {
  .mv {
    display: block;
    padding: 40px 0 289px;
  }
}
@media only screen and (max-width: 767px) {
  .mv-content {
    width: 295px;
    max-width: 100%;
    margin: 0 auto;
  }
}
.mv-title {
  opacity: 0;
  margin: 0;
  font-family: din-2014, sans-serif;
  font-weight: bold;
  line-height: 1.28;
  color: #6cb53c;
}
.mv-title-main {
  display: block;
}
.mv-title-main-en {
  font-size: 88px;
}
@media only screen and (max-width: 1659px) {
  .mv-title-main-en {
    font-size: 5.3012048193vw;
  }
}
@media only screen and (max-width: 767px) {
  .mv-title-main-en {
    font-size: 43px;
  }
}
.mv-title-main-jp {
  font-family: ryo-gothic-plusn, sans-serif;
  font-size: 72px;
}
@media only screen and (max-width: 1659px) {
  .mv-title-main-jp {
    font-size: 4.3373493976vw;
  }
}
@media only screen and (max-width: 767px) {
  .mv-title-main-jp {
    font-size: 35px;
  }
}
.mv-title-main small {
  font-family: ryo-gothic-plusn, sans-serif;
  font-size: 52px;
  color: #222;
}
@media only screen and (max-width: 1659px) {
  .mv-title-main small {
    font-size: 3.1325301205vw;
  }
}
@media only screen and (max-width: 767px) {
  .mv-title-main small {
    font-size: 25px;
  }
}
.mv-title-sub {
  display: block;
  font-size: 100px;
  margin-block-start: -35px;
}
@media only screen and (max-width: 1659px) {
  .mv-title-sub {
    font-size: 6.0240963855vw;
    margin-block-start: -2.1084337349vw;
  }
}
@media only screen and (max-width: 767px) {
  .mv-title-sub {
    font-size: 50px;
    margin-block-start: -15px;
  }
}
.mv-desc {
  opacity: 0;
  font-size: 22px;
  line-height: 2;
  margin-block-start: 57px;
}
@media only screen and (max-width: 1659px) {
  .mv-desc {
    font-size: 1.3253012048vw;
    margin-block-start: 3.4337349398vw;
  }
}
@media only screen and (max-width: 767px) {
  .mv-desc {
    font-size: 13px;
    margin-block-start: 19px;
  }
}
.mv-image {
  position: absolute;
  top: 130px;
  right: 0;
  width: 291px;
}
@media only screen and (max-width: 1659px) {
  .mv-image {
    top: 7.8313253012vw;
    width: 17.5301204819vw;
  }
}
@media only screen and (max-width: 767px) {
  .mv-image {
    top: auto;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    width: 230px;
    margin-block-start: 29px;
  }
}
.mv-efo .mv-title {
  color: #29a6d5;
}

@media only screen and (max-width: 767px) {
  .nav-menu {
    width: 100%;
    border-top: 1px solid #ccc;
  }
}
@media only screen and (max-width: 767px) {
  .nav-menu-list {
    display: flex;
    flex-wrap: wrap;
    margin-block-start: 5px;
  }
}
@media only screen and (max-width: 767px) {
  .nav-menu-item {
    flex-basis: 50%;
    width: 50%;
    margin-block-start: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .nav-menu-link {
    display: block;
    font-size: 13px;
    color: #000;
    text-align: center;
  }
}

.nav {
  padding-block: 38px 35px;
}
@media only screen and (max-width: 1659px) {
  .nav {
    padding-block: 2.2891566265vw 2.1084337349vw;
  }
}
@media only screen and (max-width: 767px) {
  .nav {
    width: 100%;
    padding-block: 0;
  }
}
.nav-list {
  display: flex;
  column-gap: 47px;
}
@media only screen and (max-width: 1659px) {
  .nav-list {
    column-gap: 2.8313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .nav-list {
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 27px;
    column-gap: 0;
  }
}
@media only screen and (max-width: 767px) {
  .nav-item {
    flex-basis: 100%;
    width: 100%;
  }
}
.nav-link {
  position: relative;
  font-size: 18px;
  color: #000;
  border-bottom: 4px solid transparent;
  padding-block: 16px;
  transition: all 0.25s ease;
}
@media only screen and (max-width: 1659px) {
  .nav-link {
    font-size: 1.0843373494vw;
    border-bottom-width: 0.2409638554vw;
    padding-block: 0.9638554217vw;
  }
}
@media only screen and (max-width: 767px) {
  .nav-link {
    display: block;
    font-size: 15px;
    text-align: left;
  }
  .nav-link::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 51px;
    height: 4px;
    background: url(../images/svg/icon-button2.svg) no-repeat center;
    background-size: 51px 4px;
  }
}
@media (any-hover: hover) {
  .nav-link:hover {
    opacity: 0.7;
    border-bottom: 4px solid #21aa92;
  }
}
.nav-link:active {
  opacity: 0.7;
  border-bottom: 4px solid #21aa92;
}

.optimization {
  padding-inline: 50px;
}
@media only screen and (max-width: 1659px) {
  .optimization {
    padding-inline: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization {
    padding-inline: 5px;
  }
}
.optimization-heading-wrapper {
  text-align: center;
}
.optimization-wrapper {
  padding-block: 116px 120px;
  margin-block-start: -40px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .optimization-wrapper {
    padding-block: 6.9879518072vw 7.2289156627vw;
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-wrapper {
    padding-block: 54px 65px;
    margin-block-start: -17px;
  }
}
.optimization-container {
  width: 1062px;
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .optimization-container {
    width: 100%;
    padding-inline: 35px;
  }
}
.optimization-title {
  font-size: 59px;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .optimization-title {
    font-size: 3.5542168675vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-title {
    font-size: 27.5px;
  }
}
.optimization-title span {
  font-size: 42px;
  font-weight: 500;
}
@media only screen and (max-width: 1659px) {
  .optimization-title span {
    font-size: 2.5301204819vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-title span {
    font-size: 18px;
  }
}
.optimization-title small {
  display: inline-block;
  font-size: 36px;
  font-weight: normal;
  font-weight: 500;
}
@media only screen and (max-width: 1659px) {
  .optimization-title small {
    font-size: 2.1686746988vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-title small {
    font-size: 19px;
  }
}
.optimization-inner {
  display: grid;
  grid-template-columns: 380px 570px;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
  margin-block-start: 62px;
}
@media only screen and (max-width: 1659px) {
  .optimization-inner {
    grid-template-columns: 22.8915662651vw 34.3373493976vw;
    column-gap: 3.0120481928vw;
    margin-block-start: 3.734939759vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-inner {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    margin-block-start: 31px;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-content {
    order: 2;
  }
}
.optimization-content-title {
  position: relative;
  padding-inline-start: 27px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.5;
}
@media only screen and (max-width: 1659px) {
  .optimization-content-title {
    padding-inline-start: 1.6265060241vw;
    font-size: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-content-title {
    padding-inline-start: 14px;
    font-size: 18px;
    line-height: 1.6;
    margin-block: 38px 23px;
  }
}
.optimization-content-title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 120px;
  background-color: #21aa92;
}
@media only screen and (max-width: 1659px) {
  .optimization-content-title::before {
    width: 0.1807228916vw;
    height: 7.2289156627vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-content-title::before {
    width: 2px;
    height: 50px;
  }
}
.optimization-content-desc {
  font-size: 18px;
  line-height: 2;
  margin-block-start: 37px;
}
@media only screen and (max-width: 1659px) {
  .optimization-content-desc {
    font-size: 1.0843373494vw;
    margin-block-start: 2.2289156627vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-content-desc {
    font-size: 13px;
    margin-block-start: 0;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-image {
    order: 1;
  }
}
.optimization-image-inner {
  position: relative;
}
.optimization-image img {
  display: block;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .optimization-image img {
    width: calc(100% - 20px);
    margin-block-start: 29px;
  }
}
.optimization-image-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background-color: #21aa92;
  line-height: 1.45;
  color: #fff;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .optimization-image-tag {
    width: 9.6385542169vw;
    height: 9.6385542169vw;
    font-size: 0.9638554217vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-image-tag {
    width: 80px;
    height: 80px;
    font-size: 8px;
  }
}
.optimization-image-tag:first-of-type {
  top: 150px;
  right: -110px;
}
@media only screen and (max-width: 1659px) {
  .optimization-image-tag:first-of-type {
    top: 9.0361445783vw;
    right: -6.6265060241vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-image-tag:first-of-type {
    top: 38px;
    right: -20px;
  }
}
.optimization-image-tag:last-of-type {
  bottom: -40px;
  right: -10px;
}
@media only screen and (max-width: 1659px) {
  .optimization-image-tag:last-of-type {
    bottom: -2.4096385542vw;
    right: -0.6024096386vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-image-tag:last-of-type {
    bottom: -10px;
    right: 5px;
  }
}
.optimization-banner {
  display: grid;
  grid-template-columns: 270px calc(100% - 270px);
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-block-start: 80px;
  background-color: #fff;
}
@media only screen and (max-width: 1659px) {
  .optimization-banner {
    grid-template-columns: 16.265060241vw calc(100% - 16.265060241vw);
    padding: 0.6024096386vw;
    margin-block-start: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-banner {
    grid-template-columns: repeat(1, 1fr);
    padding: 5px 5px 26px;
    margin-block-start: 30px;
  }
}
.optimization-banner-icon-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 15px 13px;
  background-color: #21aa92;
}
@media only screen and (max-width: 1659px) {
  .optimization-banner-icon-wrapper {
    padding: 1.2048192771vw 0.9036144578vw 0.7831325301vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-banner-icon-wrapper {
    flex-direction: row;
    column-gap: 10px;
    padding: 15px 15px 5px;
  }
}
.optimization-banner-icon-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -29px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 65px solid transparent;
  border-bottom: 65px solid transparent;
  border-left: 30px solid #21aa92;
}
@media only screen and (max-width: 1659px) {
  .optimization-banner-icon-wrapper::after {
    right: -1.7469879518vw;
    border-top-width: 3.9156626506vw;
    border-bottom-width: 3.9156626506vw;
    border-left-width: 1.8072289157vw;
  }
}
@media only screen and (max-width: 1023px) {
  .optimization-banner-icon-wrapper::after {
    right: -1.7469879518vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-banner-icon-wrapper::after {
    top: auto;
    right: auto;
    bottom: -20px;
    left: 50%;
    transform: translateX(-50%);
    border-top: 20px solid #21aa92;
    border-bottom: none;
    border-right: 142px solid transparent;
    border-left: 142px solid transparent;
  }
}
.optimization-banner-icon {
  width: 100px;
  height: 60px;
  background-size: 100px 60px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1659px) {
  .optimization-banner-icon {
    width: 6.0240963855vw;
    height: 3.6144578313vw;
    background-size: 6.0240963855vw 3.6144578313vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-banner-icon {
    width: 50px;
    height: 30px;
    background-size: 50px 30px;
  }
}
.optimization-banner-desc {
  font-size: 20px;
  font-weight: bold;
  line-height: 1.45;
  color: #fff;
  margin-block-start: 8px;
}
@media only screen and (max-width: 1659px) {
  .optimization-banner-desc {
    font-size: 1.2048192771vw;
    margin-block-start: 0.4819277108vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-banner-desc {
    font-size: 16px;
    margin-block-start: 0;
  }
}
.optimization-banner-content {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .optimization-banner-content {
    margin-block-start: 35px;
  }
}
.optimization-banner-title {
  font-size: 43px;
  font-weight: bold;
  line-height: 1.21;
  color: #21aa92;
}
@media only screen and (max-width: 1659px) {
  .optimization-banner-title {
    font-size: 2.5903614458vw;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-banner-title {
    font-size: 26px;
  }
}
.optimization-banner-title span,
.optimization-banner-title small {
  color: #222;
}
.optimization-banner-title small {
  display: block;
  font-size: 30px;
  font-weight: 500;
  line-height: 1.45;
}
@media only screen and (max-width: 1659px) {
  .optimization-banner-title small {
    font-size: 1.8072289157vw;
    padding-bottom: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .optimization-banner-title small {
    font-size: 13px;
  }
}

.scene {
  position: relative;
  display: grid;
  grid-template-columns: 470px 300px;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 53px;
  margin-top: 56px;
}
@media only screen and (max-width: 1659px) {
  .scene {
    grid-template-columns: 28.313253012vw 18.0722891566vw;
    column-gap: 3.1927710843vw;
  }
}
@media only screen and (max-width: 767px) {
  .scene {
    display: block;
    margin-top: inherit;
  }
}
.scene-title {
  font-size: 36px;
  font-weight: bold;
  line-height: 1.45;
}
@media only screen and (max-width: 1659px) {
  .scene-title {
    font-size: 2.1686746988vw;
  }
}
@media only screen and (max-width: 767px) {
  .scene-title {
    font-size: 25px;
    text-align: center;
    padding-inline: 40px;
  }
}
.scene-desc {
  font-size: 18px;
  line-height: 2;
  margin-block-start: 40px;
}
@media only screen and (max-width: 1659px) {
  .scene-desc {
    font-size: 1.0843373494vw;
    margin-block-start: 2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .scene-desc {
    font-size: 13px;
    margin-block-start: 31px;
    padding-inline: 40px;
  }
}
.scene-list {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  row-gap: 11px;
  margin-block-start: 39px;
}
@media only screen and (max-width: 1659px) {
  .scene-list {
    row-gap: 0.6626506024vw;
    margin-block-start: 2.3493975904vw;
  }
}
@media only screen and (max-width: 767px) {
  .scene-list {
    grid-template-columns: repeat(4, 1fr);
    column-gap: 2px;
    row-gap: 0;
    margin-block-start: 25px;
  }
}
.scene-button {
  appearance: none;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  width: calc(100% - 20px);
  padding-block: 16px 15px;
  background-color: #e8e8e8;
  border-left: 2px solid;
  transition: all 0.25s ease;
  min-height: 56px;
}
@media only screen and (max-width: 1659px) {
  .scene-button {
    column-gap: 1.2048192771vw;
    width: calc(100% - 1.2048192771vw);
    padding-block: 0.9638554217vw 0.9036144578vw;
    border-left-width: 0.1204819277vw;
  }
}
@media only screen and (max-width: 767px) {
  .scene-button {
    flex-direction: column;
    row-gap: 10px;
    column-gap: 0;
    width: 100%;
    padding-block: 16px 10px;
    border-left: none;
  }
}
.scene-button::after {
  content: "";
  position: absolute;
  top: 50%;
  right: -20px;
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 20px solid #e8e8e8;
  transition: all 0.25s ease;
}
@media only screen and (max-width: 1659px) {
  .scene-button::after {
    border-top-width: 28px;
    border-bottom-width: 28px;
  }
}
@media only screen and (max-width: 767px) {
  .scene-button::after {
    top: auto;
    right: auto;
    bottom: -9px;
    left: 50%;
    transform: translateX(-50%);
    border-top: 9px solid #e8e8e8;
    border-bottom: none;
    border-right: 13px solid transparent;
    border-left: 13px solid transparent;
  }
}
.scene-button-product {
  color: #bb5281;
}
@media (any-hover: hover) {
  .scene-button-product:hover {
    color: #fff;
    background-color: #bb5281;
    border-left-color: #bb5281;
  }
  .scene-button-product:hover::after {
    border-left-color: #bb5281;
  }
}
@media only screen and (any-hover: hover) and (max-width: 767px) {
  .scene-button-product:hover::after {
    border-top-color: #bb5281;
    border-left-color: transparent;
  }
}
.scene-button-product:active {
  color: #fff;
  background-color: #bb5281;
  border-left-color: #bb5281;
}
.scene-button-product:active::after {
  border-left-color: #bb5281;
}
@media only screen and (max-width: 767px) {
  .scene-button-product:active::after {
    border-top-color: #bb5281;
    border-left-color: transparent;
  }
}
.scene-button-product.is-active {
  color: #fff;
  background-color: #bb5281;
  border-left-color: #bb5281;
}
.scene-button-product.is-active::after {
  border-left-color: #bb5281;
}
@media only screen and (max-width: 767px) {
  .scene-button-product.is-active::after {
    border-top-color: #bb5281;
    border-left-color: transparent;
  }
}
.scene-button-information {
  color: #4ea199;
}
@media (any-hover: hover) {
  .scene-button-information:hover {
    color: #fff;
    background-color: #4ea199;
    border-left-color: #4ea199;
  }
  .scene-button-information:hover::after {
    border-left-color: #4ea199;
  }
}
@media only screen and (any-hover: hover) and (max-width: 767px) {
  .scene-button-information:hover::after {
    border-top-color: #4ea199;
    border-left-color: transparent;
  }
}
.scene-button-information:active {
  color: #fff;
  background-color: #4ea199;
  border-left-color: #4ea199;
}
.scene-button-information:active::after {
  border-left-color: #4ea199;
}
@media only screen and (max-width: 767px) {
  .scene-button-information:active::after {
    border-top-color: #4ea199;
    border-left-color: transparent;
  }
}
.scene-button-information.is-active {
  color: #fff;
  background-color: #4ea199;
  border-left-color: #4ea199;
}
.scene-button-information.is-active::after {
  border-left-color: #4ea199;
}
@media only screen and (max-width: 767px) {
  .scene-button-information.is-active::after {
    border-top-color: #4ea199;
    border-left-color: transparent;
  }
}
.scene-button-member {
  color: #ce7168;
}
@media (any-hover: hover) {
  .scene-button-member:hover {
    color: #fff;
    background-color: #ce7168;
    border-left-color: #ce7168;
  }
  .scene-button-member:hover::after {
    border-left-color: #ce7168;
  }
}
@media only screen and (any-hover: hover) and (max-width: 767px) {
  .scene-button-member:hover::after {
    border-top-color: #ce7168;
    border-left-color: transparent;
  }
}
.scene-button-member:active {
  color: #fff;
  background-color: #ce7168;
  border-left-color: #ce7168;
}
.scene-button-member:active::after {
  border-left-color: #ce7168;
}
@media only screen and (max-width: 767px) {
  .scene-button-member:active::after {
    border-top-color: #ce7168;
    border-left-color: transparent;
  }
}
.scene-button-member.is-active {
  color: #fff;
  background-color: #ce7168;
  border-left-color: #ce7168;
}
.scene-button-member.is-active::after {
  border-left-color: #ce7168;
}
@media only screen and (max-width: 767px) {
  .scene-button-member.is-active::after {
    border-top-color: #ce7168;
    border-left-color: transparent;
  }
}
.scene-button-reservation {
  color: #4d98aa;
}
@media (any-hover: hover) {
  .scene-button-reservation:hover {
    color: #fff;
    background-color: #4d98aa;
    border-left-color: #4d98aa;
  }
  .scene-button-reservation:hover::after {
    border-left-color: #4d98aa;
  }
}
@media only screen and (any-hover: hover) and (max-width: 767px) {
  .scene-button-reservation:hover::after {
    border-top-color: #4d98aa;
    border-left-color: transparent;
  }
}
.scene-button-reservation:active {
  color: #fff;
  background-color: #4d98aa;
  border-left-color: #4d98aa;
}
.scene-button-reservation:active::after {
  border-left-color: #4d98aa;
}
@media only screen and (max-width: 767px) {
  .scene-button-reservation:active::after {
    border-top-color: #4d98aa;
    border-left-color: transparent;
  }
}
.scene-button-reservation.is-active {
  color: #fff;
  background-color: #4d98aa;
  border-left-color: #4d98aa;
}
.scene-button-reservation.is-active::after {
  border-left-color: #4d98aa;
}
@media only screen and (max-width: 767px) {
  .scene-button-reservation.is-active::after {
    border-top-color: #4d98aa;
    border-left-color: transparent;
  }
}
.scene-button-product, .scene-button-information, .scene-button-member, .scene-button-reservation {
  border-left-color: currentColor;
}
.scene-button-product .scene-label, .scene-button-information .scene-label, .scene-button-member .scene-label, .scene-button-reservation .scene-label {
  color: currentColor;
}
.scene-icon {
  display: block;
  width: 25px;
  height: 25px;
}
@media only screen and (max-width: 1659px) {
  .scene-icon {
    width: 1.5060240964vw;
    height: 1.5060240964vw;
  }
}
@media only screen and (max-width: 767px) {
  .scene-icon {
    width: 25px;
    height: 25px;
  }
}
.scene-label {
  min-width: 80px;
  font-size: 20px;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .scene-label {
    min-width: 4.8192771084vw;
    font-size: 1.2048192771vw;
  }
}
@media only screen and (max-width: 767px) {
  .scene-label {
    min-width: auto;
    font-size: 13px;
  }
}
.scene-iframe-wrapper {
  position: relative;
  z-index: 1;
  width: 300px;
  height: 568px;
  background: url(../images/top/scene-phone.webp) no-repeat center;
  background-size: contain;
}
@media only screen and (max-width: 1659px) {
  .scene-iframe-wrapper {
    width: 18.0722891566vw;
    height: 34.156626506vw;
  }
}
@media only screen and (max-width: 767px) {
  .scene-iframe-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: 568px;
    margin-block-start: 25px;
  }
}
.scene-iframe {
  position: absolute;
  top: 41px;
  left: 50%;
  transform: translateX(-50%);
  width: 290px;
  height: 487px;
}
@media only screen and (max-width: 1659px) {
  .scene-iframe {
    top: 2.4096385542vw;
    width: 17.4698795181vw;
    height: 29.3373493976vw;
  }
}
@media only screen and (max-width: 767px) {
  .scene-iframe {
    top: 41px;
    width: 290px;
    height: 487px;
  }
}

.service-heading {
  position: relative;
  display: inline-block;
  font-size: 90px;
  font-weight: bold;
  line-height: 1.21;
}
@media only screen and (max-width: 1659px) {
  .service-heading {
    font-size: 5.421686747vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-heading {
    font-size: 45px;
    line-height: 1.45;
  }
}
.service-heading::before, .service-heading::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 160px;
  height: 160px;
  background-size: 160px 160px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1659px) {
  .service-heading::before, .service-heading::after {
    width: 9.6385542169vw;
    height: 9.6385542169vw;
    background-size: 9.6385542169vw 9.6385542169vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-heading::before, .service-heading::after {
    display: none;
  }
}
.service-heading::before {
  left: -252px;
  background-image: url(../images/svg/icon-efo.svg);
}
@media only screen and (max-width: 1659px) {
  .service-heading::before {
    left: -15.1807228916vw;
  }
}
.service-heading::after {
  right: -252px;
  background-image: url(../images/svg/icon-com.svg);
}
@media only screen and (max-width: 1659px) {
  .service-heading::after {
    right: -15.1807228916vw;
  }
}
.service-heading span {
  display: block;
  font-size: 45px;
  font-weight: normal;
  line-height: 1.45;
}
@media only screen and (max-width: 1659px) {
  .service-heading span {
    font-size: 2.7108433735vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-heading span {
    font-size: 20px;
  }
}
.service-heading small {
  line-height: 1.45;
}
.service-heading small:first-of-type {
  font-size: 80px;
}
@media only screen and (max-width: 1659px) {
  .service-heading small:first-of-type {
    font-size: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-heading small:first-of-type {
    font-size: 35px;
  }
}
.service-heading small:last-of-type {
  font-size: 45px;
}
@media only screen and (max-width: 1659px) {
  .service-heading small:last-of-type {
    font-size: 2.7108433735vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-heading small:last-of-type {
    font-size: 20px;
  }
}

.service {
  padding-inline: 50px;
}
@media only screen and (max-width: 1659px) {
  .service {
    padding-inline: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .service {
    padding-inline: 5px;
  }
}
.service-wrapper {
  padding-block: 101px;
  margin-block-start: -40px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .service-wrapper {
    padding-block: 6.0843373494vw;
    margin-block-start: -2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-wrapper {
    padding-block: 48px 50px;
    margin-block-start: -17px;
  }
}
@media only screen and (max-width: 767px) {
  .service-container {
    padding-inline: 35px;
  }
}
.service-title {
  font-family: din-2014, sans-serif;
  font-size: 75px;
  font-weight: bold;
  line-height: 1.28;
  color: currentColor;
}
@media only screen and (max-width: 1659px) {
  .service-title {
    font-size: 4.5180722892vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-title {
    font-size: 38px;
    line-height: 1.09;
  }
}
.service-desc {
  font-size: 18px;
  line-height: 2;
  color: #222;
  margin-block-start: 41px;
}
@media only screen and (max-width: 1659px) {
  .service-desc {
    font-size: 1.0843373494vw;
    margin-block-start: 2.4698795181vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-desc {
    font-size: 13px;
    text-align: left;
    margin-block-start: 23px;
  }
}
.service-list-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 80px;
  margin-block-start: 66px;
}
@media only screen and (max-width: 1659px) {
  .service-list-wrapper {
    column-gap: 4.8192771084vw;
    margin-block-start: 3.9759036145vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-list-wrapper {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 75px;
    column-gap: 0;
    margin-block-start: 29px;
  }
}
.service-list-wrapper::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0;
  height: 0;
  border-top: 80px solid transparent;
  border-bottom: 80px solid transparent;
  border-left: 30px solid currentColor;
}
@media only screen and (max-width: 1659px) {
  .service-list-wrapper::before {
    border-top-width: 4.8192771084vw;
    border-bottom-width: 4.8192771084vw;
    border-left-width: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-list-wrapper::before {
    display: none;
  }
}
.service-list {
  position: relative;
}
@media only screen and (max-width: 767px) {
  .service-list:first-of-type::after {
    content: "";
    position: absolute;
    bottom: -65px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    border-top: 25px solid currentColor;
    border-bottom: none;
    border-right: 90px solid transparent;
    border-left: 90px solid transparent;
  }
}
.service-list-left .service-list-item {
  color: #222;
  border-left-color: #434343;
}
.service-list-item {
  padding: 29px 20px 25px;
  background-color: #fff;
  border-left: 3px solid currentColor;
  font-size: 18px;
  line-height: 1.45;
  text-align: left;
}
@media only screen and (max-width: 1659px) {
  .service-list-item {
    padding: 1.7469879518vw 1.2048192771vw 1.5060240964vw;
    border-left-width: 0.1807228916vw;
    font-size: 1.0843373494vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-list-item {
    padding: 16px 15px 10px;
    border-left-width: 2px;
    font-size: 13px;
  }
}
.service-list-item:not(:first-child) {
  margin-block-start: 10px;
}
@media only screen and (max-width: 1659px) {
  .service-list-item:not(:first-child) {
    margin-block-start: 0.6024096386vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-list-item:not(:first-child) {
    margin-block-start: 5px;
  }
}
.service-list-tag {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -80px;
  right: -50px;
  z-index: 1;
  width: 180px;
  height: 180px;
  border-radius: 50%;
  background-color: currentColor;
}
@media only screen and (max-width: 1659px) {
  .service-list-tag {
    top: -4.8192771084vw;
    right: -3.0120481928vw;
    width: 10.843373494vw;
    height: 10.843373494vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-list-tag {
    top: auto;
    bottom: 92px;
    right: -20px;
    width: 90px;
    height: 90px;
  }
}
.service-list-tag::before {
  content: "";
  pointer-events: none;
  position: absolute;
  top: 47px;
  left: 50%;
  transform: translateX(-50%);
  width: 156px;
  height: 111px;
  background-image: url(../images/svg/icon-check.svg);
  background-size: 156px 111px;
  background-position: center;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 1659px) {
  .service-list-tag::before {
    top: 2.8313253012vw;
    width: 9.3975903614vw;
    height: 6.686746988vw;
    background-size: 9.3975903614vw 6.686746988vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-list-tag::before {
    top: 22px;
    width: 78px;
    height: 56px;
    background-size: 78px 56px;
  }
}
.service-list-tag-text {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.45;
  color: #fff;
  padding-block-start: 15px;
}
@media only screen and (max-width: 1659px) {
  .service-list-tag-text {
    font-size: 1.8072289157vw;
    padding-block-start: 0.9036144578vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-list-tag-text {
    font-size: 15px;
    padding-block-start: 5px;
  }
}
.service-list-tag-text span {
  display: block;
  font-size: 49px;
  line-height: 1.21;
}
@media only screen and (max-width: 1659px) {
  .service-list-tag-text span {
    font-size: 2.9518072289vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-list-tag-text span {
    font-size: 25px;
  }
}
.service-list-tag-text small {
  font-size: 22px;
}
@media only screen and (max-width: 1659px) {
  .service-list-tag-text small {
    font-size: 1.3253012048vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-list-tag-text small {
    font-size: 11px;
  }
}
.service-button {
  margin-block-start: 81px;
}
@media only screen and (max-width: 1659px) {
  .service-button {
    margin-block-start: 4.8795180723vw;
  }
}
@media only screen and (max-width: 767px) {
  .service-button {
    margin-block-start: 40px;
  }
}

.support-title {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .support-title {
    font-size: 2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .support-title {
    font-size: 25px;
    line-height: 1.6;
  }
}
.support-content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 65px;
  margin-block-start: 67px;
}
@media only screen and (max-width: 1659px) {
  .support-content {
    column-gap: 3.9156626506vw;
    margin-block-start: 4.0361445783vw;
  }
}
@media only screen and (max-width: 767px) {
  .support-content {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 33px;
    column-gap: 0;
    margin-block-start: 28px;
  }
}
.support-card:first-of-type .support-icon {
  width: 100px;
  height: 76px;
  background-size: 100px 76px;
}
@media only screen and (max-width: 1659px) {
  .support-card:first-of-type .support-icon {
    width: 6.0240963855vw;
    height: 4.578313253vw;
    background-size: 6.0240963855vw 4.578313253vw;
  }
}
@media only screen and (max-width: 767px) {
  .support-card:first-of-type .support-icon {
    width: 55px;
    height: 42px;
    background-size: 55px 42px;
  }
}
.support-card:last-of-type .support-icon {
  width: 86px;
  height: 100px;
  background-size: 86px 100px;
}
@media only screen and (max-width: 1659px) {
  .support-card:last-of-type .support-icon {
    width: 5.1807228916vw;
    height: 6.0240963855vw;
    background-size: 5.1807228916vw 6.0240963855vw;
  }
}
@media only screen and (max-width: 767px) {
  .support-card:last-of-type .support-icon {
    width: 45px;
    height: 52px;
    background-size: 45px 52px;
  }
}
.support-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 30px;
}
@media only screen and (max-width: 1659px) {
  .support-info {
    column-gap: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .support-info {
    column-gap: 15px;
  }
}
.support-icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  background-color: #f6f6f6;
}
@media only screen and (max-width: 1659px) {
  .support-icon-wrapper {
    width: 8.4337349398vw;
    height: 8.4337349398vw;
  }
}
@media only screen and (max-width: 767px) {
  .support-icon-wrapper {
    width: 80px;
    height: 80px;
  }
}
.support-icon {
  background-position: center;
  background-repeat: no-repeat;
}
.support-subtitle {
  font-size: 30px;
  font-weight: bold;
  line-height: 1.3;
  text-align: left;
}
@media only screen and (max-width: 1659px) {
  .support-subtitle {
    font-size: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .support-subtitle {
    font-size: 20px;
    line-height: 1.6;
  }
}
.support-desc {
  font-size: 18px;
  line-height: 2;
  margin-block-start: 48px;
}
@media only screen and (max-width: 1659px) {
  .support-desc {
    font-size: 1.0843373494vw;
    margin-block-start: 2.8915662651vw;
  }
}
@media only screen and (max-width: 767px) {
  .support-desc {
    font-size: 13px;
    margin-block-start: 24px;
  }
}

.top-mv-inner {
  position: relative;
  padding-block: 128px 182px;
  padding-inline-start: 300px;
}
@media only screen and (max-width: 1659px) {
  .top-mv-inner {
    padding-block: 7.7108433735vw 10.9638554217vw;
    padding-inline-start: 18.0722891566vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-mv-inner {
    width: 375px;
    margin: 0 auto;
    padding: 27px 40px 297px;
  }
}
@media only screen and (max-width: 374px) {
  .top-mv-inner {
    padding: 27px 16px 297px;
  }
}
.top-mv-title {
  opacity: 0;
  margin: 0;
  font-family: din-2014, sans-serif;
  font-size: 142px;
  font-weight: bold;
  line-height: 1.28;
  color: #21aa92;
}
@media only screen and (max-width: 1659px) {
  .top-mv-title {
    font-size: 8.5542168675vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-mv-title {
    font-size: 67px;
  }
}
@media only screen and (max-width: 374px) {
  .top-mv-title {
    font-size: 60px;
  }
}
.top-mv-title span,
.top-mv-title sub {
  font-family: ryo-gothic-plusn, sans-serif;
}
.top-mv-title span {
  display: block;
  font-size: 118px;
}
@media only screen and (max-width: 1659px) {
  .top-mv-title span {
    font-size: 7.1084337349vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-mv-title span {
    font-size: 56px;
  }
}
@media only screen and (max-width: 374px) {
  .top-mv-title span {
    font-size: 50px;
  }
}
.top-mv-title sub {
  font-size: 68px;
  color: #222;
}
@media only screen and (max-width: 1659px) {
  .top-mv-title sub {
    font-size: 4.0963855422vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-mv-title sub {
    font-size: 32px;
  }
}
.top-mv-desc {
  opacity: 0;
  font-size: 35px;
  line-height: 1.6;
  margin-block-start: 34px;
  font-weight: 500;
}
@media only screen and (max-width: 1659px) {
  .top-mv-desc {
    font-size: 2.1084337349vw;
    margin-block-start: 2.0481927711vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-mv-desc {
    font-size: 14px;
    margin-block-start: 6px;
  }
}
.top-mv-image {
  opacity: 0;
  position: absolute;
  top: 74px;
  right: -120px;
  width: 895px;
}
@media only screen and (max-width: 1659px) {
  .top-mv-image {
    top: 4.4578313253vw;
    right: -7.2289156627vw;
    width: 53.9156626506vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-mv-image {
    top: auto;
    bottom: -156px;
    right: -67px;
    width: 380px;
  }
}

.top-why {
  background-color: #f5f5f5;
}
.top-why-inner {
  padding: 188px 50px 22px;
}
@media only screen and (max-width: 1659px) {
  .top-why-inner {
    padding: 11.3253012048vw 3.0120481928vw 1.3253012048vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-inner {
    padding: 94px 0 0;
  }
}
.top-why-content {
  max-width: 100%;
  margin-inline-start: 251px;
}
@media only screen and (max-width: 1659px) {
  .top-why-content {
    margin-inline-start: 15.1204819277vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-content {
    width: 100%;
    margin-inline-start: 0;
    padding-inline: 40px;
  }
}
.top-why-title {
  font-size: 100px;
  font-weight: bold;
  line-height: 1.45;
  color: #21aa92;
}
@media only screen and (max-width: 1659px) {
  .top-why-title {
    font-size: 6.0240963855vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-title {
    font-size: 50px;
  }
}
.top-why-title span {
  display: block;
  font-size: 55px;
  font-weight: normal;
  line-height: 1.49;
  color: #222;
  font-weight: 500;
}
@media only screen and (max-width: 1659px) {
  .top-why-title span {
    font-size: 3.313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-title span {
    font-size: 20px;
    line-height: 1.6;
  }
}
.top-why-desc {
  font-size: 18px;
  line-height: 2;
  margin-block-start: 31px;
}
@media only screen and (max-width: 1659px) {
  .top-why-desc {
    font-size: 1.0843373494vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-desc {
    font-size: 13px;
    margin-block-start: 32px;
  }
}
.top-why-image {
  width: 100%;
  margin-block-start: 73px;
}
@media only screen and (max-width: 1659px) {
  .top-why-image {
    margin-block-start: 4.3975903614vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-image {
    width: 100%;
    margin: 19px auto 0;
    padding-inline-start: 5px;
    overflow-x: auto;
  }
}
.top-why-image-inner {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .top-why-image-inner {
    width: 575px;
    margin: 0 auto;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-image-note {
    width: 548px;
    font-size: 9px;
    line-height: 1.45;
    text-align: left;
    background-color: #fff;
    padding: 12px 15px;
    margin-block-start: -1px;
  }
  .top-why-image-note::after {
    content: "";
    display: inline-block;
    vertical-align: top;
    width: 190px;
    height: 8px;
    background: url(../images/svg/icon-button3.svg) no-repeat center;
    background-size: 190px 8px;
    margin-inline-start: 10px;
  }
}
.top-why-slogan {
  margin-block-start: 125px;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .top-why-slogan {
    margin-block-start: 7.5301204819vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-slogan {
    margin-block-start: 38px;
  }
}
.top-why-slogan-desc {
  font-size: 45px;
  line-height: 1.33;
  font-weight: 500;
}
@media only screen and (max-width: 1659px) {
  .top-why-slogan-desc {
    font-size: 2.7108433735vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-slogan-desc {
    font-size: 20px;
    line-height: 1.6;
  }
}
.top-why-slogan-icon {
  width: 150px;
  height: 138px;
  background-size: 150px 138px;
  background-position: center;
  background-repeat: no-repeat;
  margin: 25px auto 0;
}
@media only screen and (max-width: 1659px) {
  .top-why-slogan-icon {
    width: 9.0361445783vw;
    height: 8.313253012vw;
    background-size: 9.0361445783vw 8.313253012vw;
    margin-block-start: 1.5060240964vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-slogan-icon {
    width: 60px;
    height: 55px;
    background-size: 60px 55px;
    margin-block-start: 8px;
  }
}
.top-why-banner {
  position: relative;
  background-color: #21aa92;
}
.top-why-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-top: 40px solid #f5f5f5;
  border-left: 280px solid transparent;
  border-right: 280px solid transparent;
}
@media only screen and (max-width: 1659px) {
  .top-why-banner::before {
    border-top-width: 2.4096385542vw;
    border-left-width: 16.8674698795vw;
    border-right-width: 16.8674698795vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-banner::before {
    border-top-width: 25px;
    border-left-width: 133px;
    border-right-width: 133px;
  }
}
.top-why-banner-inner {
  padding-block: 81px 62px;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .top-why-banner-inner {
    padding-block: 4.8795180723vw 3.734939759vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-banner-inner {
    padding-block: 48px 29px;
  }
}
.top-why-banner-title {
  font-size: 80px;
  font-weight: bold;
  line-height: 1.45;
  color: #fff;
}
@media only screen and (max-width: 1659px) {
  .top-why-banner-title {
    font-size: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-banner-title {
    font-size: 31px;
  }
}
.top-why-banner-title span {
  font-size: 70px;
}
@media only screen and (max-width: 1659px) {
  .top-why-banner-title span {
    font-size: 4.2168674699vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-banner-title span {
    font-size: 27px;
  }
}
.top-why-banner-title small {
  display: block;
  font-size: 31px;
}
@media only screen and (max-width: 1659px) {
  .top-why-banner-title small {
    font-size: 1.8674698795vw;
  }
}
@media only screen and (max-width: 767px) {
  .top-why-banner-title small {
    font-size: 16px;
  }
}

.topics-title {
  font-size: 40px;
  font-weight: bold;
  line-height: 1.45;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .topics-title {
    font-size: 2.4096385542vw;
  }
}
@media only screen and (max-width: 767px) {
  .topics-title {
    font-size: 25px;
  }
}
.topics-list {
  margin-block-start: 67px;
}
@media only screen and (max-width: 1659px) {
  .topics-list {
    margin-block-start: 4.0361445783vw;
  }
}
@media only screen and (max-width: 767px) {
  .topics-list {
    margin-block-start: 32px;
  }
}
.topics-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 49px;
  width: 100%;
  padding: 30px 50px 27px;
}
@media only screen and (max-width: 1659px) {
  .topics-item {
    column-gap: 2.9518072289vw;
    padding: 1.8072289157vw 3.0120481928vw 1.6265060241vw;
  }
}
@media only screen and (max-width: 767px) {
  .topics-item {
    display: block;
    padding: 25px 25px 18px;
  }
}
.topics-item:nth-child(odd) {
  background-color: #f6f6f6;
}
.topics-label {
  font-family: din-2014, sans-serif;
  line-height: 1.28;
}
@media only screen and (max-width: 1659px) {
  .topics-label {
    font-size: 0.9638554217vw;
  }
}
@media only screen and (max-width: 767px) {
  .topics-label {
    font-size: 11px;
    line-height: 1.45;
  }
}
.topics-desc {
  line-height: 1.45;
}
.topics-desc a {
  color: #222;
  border-bottom: 1px solid currentColor;
  transition: all 0.25s ease;
}
@media (any-hover: hover) {
  .topics-desc a:hover {
    color: #21aa92;
    border-bottom: 1px solid #21aa92;
  }
}
.topics-desc a:active {
  color: #21aa92;
  border-bottom: 1px solid #21aa92;
}
@media only screen and (max-width: 1659px) {
  .topics-desc {
    font-size: 0.9638554217vw;
  }
}
@media only screen and (max-width: 767px) {
  .topics-desc {
    font-size: 13px;
    line-height: 1.62;
    margin-block-start: 5px;
  }
}

.why-banner {
  position: relative;
  background-color: #6cb53c;
}
.why-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-top: 51px solid #f5f5f5;
  border-left: 350px solid transparent;
  border-right: 350px solid transparent;
}
@media only screen and (max-width: 1659px) {
  .why-banner::before {
    border-top-width: 3.0722891566vw;
    border-left-width: 21.0843373494vw;
    border-right-width: 21.0843373494vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner::before {
    border-top-width: 26px;
    border-left-width: 133px;
    border-right-width: 133px;
  }
}
.why-banner-inner {
  padding: 68px 50px 50px;
  text-align: center;
}
@media only screen and (max-width: 1659px) {
  .why-banner-inner {
    padding: 4.0963855422vw 3.0120481928vw 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-inner {
    padding: 38px 5px 5px;
  }
}
.why-banner-title {
  font-size: 55px;
  font-weight: normal;
  line-height: 1.45;
  color: #fff;
}
@media only screen and (max-width: 1659px) {
  .why-banner-title {
    font-size: 3.313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-title {
    font-size: 30px;
  }
}
.why-banner-title span {
  font-size: 75px;
  font-weight: bold;
}
@media only screen and (max-width: 1659px) {
  .why-banner-title span {
    font-size: 4.5180722892vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-title span {
    font-size: 41px;
  }
}
.why-banner-title small {
  font-size: 35px;
  font-weight: bold;
}
@media only screen and (max-width: 1659px) {
  .why-banner-title small {
    font-size: 2.1084337349vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-title small {
    font-size: 18px;
  }
}
.why-banner-graph {
  display: grid;
  grid-template-columns: 365px 620px;
  justify-content: center;
  align-items: center;
  column-gap: 15px;
  margin-block-start: 5px;
  padding: 50px;
  background-color: #fff;
}
@media only screen and (max-width: 1659px) {
  .why-banner-graph {
    grid-template-columns: 21.9879518072vw 37.3493975904vw;
    column-gap: 0.9036144578vw;
    margin-block-start: 0.3012048193vw;
    padding: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-graph {
    grid-template-columns: repeat(1, 1fr);
    column-gap: 0;
    row-gap: 13px;
    margin-block-start: 8px;
    padding: 33px 35px 40px;
  }
}
.why-banner-graph-desc {
  font-size: 33px;
  line-height: 1.2;
  text-align: left;
}
@media only screen and (max-width: 1659px) {
  .why-banner-graph-desc {
    font-size: 1.9879518072vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-graph-desc {
    font-size: 17px;
    text-align: center;
  }
}
.why-banner-graph-desc-main, .why-banner-graph-desc-subheading, .why-banner-graph-desc-text {
  display: block;
}
.why-banner-graph-desc-lg {
  font-size: 76px;
  font-weight: bold;
}
@media only screen and (max-width: 1659px) {
  .why-banner-graph-desc-lg {
    font-size: 4.578313253vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-graph-desc-lg {
    font-size: 38px;
  }
}
.why-banner-graph-desc-color {
  color: #6cb53c;
}
.why-banner-graph-desc-text {
  margin-block-start: 18px;
}
@media only screen and (max-width: 1659px) {
  .why-banner-graph-desc-text {
    margin-block-start: 1.0843373494vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-graph-desc-text {
    margin-block-start: 5px;
  }
}
.why-banner-efo {
  background-color: #29a6d5;
}
.why-banner-efo .why-banner-inner {
  padding-block-end: 80px;
}
@media only screen and (max-width: 1659px) {
  .why-banner-efo .why-banner-inner {
    padding-block-end: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-efo .why-banner-inner {
    padding-block-end: 5px;
  }
}
.why-banner-efo .why-banner-graph {
  grid-template-columns: 334px 650px;
}
@media only screen and (max-width: 1659px) {
  .why-banner-efo .why-banner-graph {
    grid-template-columns: 20.1204819277vw 39.156626506vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-efo .why-banner-graph {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 7px;
    padding: 40px 5px 43px;
  }
}
.why-banner-efo .why-banner-graph-desc {
  font-weight: bold;
  line-height: 1.45;
}
.why-banner-efo .why-banner-graph-desc-main {
  display: block;
  font-size: 20px;
  font-weight: normal;
}
@media only screen and (max-width: 1659px) {
  .why-banner-efo .why-banner-graph-desc-main {
    font-size: 1.2048192771vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-efo .why-banner-graph-desc-main {
    font-size: 15px;
  }
}
.why-banner-efo .why-banner-graph-desc-sub {
  display: block;
  font-size: 35px;
  margin-block-start: 5px;
}
@media only screen and (max-width: 1659px) {
  .why-banner-efo .why-banner-graph-desc-sub {
    font-size: 2.1084337349vw;
    margin-block-start: 0.3012048193vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-efo .why-banner-graph-desc-sub {
    font-size: 26px;
    margin-block-start: 3px;
  }
}
.why-banner-efo .why-banner-graph-desc-sub small {
  font-size: 30px;
}
@media only screen and (max-width: 1659px) {
  .why-banner-efo .why-banner-graph-desc-sub small {
    font-size: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-efo .why-banner-graph-desc-sub small {
    font-size: 26px;
  }
}
.why-banner-efo .why-banner-graph-desc-subheading {
  display: block;
  font-size: 50px;
  margin-block-start: -12px;
}
@media only screen and (max-width: 1659px) {
  .why-banner-efo .why-banner-graph-desc-subheading {
    font-size: 3.0120481928vw;
    margin-block-start: -0.7228915663vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-efo .why-banner-graph-desc-subheading {
    display: inline-block;
    font-size: 28px;
    margin-block-start: -17px;
  }
}
.why-banner-efo .why-banner-graph-desc-subheading-lg {
  font-size: 87px;
  color: #29a6d5;
}
@media only screen and (max-width: 1659px) {
  .why-banner-efo .why-banner-graph-desc-subheading-lg {
    font-size: 5.2409638554vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-efo .why-banner-graph-desc-subheading-lg {
    font-size: 50px;
  }
}
.why-banner-efo .why-banner-graph-desc-subheading-lg small {
  font-size: 50px;
}
@media only screen and (max-width: 1659px) {
  .why-banner-efo .why-banner-graph-desc-subheading-lg small {
    font-size: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-efo .why-banner-graph-desc-subheading-lg small {
    font-size: 28px;
  }
}
.why-banner-efo .why-banner-graph-desc-text {
  display: block;
  font-size: 50px;
  text-align: center;
  margin-block-start: -25px;
}
@media only screen and (max-width: 1659px) {
  .why-banner-efo .why-banner-graph-desc-text {
    font-size: 3.0120481928vw;
    margin-block-start: -1.5060240964vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-banner-efo .why-banner-graph-desc-text {
    display: inline-block;
    font-size: 28px;
    margin-block-start: 0;
  }
}

.why-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  column-gap: 50px;
}
@media only screen and (max-width: 1659px) {
  .why-card {
    column-gap: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-card {
    flex-direction: column-reverse;
  }
}
.why-card-content {
  flex-basis: 620px;
  width: 620px;
}
@media only screen and (max-width: 1659px) {
  .why-card-content {
    flex-basis: 37.3493975904vw;
    width: 37.3493975904vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-content {
    flex-basis: 100%;
    width: 100%;
  }
}
.why-card-title {
  position: relative;
  padding-inline-start: 27px;
  font-size: 30px;
  font-weight: bold;
  line-height: 1.67;
}
@media only screen and (max-width: 1659px) {
  .why-card-title {
    padding-inline-start: 1.6265060241vw;
    font-size: 1.8072289157vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-title {
    flex-basis: 149px;
    width: 149px;
    padding-inline-start: 0;
    font-size: 18px;
    line-height: 1.61;
  }
}
.why-card-title::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 3px;
  height: 80%;
  background-color: #6cb53c;
}
@media only screen and (max-width: 1659px) {
  .why-card-title::before {
    width: 0.1807228916vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-title::before {
    display: none;
  }
}
.why-card-desc {
  font-size: 18px;
  line-height: 2;
  margin-block-start: 27px;
}
@media only screen and (max-width: 1659px) {
  .why-card-desc {
    font-size: 1.0843373494vw;
    margin-block-start: 1.6265060241vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-desc {
    font-size: 12px;
    margin-block-start: 35px;
  }
}
.why-card-image-wrapper {
  flex-basis: 330px;
  width: 330px;
}
@media only screen and (max-width: 1659px) {
  .why-card-image-wrapper {
    flex-basis: 19.8795180723vw;
    width: 19.8795180723vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-image-wrapper {
    flex-basis: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 25px;
  }
}
.why-card-thumb {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 350px;
  background-color: #fff;
}
@media only screen and (max-width: 1659px) {
  .why-card-thumb {
    height: 21.0843373494vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-thumb {
    flex-basis: 110px;
    width: 110px;
    height: auto;
    background-color: transparent;
  }
}
.why-card-image {
  width: 180px;
  margin-block-end: 20px;
}
@media only screen and (max-width: 1659px) {
  .why-card-image {
    width: 10.843373494vw;
    margin-block-end: 1.2048192771vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-image {
    width: 110px;
    margin-block-end: 0;
  }
}
.why-card-image-lg {
  width: 230px;
  margin-block-end: 32px;
}
@media only screen and (max-width: 1659px) {
  .why-card-image-lg {
    width: 13.8554216867vw;
    margin-block-end: 1.9277108434vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-image-lg {
    width: 110px;
    margin-block-end: 0;
  }
}
.why-card-reverse {
  flex-direction: row-reverse;
}
@media only screen and (max-width: 767px) {
  .why-card-reverse {
    flex-direction: column-reverse;
  }
}
.why-card-reverse .why-card-image-wrapper {
  flex-direction: row-reverse;
}
.why-card-efo .why-card-content, .why-card-efo .why-card-image-wrapper {
  flex-basis: 475px;
  width: 475px;
}
@media only screen and (max-width: 1659px) {
  .why-card-efo .why-card-content, .why-card-efo .why-card-image-wrapper {
    flex-basis: 28.6144578313vw;
    width: 28.6144578313vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-efo .why-card-content, .why-card-efo .why-card-image-wrapper {
    flex-basis: 100%;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-efo .why-card-title {
    flex-basis: 100%;
    width: 100%;
    padding-inline-start: 14px;
    margin-block-start: 24px;
  }
}
.why-card-efo .why-card-title::before {
  background-color: #29a6d5;
}
@media only screen and (max-width: 767px) {
  .why-card-efo .why-card-title::before {
    display: block;
    width: 2px;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-efo .why-card-desc {
    font-size: 13px;
    margin-block-start: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .why-card-efo .why-card-image-wrapper {
    flex-direction: column;
  }
}
.why-card-efo .why-card-thumb {
  height: auto;
  background-color: transparent;
}
@media only screen and (max-width: 767px) {
  .why-card-efo .why-card-thumb {
    flex-basis: 100%;
    width: 100%;
  }
}
.why-card-efo .why-card-image {
  width: 100%;
  margin-block-end: 0;
}

.why {
  background-color: #f5f5f5;
}
.why-inner {
  padding-block: 130px 190px;
}
@media only screen and (max-width: 1659px) {
  .why-inner {
    padding-block: 7.8313253012vw 11.4457831325vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-inner {
    padding-block: 265px 33px;
  }
}
.why-title {
  font-weight: bold;
  line-height: 1.35;
}
.why-title-main {
  display: block;
  font-size: 100px;
  color: #6cb53c;
}
@media only screen and (max-width: 1659px) {
  .why-title-main {
    font-size: 6.0240963855vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-title-main {
    font-size: 50px;
  }
}
.why-title-sub {
  display: block;
  font-size: 57px;
  font-weight: normal;
}
@media only screen and (max-width: 1659px) {
  .why-title-sub {
    font-size: 3.4337349398vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-title-sub {
    font-size: 23px;
  }
}
.why-title-sub small {
  font-size: 50px;
}
@media only screen and (max-width: 1659px) {
  .why-title-sub small {
    font-size: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-title-sub small {
    font-size: 20px;
  }
}
.why-title-subheading {
  display: block;
}
.why-title-subheading-en {
  font-size: 80px;
}
@media only screen and (max-width: 1659px) {
  .why-title-subheading-en {
    font-size: 4.8192771084vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-title-subheading-en {
    font-size: 32px;
  }
}
.why-title-subheading-jp {
  font-size: 65px;
}
@media only screen and (max-width: 1659px) {
  .why-title-subheading-jp {
    font-size: 3.9156626506vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-title-subheading-jp {
    font-size: 26px;
  }
}
.why-title-subheading small {
  font-size: 50px;
  font-weight: 500;
}
@media only screen and (max-width: 1659px) {
  .why-title-subheading small {
    font-size: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-title-subheading small {
    font-size: 26px;
  }
}
@media only screen and (max-width: 767px) {
  .why-title-subheading small:last-of-type {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .why-desc {
    font-size: 13px;
    line-height: 2;
    margin-block-start: 30px;
  }
}
.why-list {
  margin-block-start: 50px;
}
@media only screen and (max-width: 1659px) {
  .why-list {
    margin-block-start: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-list {
    margin-block-start: 30px;
  }
}
.why-item:not(:first-child) {
  margin-block-start: 50px;
}
@media only screen and (max-width: 1659px) {
  .why-item:not(:first-child) {
    margin-block-start: 3.0120481928vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-item:not(:first-child) {
    margin-block-start: 33px;
  }
}
.why-efo .why-inner {
  padding-block-end: 130px;
}
@media only screen and (max-width: 1659px) {
  .why-efo .why-inner {
    padding-block-end: 7.8313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-efo .why-inner {
    padding-block-end: 56px;
  }
}
.why-efo .why-title-main {
  color: #29a6d5;
}
.why-efo .why-title-sub {
  font-size: 62px;
}
@media only screen and (max-width: 1659px) {
  .why-efo .why-title-sub {
    font-size: 3.734939759vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-efo .why-title-sub {
    font-size: 23px;
  }
}
.why-efo .why-title-sub small {
  font-size: 55px;
}
@media only screen and (max-width: 1659px) {
  .why-efo .why-title-sub small {
    font-size: 3.313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-efo .why-title-sub small {
    font-size: 20px;
  }
}
.why-efo .why-title-subheading small {
  font-size: 51px;
}
@media only screen and (max-width: 1659px) {
  .why-efo .why-title-subheading small {
    font-size: 3.0722891566vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-efo .why-title-subheading small {
    font-size: 26px;
  }
}
.why-efo .why-title-subheading small:last-of-type {
  font-size: 55px;
}
@media only screen and (max-width: 1659px) {
  .why-efo .why-title-subheading small:last-of-type {
    font-size: 3.313253012vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-efo .why-title-subheading small:last-of-type {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .why-efo .why-desc {
    margin-block-start: 25px;
  }
}
.why-efo .why-item:not(:first-child) {
  margin-block-start: 70px;
}
@media only screen and (max-width: 1659px) {
  .why-efo .why-item:not(:first-child) {
    margin-block-start: 4.2168674699vw;
  }
}
@media only screen and (max-width: 767px) {
  .why-efo .why-item:not(:first-child) {
    margin-block-start: 27px;
  }
}