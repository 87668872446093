.mini-card {
  display: block;
  height: 100%;
  padding: 50px 50px 34px;
  background-color: $gray-two;

  @include max-screen($breakpoint-bestview) {
    padding: px-to-vw(50) px-to-vw(50) px-to-vw(34);
  }

  @include max-screen($breakpoint-mobile) {
    padding: 25px 25px 17px;
  }

  &-info {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 15px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(15);
    }

    @include max-screen($breakpoint-mobile) {
      column-gap: 8px;
    }
  }

  &-icon {
    width: 55px;
    height: 50px;
    background-size: 55px 50px;
    background-position: center;
    background-repeat: no-repeat;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(55);
      height: px-to-vw(50);
      background-size: px-to-vw(55) px-to-vw(50);
    }

    @include max-screen($breakpoint-mobile) {
      width: 28px;
      height: 25px;
      background-size: 28px 25px;
    }
  }

  &-title {
    font-size: 33px;
    font-weight: bold;
    line-height: 1.45;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(33);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 20px;
    }
  }

  &-desc {
    font-size: 18px;
    line-height: 2;
    margin-block-start: 38px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
      margin-block-start: px-to-vw(38);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      margin-block-start: 14px;
    }
  }
}
