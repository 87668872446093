.u-show-pc {
  @include max-screen($breakpoint-mobile) {
    // sass-lint:disable-block no-important
    display: none !important;
  }
}

.u-show-sp {
  // sass-lint:disable-block no-important
  display: none !important;

  @include max-screen($breakpoint-mobile) {
    display: block !important;
  }

  &.u-show-sp-flex {
    @include max-screen($breakpoint-mobile) {
      display: flex !important;
    }
  }
}

.u-hide {
  display: none;
}

@function px-to-vw($px, $breakpoint: 1660, $minusPx: 0) {
  @if $minusPx {
    @return ($px - $minusPx) / $breakpoint * 100 + vw;
  } @else {
    @return $px / $breakpoint * 100 + vw;
  }
}
