.graph {
  width: 100%;
  padding: 38px 50px 70px;
  border: 1px solid $gray-five;

  @include max-screen($breakpoint-bestview) {
    padding: px-to-vw(38) px-to-vw(50) px-to-vw(70);
  }

  @include max-screen($breakpoint-mobile) {
    padding: 20px 30px 0;
    border: none;
    border-top: 1px solid $gray-five;
  }

  &-title {
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    line-height: 1.45;
    padding-block-end: 10px;
    border-bottom: 1px solid currentColor;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(15);
      padding-block-end: px-to-vw(10);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      font-weight: normal;
      padding-block-end: 0;
    }
  }

  &-inner {
    position: relative;
    margin-block-start: 30px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(30);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 15px;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 150px;
      width: 2px;
      height: 100%;
      background-color: $gray-five;

      @include max-screen($breakpoint-bestview) {
        left: px-to-vw(150);
        width: px-to-vw(2);
      }

      @include max-screen($breakpoint-mobile) {
        left: 88px;
        width: 2px;
      }
    }

    &::after {
      content: "0%";
      position: absolute;
      bottom: -35px;
      left: 150px;
      font-size: 14px;

      @include max-screen($breakpoint-bestview) {
        bottom: px-to-vw(-35);
        left: px-to-vw(150);
        font-size: px-to-vw(14);
      }

      @include max-screen($breakpoint-mobile) {
        bottom: -18px;
        left: 88px;
        font-size: 7px;
      }
    }
  }

  &-wrapper {
    display: grid;
    grid-template-columns: 126px 400px;
    justify-content: flex-start;
    align-items: center;
    column-gap: 24px;

    @include max-screen($breakpoint-bestview) {
      grid-template-columns: px-to-vw(126) px-to-vw(400);
      column-gap: px-to-vw(24);
    }

    @include max-screen($breakpoint-mobile) {
      grid-template-columns: 75px calc(100% - 88px);
      column-gap: 13px;
    }

    &:not(:first-of-type) {
      margin-block-start: 20px;

      @include max-screen($breakpoint-bestview) {
        margin-block-start: px-to-vw(20);
      }

      @include max-screen($breakpoint-mobile) {
        margin-block-start: 11px;
      }
    }
  }

  &-label {
    display: block;
    font-size: 14px;
    font-weight: bold;
    text-align: left;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(14);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 8px;
    }

    img {
      display: block;
      width: 100%;
    }
  }

  &-bar {
    width: 100%;
    height: 50px;
    background-color: $gray;

    @include max-screen($breakpoint-bestview) {
      height: px-to-vw(50);
    }

    @include max-screen($breakpoint-mobile) {
      height: 27px;
    }
  }

  &-percent {
    width: 0;
    height: 100%;
    transition: width 0.15s linear;
  }
}
