.card {
  &-block {
    @include true-hover {
      .card-image {
        transform: scale(1.2) translate3d(0, 0, 0);
      }
    }
  }

  &-image-wrapper {
    border: 1px solid $black;
    overflow: hidden;

    @include max-screen($breakpoint-mobile) {
      border: none;
    }
  }

  &-image {
    display: block;
    width: 100%;
    padding-top: percentage(196 / 313);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    backface-visibility: hidden;
    transform: translate3d(0, 0, 0);
    transition: transform 0.5s $ease;

    @include max-screen($breakpoint-mobile) {
      padding-top: percentage(185 / 296);
    }
  }

  &-content {
    padding: 30px 43px 30px 30px;
    border: 1px solid $gray-three;
    color: $base-font-color;

    @include max-screen($breakpoint-bestview) {
      padding: px-to-vw(30) px-to-vw(43) px-to-vw(30) px-to-vw(30);
    }

    @include max-screen($breakpoint-mobile) {
      padding: 20px 24px;
    }
  }

  &-tag {
    display: inline-block;
    font-size: 11px;
    line-height: 1.45;
    color: $blue-two;
    padding: 3px 13px 3px 9px;
    border: 2px solid $blue-three;
    border-radius: 15px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(11);
      padding: px-to-vw(3) px-to-vw(13) px-to-vw(3) px-to-vw(9);
      border-width: px-to-vw(2);
      border-radius: 15px;
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 9px;
      padding: 3px 12px 3px 8px;
      border-width: 1px;
    }
  }

  &-title {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.56;
    margin-block-start: 11px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
      margin-block-start: px-to-vw(11);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      line-height: 1.61;
      margin-block-start: 14px;
    }
  }

  &-date {
    display: block;
    margin-block-start: 8px;
    font-family: $secondary-font-family;
    font-size: 13px;
    line-height: 1.28;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(8);
      font-size: px-to-vw(13);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 11px;
      margin-block-start: 9px;
    }
  }
}
