.top-mv {
  &-inner {
    position: relative;
    padding-block: 128px 182px;
    padding-inline-start: 300px;

    @include max-screen($breakpoint-bestview) {
      padding-block: px-to-vw(128) px-to-vw(182);
      padding-inline-start: px-to-vw(300);
    }

    @include max-screen($breakpoint-mobile) {
      width: 375px;
      margin: 0 auto;
      padding: 27px 40px 297px;
    }

    @include max-screen($breakpoint-mobile-small) {
      padding: 27px 16px 297px;
    }
  }

  &-title {
    opacity: 0;
    @include margin-reset;
    font-family: $secondary-font-family;
    font-size: 142px;
    font-weight: bold;
    line-height: 1.28;
    color: $top-theme-color;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(142);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 67px;
    }

    @include max-screen($breakpoint-mobile-small) {
      font-size: 60px;
    }

    span,
    sub {
      font-family: $base-font-family;
    }

    span {
      display: block;
      font-size: 118px;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(118);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 56px;
      }

      @include max-screen($breakpoint-mobile-small) {
        font-size: 50px;
      }
    }

    sub {
      font-size: 68px;
      color: $base-font-color;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(68);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 32px;
      }
    }
  }

  &-desc {
    opacity: 0;
    font-size: 35px;
    line-height: 1.6;
    margin-block-start: 34px;
    font-weight: 500;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(35);
      margin-block-start: px-to-vw(34);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 14px;
      margin-block-start: 6px;
    }
  }

  &-image {
    opacity: 0;
    position: absolute;
    top: 74px;
    right: -120px;
    width: 895px;

    @include max-screen($breakpoint-bestview) {
      top: px-to-vw(74);
      right: px-to-vw(-120);
      width: px-to-vw(895);
    }

    @include max-screen($breakpoint-mobile) {
      top: auto;
      bottom: -156px;
      right: -67px;
      width: 380px;
    }
  }
}
