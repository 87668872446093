.heading {
  position: relative;
  z-index: $z-min;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding-block: 15px 17px;
  padding-inline: 50px 57px;
  transform: skew(10deg);

  font-size: 40px;
  font-weight: bold;
  line-height: 1.21;
  text-align: center;

  @include max-screen($breakpoint-bestview) {
    padding-block: px-to-vw(15) px-to-vw(17);
    padding-inline: px-to-vw(50) px-to-vw(57);
    font-size: px-to-vw(40);
  }

  @include max-screen($breakpoint-mobile) {
    padding-block: 4px 5px;
    padding-inline: 18px 25px;
    font-size: 18px;
    line-height: 1.45;
  }

  span {
    transform: skew(-10deg);
  }
}
