.scene {
  position: relative;
  display: grid;
  grid-template-columns: 470px 300px;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 53px;
  margin-top: 56px;

  @include max-screen($breakpoint-bestview) {
    grid-template-columns: px-to-vw(470) px-to-vw(300);
    column-gap: px-to-vw(53);
  }

  @include max-screen($breakpoint-mobile) {
    display: block;
    margin-top: inherit;
  }

  &-title {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.45;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(36);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 25px;
      text-align: center;
      padding-inline: 40px;
    }
  }

  &-desc {
    font-size: 18px;
    line-height: 2;
    margin-block-start: 40px;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(18);
      margin-block-start: px-to-vw(40);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      margin-block-start: 31px;
      padding-inline: 40px;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    row-gap: 11px;
    margin-block-start: 39px;

    @include max-screen($breakpoint-bestview) {
      row-gap: px-to-vw(11);
      margin-block-start: px-to-vw(39);
    }

    @include max-screen($breakpoint-mobile) {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 2px;
      row-gap: 0;
      margin-block-start: 25px;
    }
  }

  &-button {
    appearance: none;
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
    width: calc(100% - 20px);
    padding-block: 16px 15px;
    background-color: $gray-four;
    border-left: 2px solid;
    transition: all $duration $ease;
    min-height: 56px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(20);
      width: calc(100% - px-to-vw(20));
      padding-block: px-to-vw(16) px-to-vw(15);
      border-left-width: px-to-vw(2);
    }

    @include max-screen($breakpoint-mobile) {
      flex-direction: column;
      row-gap: 10px;
      column-gap: 0;
      width: 100%;
      padding-block: 16px 10px;
      border-left: none;
    }

    &::after {
      content: "";
      position: absolute;
      top: 50%;
      // right: -19px;
      right: -20px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      border-left: 20px solid $gray-four;
      transition: all $duration $ease;

      @include max-screen($breakpoint-bestview) {
        // right: px-to-vw(-18.65);
        // border-top-width: px-to-vw(30);
        // border-bottom-width: px-to-vw(30);
        // border-left-width: px-to-vw(19);
        border-top-width: 28px;
        border-bottom-width: 28px;
      }

      @include max-screen($breakpoint-mobile) {
        top: auto;
        right: auto;
        bottom: -9px;
        left: 50%;
        transform: translateX(-50%);
        border-top: 9px solid $gray-four;
        border-bottom: none;
        border-right: 13px solid transparent;
        border-left: 13px solid transparent;
      }
    }

    // modifier
    &-product {
      color: $pink;

      @include true-hover {
        color: $white;
        background-color: $pink;
        border-left-color: $pink;

        &::after {
          border-left-color: $pink;
          @include max-screen($breakpoint-mobile) {
            border-top-color: $pink;
            border-left-color: transparent;
          }
        }
      }

      &.is-active {
        color: $white;
        background-color: $pink;
        border-left-color: $pink;

        &::after {
          border-left-color: $pink;

          @include max-screen($breakpoint-mobile) {
            border-top-color: $pink;
            border-left-color: transparent;
          }
        }
      }
    }

    &-information {
      color: $green-three;

      @include true-hover {
        color: $white;
        background-color: $green-three;
        border-left-color: $green-three;

        &::after {
          border-left-color: $green-three;
          @include max-screen($breakpoint-mobile) {
            border-top-color: $green-three;
            border-left-color: transparent;
          }
        }
      }

      &.is-active {
        color: $white;
        background-color: $green-three;
        border-left-color: $green-three;

        &::after {
          border-left-color: $green-three;

          @include max-screen($breakpoint-mobile) {
            border-top-color: $green-three;
            border-left-color: transparent;
          }
        }
      }
    }

    &-member {
      color: $orange;

      @include true-hover {
        color: $white;
        background-color: $orange;
        border-left-color: $orange;

        &::after {
          border-left-color: $orange;
          @include max-screen($breakpoint-mobile) {
            border-top-color: $orange;
            border-left-color: transparent;
          }
        }
      }

      &.is-active {
        color: $white;
        background-color: $orange;
        border-left-color: $orange;

        &::after {
          border-left-color: $orange;

          @include max-screen($breakpoint-mobile) {
            border-top-color: $orange;
            border-left-color: transparent;
          }
        }
      }
    }

    &-reservation {
      color: $blue-four;

      @include true-hover {
        color: $white;
        background-color: $blue-four;
        border-left-color: $blue-four;

        &::after {
          border-left-color: $blue-four;
          @include max-screen($breakpoint-mobile) {
            border-top-color: $blue-four;
            border-left-color: transparent;
          }
        }
      }

      &.is-active {
        color: $white;
        background-color: $blue-four;
        border-left-color: $blue-four;

        &::after {
          border-left-color: $blue-four;

          @include max-screen($breakpoint-mobile) {
            border-top-color: $blue-four;
            border-left-color: transparent;
          }
        }
      }
    }

    &-product,
    &-information,
    &-member,
    &-reservation {
      border-left-color: currentColor;

      .scene-label {
        color: currentColor;
      }
    }
  }

  &-icon {
    display: block;
    width: 25px;
    height: 25px;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(25);
      height: px-to-vw(25);
    }

    @include max-screen($breakpoint-mobile) {
      width: 25px;
      height: 25px;
    }
  }

  &-label {
    min-width: 80px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      min-width: px-to-vw(80);
      font-size: px-to-vw(20);
    }

    @include max-screen($breakpoint-mobile) {
      min-width: auto;
      font-size: 13px;
    }
  }

  &-iframe-wrapper {
    position: relative;
    z-index: $z-min;
    width: 300px;
    height: 568px;
    background: url(#{$image-path}top/scene-phone.webp) no-repeat center;
    background-size: contain;

    @include max-screen($breakpoint-bestview) {
      width: px-to-vw(300);
      height: px-to-vw(567);
    }

    @include max-screen($breakpoint-mobile) {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      // width: 230px;
      // height: 483px;
      width: 300px;
      height: 568px;
      margin-block-start: 25px;
    }
  }

  &-iframe {
    position: absolute;
    top: 41px;
    left: 50%;
    transform: translateX(-50%);
    width: 290px;
    height: 487px;

    @include max-screen($breakpoint-bestview) {
      top: px-to-vw(40);
      width: px-to-vw(290);
      height: px-to-vw(487);
    }

    @include max-screen($breakpoint-mobile) {
      // top: 56px;
      // width: 222px;
      // height: 373px;
      top: 41px;
      width: 290px;
      height: 487px;
    }
  }
}
