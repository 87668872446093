.topics {
  &-title {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(40);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 25px;
    }
  }

  &-list {
    margin-block-start: 67px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(67);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 32px;
    }
  }

  &-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 49px;
    width: 100%;
    padding: 30px 50px 27px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(49);
      padding: px-to-vw(30) px-to-vw(50) px-to-vw(27);
    }

    @include max-screen($breakpoint-mobile) {
      display: block;
      padding: 25px 25px 18px;
    }

    &:nth-child(odd) {
      background-color: $gray-two;
    }
  }

  &-label {
    font-family: $secondary-font-family;
    line-height: 1.28;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(16);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 11px;
      line-height: 1.45;
    }
  }

  &-desc {
    line-height: 1.45;

    a {
      color: $base-font-color;
      border-bottom: 1px solid currentColor;
      transition: all $duration $ease;

      @include true-hover {
        color: $green;
        border-bottom: 1px solid $green;
      }
    }

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(16);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      line-height: 1.62;
      margin-block-start: 5px;
    }
  }
}
