.merit {
  $this: &;
  padding-block: 118px 114px;

  @include max-screen($breakpoint-bestview) {
    padding-block: px-to-vw(118) px-to-vw(114);
  }

  @include max-screen($breakpoint-mobile) {
    padding-block: 55px 22px;
  }

  &-title {
    line-height: 1.45;
    text-align: center;

    &-en {
      font-size: 45px;
      font-weight: normal;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(45);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 20px;
      }

      small {
        font-size: 35px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(35);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 16px;
        }
      }
    }

    &-jp {
      display: block;
      font-size: 80px;
      font-weight: bold;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(80);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 35px;
        line-height: 1.8;
      }

      &-number {
        font-size: 90px;
        line-height: 1.21;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(90);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 45px;
        }
      }

      small {
        font-size: 45px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(45);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 20px;
        }
      }
    }
  }

  &-list {
    display: block;
    margin-block-start: -15px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(-15);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: -18px;
    }
  }

  &-item {
    padding-block: 70px 63px;

    @include max-screen($breakpoint-bestview) {
      padding-block: px-to-vw(70) px-to-vw(63);
    }

    @include max-screen($breakpoint-mobile) {
      padding-block: 40px 29px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid $gray-five;
    }
  }

  // modifier
  &-efo {
    padding-block: 124px 50px;

    @include max-screen($breakpoint-bestview) {
      padding-block: px-to-vw(124) px-to-vw(50);
    }

    @include max-screen($breakpoint-mobile) {
      padding-block: 59px 22px;
    }

    #{$this} {
      &-title {
        &-en {
          small {
            font-size: 45px;

            @include max-screen($breakpoint-bestview) {
              font-size: px-to-vw(45);
            }

            @include max-screen($breakpoint-mobile) {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}
