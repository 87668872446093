.why {
  $this: &;
  background-color: $gray;

  &-inner {
    padding-block: 130px 190px;

    @include max-screen($breakpoint-bestview) {
      padding-block: px-to-vw(130) px-to-vw(190);
    }

    @include max-screen($breakpoint-mobile) {
      padding-block: 265px 33px;
    }
  }

  &-title {
    font-weight: bold;
    line-height: 1.35;

    &-main {
      display: block;
      font-size: 100px;
      color: $com-theme-color;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(100);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 50px;
      }
    }

    &-sub {
      display: block;
      font-size: 57px;
      font-weight: normal;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(57);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 23px;
      }

      small {
        font-size: 50px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(50);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 20px;
        }
      }
    }

    &-subheading {
      display: block;

      &-en {
        font-size: 80px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(80);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 32px;
        }
      }

      &-jp {
        font-size: 65px;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(65);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 26px;
        }
      }

      small {
        font-size: 50px;
        font-weight: 500;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(50);
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 26px;
        }

        &:last-of-type {
          @include max-screen($breakpoint-mobile) {
            font-size: 20px;
          }
        }
      }
    }
  }

  &-desc {
    @include max-screen($breakpoint-mobile) {
      font-size: 13px;
      line-height: 2;
      margin-block-start: 30px;
    }
  }

  &-list {
    margin-block-start: 50px;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(50);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 30px;
    }
  }

  &-item {
    &:not(:first-child) {
      margin-block-start: 50px;

      @include max-screen($breakpoint-bestview) {
        margin-block-start: px-to-vw(50);
      }

      @include max-screen($breakpoint-mobile) {
        margin-block-start: 33px;
      }
    }
  }

  // modifier
  &-efo {
    #{$this} {
      &-inner {
        padding-block-end: 130px;

        @include max-screen($breakpoint-bestview) {
          padding-block-end: px-to-vw(130);
        }

        @include max-screen($breakpoint-mobile) {
          padding-block-end: 56px;
        }
      }

      &-title {
        &-main {
          color: $efo-theme-color;
        }

        &-sub {
          font-size: 62px;

          @include max-screen($breakpoint-bestview) {
            font-size: px-to-vw(62);
          }

          @include max-screen($breakpoint-mobile) {
            font-size: 23px;
          }

          small {
            font-size: 55px;

            @include max-screen($breakpoint-bestview) {
              font-size: px-to-vw(55);
            }

            @include max-screen($breakpoint-mobile) {
              font-size: 20px;
            }
          }
        }

        &-subheading {
          small {
            font-size: 51px;

            @include max-screen($breakpoint-bestview) {
              font-size: px-to-vw(51);
            }

            @include max-screen($breakpoint-mobile) {
              font-size: 26px;
            }

            &:last-of-type {
              font-size: 55px;

              @include max-screen($breakpoint-bestview) {
                font-size: px-to-vw(55);
              }

              @include max-screen($breakpoint-mobile) {
                font-size: 20px;
              }
            }
          }
        }
      }

      &-desc {
        @include max-screen($breakpoint-mobile) {
          margin-block-start: 25px;
        }
      }

      &-item {
        &:not(:first-child) {
          margin-block-start: 70px;

          @include max-screen($breakpoint-bestview) {
            margin-block-start: px-to-vw(70);
          }

          @include max-screen($breakpoint-mobile) {
            margin-block-start: 27px;
          }
        }
      }
    }
  }
}
