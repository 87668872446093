.optimization {
  padding-inline: 50px;

  @include max-screen($breakpoint-bestview) {
    padding-inline: px-to-vw(50);
  }

  @include max-screen($breakpoint-mobile) {
    padding-inline: 5px;
  }

  &-heading-wrapper {
    text-align: center;
  }

  &-wrapper {
    padding-block: 116px 120px;
    margin-block-start: -40px;
    background-color: $gray-two;

    @include max-screen($breakpoint-bestview) {
      padding-block: px-to-vw(116) px-to-vw(120);
      margin-block-start: px-to-vw(-40);
    }

    @include max-screen($breakpoint-mobile) {
      padding-block: 54px 65px;
      margin-block-start: -17px;
    }
  }

  &-container {
    width: 1002px + 60;
    max-width: 100%;

    // @include max-screen($breakpoint-bestview) {
    //   width: px-to-vw(1062);
    // }

    @include max-screen($breakpoint-mobile) {
      width: 100%;
      padding-inline: 35px;
    }
  }

  &-title {
    font-size: 59px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(59);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 27.5px;
    }

    span {
      font-size: 42px;
      font-weight: 500;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(42);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 18px;
      }
    }

    small {
      display: inline-block;
      font-size: 36px;
      font-weight: normal;
      font-weight: 500;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(36);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 19px;
      }
    }
  }

  &-inner {
    display: grid;
    grid-template-columns: 380px 570px;
    justify-content: space-between;
    align-items: center;
    column-gap: 50px;
    margin-block-start: 62px;

    @include max-screen($breakpoint-bestview) {
      grid-template-columns: px-to-vw(380) px-to-vw(570);
      column-gap: px-to-vw(50);
      margin-block-start: px-to-vw(62);
    }

    @include max-screen($breakpoint-mobile) {
      grid-template-columns: repeat(1, 1fr);
      column-gap: 0;
      margin-block-start: 31px;
    }
  }

  &-content {
    @include max-screen($breakpoint-mobile) {
      order: 2;
    }

    &-title {
      position: relative;
      padding-inline-start: 27px;
      font-size: 30px;
      font-weight: bold;
      line-height: 1.5;

      @include max-screen($breakpoint-bestview) {
        padding-inline-start: px-to-vw(27);
        font-size: px-to-vw(30);
      }

      @include max-screen($breakpoint-mobile) {
        padding-inline-start: 14px;
        font-size: 18px;
        line-height: 1.6;
        margin-block: 38px 23px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 120px;
        background-color: $top-theme-color;

        @include max-screen($breakpoint-bestview) {
          width: px-to-vw(3);
          height: px-to-vw(120);
        }

        @include max-screen($breakpoint-mobile) {
          width: 2px;
          height: 50px;
        }
      }
    }

    &-desc {
      font-size: 18px;
      line-height: 2;
      margin-block-start: 37px;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(18);
        margin-block-start: px-to-vw(37);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 13px;
        margin-block-start: 0;
      }
    }
  }

  &-image {
    @include max-screen($breakpoint-mobile) {
      order: 1;
    }

    &-inner {
      position: relative;
    }

    img {
      display: block;
      width: 100%;

      @include max-screen($breakpoint-mobile) {
        width: calc(100% - 20px);
        margin-block-start: 29px;
      }
    }

    &-tag {
      display: flex;
      justify-content: center;
      align-items: center;

      position: absolute;
      width: 160px;
      height: 160px;
      border-radius: 50%;
      background-color: $top-theme-color;

      line-height: 1.45;
      color: $white;
      text-align: center;

      @include max-screen($breakpoint-bestview) {
        width: px-to-vw(160);
        height: px-to-vw(160);
        font-size: px-to-vw(16);
      }

      @include max-screen($breakpoint-mobile) {
        width: 80px;
        height: 80px;
        font-size: 8px;
      }

      &:first-of-type {
        top: 150px;
        right: -110px;

        @include max-screen($breakpoint-bestview) {
          top: px-to-vw(150);
          right: px-to-vw(-110);
        }

        @include max-screen($breakpoint-mobile) {
          top: 38px;
          right: -20px;
        }
      }

      &:last-of-type {
        bottom: -40px;
        right: -10px;

        @include max-screen($breakpoint-bestview) {
          bottom: px-to-vw(-40);
          right: px-to-vw(-10);
        }

        @include max-screen($breakpoint-mobile) {
          bottom: -10px;
          right: 5px;
        }
      }
    }
  }

  &-banner {
    display: grid;
    grid-template-columns: 270px calc(100% - 270px);
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-block-start: 80px;
    background-color: $white;

    @include max-screen($breakpoint-bestview) {
      grid-template-columns: px-to-vw(270) calc(100% - px-to-vw(270));
      padding: px-to-vw(10);
      margin-block-start: px-to-vw(80);
    }

    @include max-screen($breakpoint-mobile) {
      grid-template-columns: repeat(1, 1fr);
      padding: 5px 5px 26px;
      margin-block-start: 30px;
    }

    &-icon-wrapper {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px 15px 13px;
      background-color: $top-theme-color;

      @include max-screen($breakpoint-bestview) {
        padding: px-to-vw(20) px-to-vw(15) px-to-vw(13);
      }

      @include max-screen($breakpoint-mobile) {
        flex-direction: row;
        column-gap: 10px;
        padding: 15px 15px 5px;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -29px;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 65px solid transparent;
        border-bottom: 65px solid transparent;
        border-left: 30px solid $top-theme-color;

        @include max-screen($breakpoint-bestview) {
          right: px-to-vw(-29);
          border-top-width: px-to-vw(65);
          border-bottom-width: px-to-vw(65);
          border-left-width: px-to-vw(30);
        }

        @include max-screen($breakpoint-tablet) {
          right: px-to-vw(-29);
        }

        @include max-screen($breakpoint-mobile) {
          top: auto;
          right: auto;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
          border-top: 20px solid $top-theme-color;
          border-bottom: none;
          border-right: 142px solid transparent;
          border-left: 142px solid transparent;
        }
      }
    }

    &-icon {
      width: 100px;
      height: 60px;
      background-size: 100px 60px;
      background-position: center;
      background-repeat: no-repeat;

      @include max-screen($breakpoint-bestview) {
        width: px-to-vw(100);
        height: px-to-vw(60);
        background-size: px-to-vw(100) px-to-vw(60);
      }

      @include max-screen($breakpoint-mobile) {
        width: 50px;
        height: 30px;
        background-size: 50px 30px;
      }
    }

    &-desc {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.45;
      color: $white;
      margin-block-start: 8px;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(20);
        margin-block-start: px-to-vw(8);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 16px;
        margin-block-start: 0;
      }
    }

    &-content {
      text-align: center;

      @include max-screen($breakpoint-mobile) {
        margin-block-start: 35px;
      }
    }

    &-title {
      font-size: 43px;
      font-weight: bold;
      line-height: 1.21;
      color: $top-theme-color;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(43);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 26px;
      }

      span,
      small {
        color: $base-font-color;
      }

      small {
        display: block;
        font-size: 30px;
        font-weight: 500;
        line-height: 1.45;

        @include max-screen($breakpoint-bestview) {
          font-size: px-to-vw(30);
          padding-bottom: 12px;
        }

        @include max-screen($breakpoint-mobile) {
          font-size: 13px;
        }
      }
    }
  }
}
