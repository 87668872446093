.function {
  $this: &;
  padding-block: 118px 170px;
  background-color: $gray-two;

  @include max-screen($breakpoint-bestview) {
    padding-block: px-to-vw(118) px-to-vw(170);
  }

  @include max-screen($breakpoint-mobile) {
    padding-block: 64px 80px;
  }

  &-inner {
    @include max-screen($breakpoint-mobile) {
      padding-inline: 0; // l-container override
    }
  }

  &-title {
    font-size: 50px;
    font-weight: 400;
    line-height: 1.45;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(50);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 20px;
    }

    span {
      display: block;
      font-size: 80px;
      font-weight: 700;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(80);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 35px;
        line-height: 1.67;
      }
    }

    small {
      font-size: 50px;

      @include max-screen($breakpoint-bestview) {
        font-size: px-to-vw(50);
      }

      @include max-screen($breakpoint-mobile) {
        font-size: 16px;
      }
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    margin: 0;

    .function-item {
      flex: 1 1 33%;
      text-align: center;
      box-sizing: border-box;
      padding: 10px;
      max-width: 33%;

      @include max-screen($breakpoint-mobile) {
        padding: 2px;
      }
    }
  }

  // modifier
  &-efo {
    padding-block-end: 179px;

    @include max-screen($breakpoint-bestview) {
      padding-block-end: px-to-vw(179);
    }

    @include max-screen($breakpoint-mobile) {
      padding-block-end: 80px;
    }

    #{$this} {
      &-list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        justify-content: space-between;
        align-items: flex-start;
        gap: 28px;
        margin-block-start: 54px;

        @include max-screen($breakpoint-bestview) {
          gap: px-to-vw(28);
        }

        @include max-screen($breakpoint-mobile) {
          grid-template-columns: repeat(3, 1fr);
          gap: 5px;
        }

        .function-item {
          flex: inherit;
          text-align: inherit;
          box-sizing: inherit;
          padding: inherit;
          max-width: inherit;

          @include max-screen($breakpoint-mobile) {
            padding: inherit;
          }
        }
      }
    }
  }
}
