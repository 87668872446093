.card-list {
  $this: &;
  position: relative;

  &-title {
    font-size: 40px;
    font-weight: bold;
    line-height: 1.45;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      font-size: px-to-vw(40);
    }

    @include max-screen($breakpoint-mobile) {
      font-size: 25px;
    }

    img {
      width: 345px;
      margin: 0 auto;

      @include max-screen($breakpoint-bestview) {
        width: px-to-vw(345);
      }

      @include max-screen($breakpoint-mobile) {
        width: 222px;
      }
    }
  }

  &-inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 31px;
    margin-block-start: 73px;

    @include max-screen($breakpoint-bestview) {
      column-gap: px-to-vw(31);
      margin-block-start: px-to-vw(73);
    }

    @include max-screen($breakpoint-mobile) {
      grid-template-columns: repeat(1, 1fr);
      row-gap: 25px;
      column-gap: 0;
      margin-block-start: 38px;
    }
  }

  &-button {
    margin-block-start: 80px;
    text-align: center;

    @include max-screen($breakpoint-bestview) {
      margin-block-start: px-to-vw(80);
    }

    @include max-screen($breakpoint-mobile) {
      margin-block-start: 40px;
    }
  }

  // modifier
  &-com,
  &-efo {
    #{$this} {
      &-inner {
        margin-block-start: 67px;

        @include max-screen($breakpoint-bestview) {
          margin-block-start: px-to-vw(67);
        }

        @include max-screen($breakpoint-mobile) {
          margin-block-start: 32px;
        }
      }
    }
  }
}
